import React from 'react';

import clsx from 'clsx';
import { Badge, Ellipsis, HiddenDetails } from 'components';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { Checkbox, CheckboxOnChange } from 'components/Checkbox/Checkbox';
import { ReleaseRequestAccount } from 'modules/ReleaseRequest/models/account';
import { selectedAccountsAtom } from 'modules/ReleaseRequest/models/input';
import { STATUS_BACKGROUND } from 'modules/common/enums/statusBackground';
import { useCustomisation } from 'modules/root/Settings';
import { printMoney } from 'utils/print';

import { AssetTable } from './AssetTable';

interface AccountItemProps {
  account?: ReleaseRequestAccount;
  inactive?: boolean;
  onChange: CheckboxOnChange;
}

export const AccountItem: React.FC<AccountItemProps> = ({
  account,
  inactive,
  onChange: handleChange,
}) => {
  const { t } = useTranslation();
  const { labels } = useCustomisation();
  const selectedAccount = useAtomValue(selectedAccountsAtom);

  return (
    <div
      data-testid="release-request-account-item"
      className={clsx(
        'rounded-xl px-5 py-4 w-full border-gray-200 border overflow-hidden hover:border-primary-200',
        { 'bg-gray-50 text-gray-200': inactive }
      )}
      role="checkbox"
      aria-checked={false}
    >
      <div className="flex items-center justify-between">
        <div className="flex w-[55%] overflow-hidden">
          <div className="flex flex-col items-stretch gap-1 w-full">
            {account?.pledged && (
              <Badge size="sm" color={STATUS_BACKGROUND.YELLOW} label={labels.pledged} />
            )}
            <div className="font-bold grow-0" title={labels.collateralAccountId}>
              <Ellipsis>
                <>{account?.collateralAccountId}</>
              </Ellipsis>
            </div>
            <div className="flex">
              <div className="grow-0 overflow-hidden max-w-[50%]" title={labels.owner}>
                <Ellipsis>
                  <>{account?.ownerDisplayName || t('common.unavailable')}</>
                </Ellipsis>
              </div>
              <div className="pl-1 pr-2">{`|`}</div>
              <div
                className="grow-0 overflow-hidden max-w-[50%]"
                title={labels.custodian}
              >
                <Ellipsis>
                  <>{account?.custodian || t('common.unavailable')}</>
                </Ellipsis>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center ml-4">
          <div className="flex justify-end">
            <div className="flex flex-col items-end gap-1">
              <Badge
                size="sm"
                color={STATUS_BACKGROUND.PRIMARY}
                label={labels.marketValue}
              />
              <div className="font-bold tracking-wider">
                {account?.marketData?.marketValue ||
                account?.marketData?.marketValue === 0
                  ? printMoney(account?.marketData.marketValue)
                  : t('common.notPriced')}
              </div>
              <div title={labels.accountType}>
                {account?.accountType || t('common.unavailable')}
              </div>
            </div>
          </div>
          {!inactive && (
            <div className="mx-5 justify-self-end">
              <Checkbox
                checked={
                  account
                    ? selectedAccount[account.collateralAccountId]?.selectedAssets ===
                      selectedAccount[account.collateralAccountId]?.allAssets
                    : false
                }
                name="account"
                value={account?.collateralAccountId}
                onChange={handleChange}
              />
            </div>
          )}
        </div>
      </div>
      <HiddenDetails className="mt-4">
        <AssetTable
          inactive={inactive}
          assets={account?.assetHoldings}
          onChange={handleChange}
        />
      </HiddenDetails>
    </div>
  );
};
