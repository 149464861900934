import React from 'react';

import { useAtomValue } from 'jotai';

import { StepIds, currentStepAtom } from '../models/step';
import { CustomerSelectorStep } from './steps/CustomerSelectorStep/CustomerSelectorStep';
import { RequestStep } from './steps/RequestStep/RequestStep';

interface ReleaseRequestStepProps {}

export const ReleaseRequestStep: React.FC<ReleaseRequestStepProps> = (props) => {
  const step = useAtomValue(currentStepAtom);

  return (
    <div className="flex flex-col items-center w-full justify-between h-full mt-5">
      {step?.id === StepIds.Customer && <CustomerSelectorStep className="mb-14" />}
      {step?.id === StepIds.Request && <RequestStep />}
    </div>
  );
};
