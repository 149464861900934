import React from 'react';

import clsx from 'clsx';
import { LoadedButton, LoadingSpinner } from 'components';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { SearchBox } from 'modules/Customers/SearchBox/SearchBox';
import { useCustomisation } from 'modules/root/Settings';

import { QuerySuspense } from '../QuerySuspense/QuerySuspense';
import { CustomerItem } from './CustomerItem';
import { CustomerSelection, canShowMoreCustomersAtom, customerSearchAtom } from './model';
import { useCustomers } from './useCustomers';

interface CustomerSelectorProps {
  defaultId?: string;
  className?: string;
  onSelect: (selected: CustomerSelection) => void;
  renderBadge?: (eligible: boolean) => React.ReactElement;
  getEligibitity?: (customer: CustomerSelection) => boolean;
}

export const CustomerSelector: React.FC<CustomerSelectorProps> = ({
  defaultId = '',
  className,
  onSelect,
  renderBadge,
  getEligibitity,
}) => {
  const [customerSearch, setCustomerSearch] = useAtom(customerSearchAtom);
  const [customerId, setCustomerId] = React.useState(defaultId);

  const [canShowMoreCustomers] = useAtom(canShowMoreCustomersAtom);
  const { labels } = useCustomisation();
  const { isLoading, error, customers } = useCustomers();
  const { t } = useTranslation();

  if (!customerSearch) {
    setCustomerSearch('', 0);
  }

  const showNoResults = React.useMemo(
    () =>
      customers.length > 0 &&
      !customers.some((page) => page.items && page.items.length > 0),
    [customers]
  );

  const handleSelect = React.useCallback(
    (customer: CustomerSelection) => {
      setCustomerId(customer.id);
      onSelect(customer);
    },
    [onSelect]
  );

  const handleSearch = React.useCallback(
    (value: string) => {
      setCustomerSearch(value, 0);
    },
    [setCustomerSearch]
  );

  const handleLoadMore = React.useCallback(() => {
    const searchText = customerSearch?.searchText || '';
    const fromIndex = (customerSearch?.fromIndex ?? 0) + 5;
    setCustomerSearch(searchText, fromIndex);
  }, [customerSearch?.searchText, customerSearch?.fromIndex, setCustomerSearch]);

  return (
    <div className={clsx('w-full flex flex-col', className)}>
      <h2 className="text-lg font-bold mb-8">{labels.chooseCustomer}</h2>
      <SearchBox
        className="mb-8"
        initialValue={customerSearch?.searchText || ''}
        onChange={handleSearch}
        placeholder={`${t('common.searchBox.find')} ${labels.customers}`}
      />
      <QuerySuspense
        isLoading={isLoading && !customers}
        error={error}
        noData={!isLoading && !customers.length}
        loadingRenderer={() => <LoadingSpinner className="self-center" />}
      >
        <div className="flex flex-col gap-3 mb-3">
          {customers?.map((page) => (
            <React.Fragment key={page.fromIndex}>
              {page?.items?.map((cust) =>
                cust ? (
                  <CustomerItem
                    key={cust.id}
                    customer={cust}
                    selected={cust.id === customerId}
                    onSelect={handleSelect}
                    renderBadge={renderBadge}
                    getEligibitity={getEligibitity}
                  />
                ) : null
              )}
            </React.Fragment>
          ))}
        </div>
        {canShowMoreCustomers && (
          <div className="text-center">
            <LoadedButton
              isLoading={isLoading}
              state="secondary"
              onClick={handleLoadMore}
            >
              {t('common.showMore')}
            </LoadedButton>
          </div>
        )}
        {showNoResults && (
          <div data-testid="no-data" className="notification self-center">
            {t('common.searchBox.noRecords')}
          </div>
        )}
      </QuerySuspense>
    </div>
  );
};
