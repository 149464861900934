import React from 'react';

import { LoadedButton, notification } from 'components';
import { ActivityAuditType, useAuditReportMutation } from 'generated/graphql';
import { useAtomValue } from 'jotai';
import { graphqlClient, useAuthClient } from 'queries/apiFetch/graphqlClient';
import { useTranslation } from 'react-i18next';
import { useDownloadPdf } from 'services';
import { ResourceAccessed } from 'services/auditReport';

import { selCustomerAtom } from 'modules/ReleaseRequest/models/customer';
import { releaseRequestResultAtom } from 'modules/ReleaseRequest/models/result';
import { DATE_FORMAT_DASH, formatDate } from 'utils/date';

import { releaseRequestStatusAtom } from '../../../models/status';

interface DownloadPDFProps {}

export const DownloadPDF: React.FC<DownloadPDFProps> = (props) => {
  const customerExternalId = useAtomValue(selCustomerAtom)?.customerId;
  const status = useAtomValue(releaseRequestStatusAtom);
  const releaseRequestExternalId = useAtomValue(releaseRequestResultAtom)
    ?.runReleaseRequest.externalId;
  const { t } = useTranslation();

  const { download, isLoading: pdfIsLoading } = useDownloadPdf();
  const authClient = useAuthClient(graphqlClient);
  const { mutateAsync } = useAuditReportMutation(authClient);
  const handleDownloadPdf = (id: string) => {
    let date = new Date();
    const offset = date.getTimezoneOffset();
    const fileName = `CollateralRelease_${id}_${formatDate(
      date.toISOString(),
      DATE_FORMAT_DASH
    )}`;
    const urlPath = `/schema/release-request/${id}/pdf?timeZoneOffset=${-offset}`;
    download(urlPath, fileName)
      .then(() =>
        mutateAsync({
          activityType: ActivityAuditType.Read,
          customerExternalId,
          resourceAccessed: ResourceAccessed.DownloadReleaseRequestPdf,
          queryDetails: JSON.stringify({ releaseRequestId: id }),
        })
      )
      .catch((error) => {
        mutateAsync({
          activityType: ActivityAuditType.Read,
          customerExternalId,
          resourceAccessed: ResourceAccessed.DownloadReleaseRequestPdf,
          queryDetails: JSON.stringify({ releaseRequestId: id, error: true }),
        });
        notification.error(error?.message || t('common.error.unspecific'));
      });
  };

  return (
    <LoadedButton
      className="whitespace-nowrap"
      isLoading={pdfIsLoading}
      data-testid="download-pdf-button"
      onClick={() => {
        handleDownloadPdf(releaseRequestExternalId?.toString() || '');
      }}
      disabled={status !== 'saved' || !releaseRequestExternalId || pdfIsLoading}
    >
      {t('releaseRequest.buttons.download')}
    </LoadedButton>
  );
};
