import { format as dateFnsFormat, isValid } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';

export const DATE_FORMAT_MONTH_NAME = 'MMMM d, yyyy';
export const DATE_FORMAT_SHORT_MONTH_NAME = 'MMM d, yyyy';
export const DATE_FORMAT_MONTH_NAME_TIME = 'MMMM d, yyyy HH:mm:ss';
export const DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_FORMAT_TIME = 'MM/dd/yyyy HH:mm:ss';
export const DATE_FORMAT_DASH_SEPARATED = "yyyy-MM-dd'T'HH:mm:ss";
export const DATE_FORMAT_UNDERSCORE = 'dd_MM_yyyy';
export const DATE_FORMAT_DASH = 'dd-MM-yyyy';
export const DATE_FORMAT_MONTH_DASH = 'MM-dd-yyyy';
export const TIME_FORMAT_WITH_MS_12H = 'hh:mm:ss aaa';
export const TIME_FORMAT_12H = 'hh:mm aaa';
export const formatDate = (dateStr: string, format: string): string => {
  const date = new Date(dateStr);
  if (!isValid(date)) {
    return 'Invalid Date';
  }
  return dateFnsFormat(date, format, { locale: enUS });
};

export const formatDateWithoutTimezone = (dateStr: string, format: string): string => {
  const date = new Date(dateStr);
  if (!isValid(date)) {
    return 'Invalid Date';
  }
  return dateFnsFormat(toZonedTime(date, 'UTC'), format, { locale: enUS });
};
