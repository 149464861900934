import React from 'react';

import { Dropdown, DropdownKeyValueOption, DropdownOption } from 'components';
import { DrawdownRequestAmountQuery } from 'generated/graphql';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { roundDecimalPlaces } from 'utils';

import {
  amountAtom,
  availabilityAtom,
  loanAtom,
} from 'modules/DrawdownRequest/models/amount';

interface LoanFieldProps {
  loans: DrawdownRequestAmountQuery['customer']['loans'];
}

const roundAvailability = (availability?: number | null) => {
  return parseFloat(roundDecimalPlaces(availability || 0, 2, 'down'));
};

export const LoanField: React.FC<LoanFieldProps> = ({ loans }) => {
  const { t } = useTranslation();
  const setAvailability = useSetAtom(availabilityAtom);
  const [loan, setLoan] = useAtom(loanAtom);
  const setAmount = useSetAtom(amountAtom);

  React.useEffect(() => {
    if (loans && loans.length === 1) {
      const val = loans?.[0] || null;
      if (val) {
        setAvailability(roundAvailability(val.availability));
      }
      setLoan(val);
    }
  }, [loans, setAvailability, setLoan]);

  const options = React.useMemo(
    () =>
      loans?.reduce<DropdownKeyValueOption[]>((result, loan) => {
        if (loan) {
          result.push({
            key: loan.id,
            value: `${t('customer.loans.single')} ${result.length + 1} • ${
              loan.obligor
            } - ${loan.obligation}`,
          });
        }
        return result;
      }, []),
    [loans, t]
  );

  const handleChange = React.useCallback(
    (option: DropdownOption) => {
      const val = loans?.find(
        (loan) => loan?.id === (option as DropdownKeyValueOption).key
      );
      setLoan(val ?? null);
      setAmount(0);
      setAvailability(roundAvailability(val?.availability));
    },
    [loans, setAmount, setAvailability, setLoan]
  );

  return (
    <Dropdown
      data-testid="drawdown-loan-field"
      label={t('customer.loans.single')}
      onChange={handleChange}
      options={options || []}
      disabled={options?.length === 1}
      value={
        loan ? { key: loan.id, value: `${loan?.obligor}-${loan?.obligation}` } : null
      }
    />
  );
};
