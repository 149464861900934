import React from 'react';

import {
  faAngleDown,
  faAngleRight,
  faCheckCircle,
  faCircleXmark,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { Row, createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import {
  Button,
  Ellipsis,
  FontIcon,
  HelpIcon,
  Modal,
  ReactTable,
  Tooltip,
} from 'components';
import { ParseAssetCsvQuery, ParsedAssetError } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { ArrayElement, printPrimitive } from 'utils';

import { useCustomisation } from 'modules/root/Settings';
import { notEmpty } from 'utils/helpers';

import { Holdings } from './Holdings';

type ParsedAccounts = NonNullable<ParseAssetCsvQuery['parseAssetCSV']>['records'];
type ParsedAccount = ArrayElement<ParsedAccounts>;

export interface AcquiredAccountsModalTitleProps {
  onClose: () => void;
}

export interface AcquiredAccountsModalProps {
  accounts: ParsedAccounts;
  warning?: string | null;
  onClose: () => void;
  onAdd: () => void;
}

export const AcquiredAccountsModalTitle: React.FC<AcquiredAccountsModalTitleProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <div className="bg-gray-50 px-10 py-6 rounded-t-lg">
      <div className="w-[950px] flex justify-between">
        {t('evaluations.acquiredAccounts')}
        <FontIcon className="cursor-pointer" onClick={onClose} icon={faXmark} />
      </div>
    </div>
  );
};

const renderSubComponent = (row: Row<ParsedAccount>) => {
  return (
    <div className="pl-16">
      <Holdings holdings={row.original.account?.assetHoldings || []} />
    </div>
  );
};

interface ManualUploadErrorMessagesProps {
  errors: ParsedAssetError[];
}

export const ManualUploadErrorMessages: React.FC<ManualUploadErrorMessagesProps> = ({
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <div data-testid="csv-accounts-error" className="alert">
      <ul className="list-disc pl-2">
        {errors?.includes(ParsedAssetError.MissingInfo) && (
          <li className="my-1">{t('evaluations.missingInformationError')}</li>
        )}

        {errors?.includes(ParsedAssetError.Duplication) && (
          <li className="my-1">{t('evaluations.duplicateRecordsError')}</li>
        )}

        {errors?.includes(ParsedAssetError.AccountExists) && (
          <li className="my-1">{t('evaluations.accountsAlreadyExistsError')}</li>
        )}
      </ul>
    </div>
  );
};

export const AcquiredAccountsModal: React.FC<AcquiredAccountsModalProps> = ({
  accounts,
  warning,
  onClose,
  onAdd,
}) => {
  const { t } = useTranslation();
  const { labels, helpText } = useCustomisation();
  const columnHelper = createColumnHelper<NonNullable<ParsedAccount>>();
  const accountValidationCount = React.useMemo(() => {
    const validAccounts = accounts.filter((acc) => acc.errors?.length === 0);
    const invalidAccounts = accounts.filter((acc) => acc.errors?.length !== 0);

    return {
      validAccounts: validAccounts.length,
      invalidAccounts: invalidAccounts.length,
    };
  }, [accounts]);
  const columns = React.useMemo(() => {
    return [
      columnHelper.accessor('account.id', {
        header: () => null,
        cell: ({ row }) => (
          <FontIcon
            className="cursor-pointer px-3"
            onClick={row.getToggleExpandedHandler()}
            icon={row.getIsExpanded() ? faAngleDown : faAngleRight}
          />
        ),
      }),
      columnHelper.accessor('account.collateralAccountId', {
        header: () => (
          <div className="flex flex-nowrap items-center gap-1">
            {labels.collateralAccountNumber}
            <HelpIcon text={helpText.collateralAccountNumber} />
          </div>
        ),
        cell: ({ getValue }) => <Ellipsis>{printPrimitive(getValue())}</Ellipsis>,
      }),
      columnHelper.accessor('account.accountType', {
        header: () => (
          <div className="flex flex-nowrap items-center gap-1">
            {labels.accountType} <HelpIcon text={helpText.accountType} />
          </div>
        ),
        cell: ({ getValue }) => <Ellipsis>{printPrimitive(getValue())}</Ellipsis>,
      }),
      columnHelper.accessor('account.ownerId', {
        header: () => (
          <div className="flex flex-nowrap items-center gap-1">
            {labels.owner} <HelpIcon text={helpText.owner} />
          </div>
        ),
        cell: ({ getValue }) => <Ellipsis>{printPrimitive(getValue())}</Ellipsis>,
      }),
      columnHelper.accessor('errors', {
        header: () => (
          <div className="flex flex-nowrap items-center gap-1">
            {t('evaluations.validation')}
          </div>
        ),
        cell: ({ getValue }) => {
          const errors = getValue();
          return errors && errors?.length > 0 ? (
            <Tooltip
              text={<ManualUploadErrorMessages errors={errors} />}
              className="leading-none"
            >
              <span data-testid="invalid-account">
                <FontIcon
                  className="cursor-pointer px-3 text-red-500"
                  icon={faCircleXmark}
                />
              </span>
            </Tooltip>
          ) : (
            <span data-testid="valid-account">
              <FontIcon
                data-testid="valid-account-icon"
                className="px-3 text-green-500"
                icon={faCheckCircle}
              />
            </span>
          );
        },
      }),
    ];
  }, [labels, columnHelper, helpText, t]);
  const warningParsed = React.useMemo(() => {
    if (warning?.startsWith('001 ')) {
      return t('evaluations.incorrectClientId');
    } else if (warning?.startsWith('002 ')) {
      return t('accounts.noAccountsRetrieved');
    } else {
      return warning?.replace(/^\d+ - /, '');
    }
  }, [warning, t]);
  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title={<AcquiredAccountsModalTitle onClose={onClose} />}
    >
      <div
        className={clsx(
          { 'min-h-[500px]': accounts && !warningParsed },
          'max-h-[70vh] px-10 py-6 overflow-y-auto'
        )}
      >
        {accounts && accounts.length > 0 && !warningParsed && (
          <ReactTable<NonNullable<ParsedAccount>>
            data={accounts?.filter(notEmpty)}
            columns={columns}
            renderSubComponent={renderSubComponent}
            getRowCanExpand={() => true}
            expandedByDefault={true}
            data-testid="Acquired-accounts-table"
          />
        )}
        {!(accounts && accounts.length > 0) && !warningParsed && (
          <div className="warning" data-testid="no-accounts-warning">
            {t('accounts.noAccountsRetrieved')}
          </div>
        )}
        {warningParsed && (
          <div className="warning" data-testid="api-warning">
            {warningParsed}
          </div>
        )}
      </div>
      <div className="flex justify-start gap-4 p-6 flex-row flex-nowrap">
        {accountValidationCount.invalidAccounts > 0 && (
          <div className="warning" data-testid="invalid-accounts-warning">
            {t('evaluations.addValidAccountsNote')}
          </div>
        )}
        <div className="flex flex-grow justify-end gap-4 py-4">
          <Button onClick={onClose} state="secondary">
            {t('common.cancel')}
          </Button>
          <Button
            onClick={onAdd}
            disabled={accountValidationCount.validAccounts === 0 || !!warningParsed}
            data-testid="add-accounts-button"
          >
            {t('evaluations.addAccounts')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
