import React from 'react';

import { cn } from 'utils/styles';

import { TableRow } from './TableRow';
import { getWarning } from './utils';

export interface TableHeadProps {
  children?: JSX.Element | JSX.Element[];
  className?: string;
  'data-testid'?: string;
}

export const TableHead: React.FC<TableHeadProps> = ({
  children,
  className,
  'data-testid': dataTestId,
}) => {
  return (
    <thead className={cn('group', className)} data-testid={dataTestId}>
      {React.Children.map(children, (child) => {
        if (child?.type !== TableRow) {
          const warning = getWarning('TableHead', 'TableRow');
          console.error(warning);
        }
        return child;
      })}
    </thead>
  );
};
