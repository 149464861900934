import { RunDrawdownRequestMutation } from 'generated/graphql';
import { atom } from 'jotai';

import { StepIds, stepsAtom } from './step';

type drawdownRequestResultType = RunDrawdownRequestMutation['runDrawdownRequest'] | null;

export const drawdownRequestResultAtom = atom<
  drawdownRequestResultType,
  [drawdownRequestResultType],
  void
>(null, (get, set, update: drawdownRequestResultType) => {
  // enable next step after successful calculate result
  set(stepsAtom, (steps) => {
    const index = steps.findIndex((step) => step.id === StepIds.Results);
    const isValid = !!update;
    const updatedSteps = steps.map((step, i) => {
      if (i === index) {
        return { ...step, valid: isValid };
      } else if (i === index + 1) {
        return { ...step, enable: isValid };
      }
      return step;
    });
    return updatedSteps;
  });
  set(drawdownRequestResultAtom, update);
});
