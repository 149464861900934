import { CompareTo, LoanStatus, RateType } from 'generated/graphql';
import { ApplicationConfig } from 'generated/graphql';
import { t } from 'i18next';

import { EMPTY_DASH } from './print';

export const getLoanRateTypeLabel = (rateType: RateType | null | undefined): string => {
  if (rateType === 'FIXED') {
    return t('customer.loans.interestPayments.rateTypeFixed');
  }
  if (rateType === 'VARIABLE') {
    return t('customer.loans.interestPayments.rateTypeVariable');
  }
  return EMPTY_DASH;
};

export const getStatusLabel = (
  status: LoanStatus | null | undefined,
  statusLabel: ApplicationConfig['serviceStatus']
): string => {
  if (!status) {
    return '';
  }

  return statusLabel[status].label || EMPTY_DASH;
};

export const getCompareToLabel = (compareTo: CompareTo | undefined | null) => {
  if (!compareTo) {
    return '';
  }
  const labels = {
    [CompareTo.MinimumBalance]: 'Minimum Balance',
    [CompareTo.OutstandingBalance]: 'Outstanding Balance',
    [CompareTo.CommitmentAmount]: 'Commitment Amount',
  };
  return labels[compareTo] || EMPTY_DASH;
};
