import React from 'react';

import clsx from 'clsx';
import { TabItem, Tabs } from 'components';

interface HeaderProps {
  title: React.ReactElement | string;
  subTitle?: React.ReactElement;
  buttons?: React.ReactElement;
  tabs?: TabItem[];
  onTabChange?: (key: string) => void;
}

export const ShrinkHeader: React.FC<HeaderProps> = ({
  title,
  subTitle,
  buttons,
  tabs,
  onTabChange,
}) => {
  const MAX_HEIGHT = 156;
  const MIN_HEIGHT = tabs ? 100 : 80;
  const [height, setHeight] = React.useState(MAX_HEIGHT);
  const headerRef = React.useRef<HTMLElement>(null);

  const root = document.getElementById('root');
  React.useLayoutEffect(() => {
    if (root && root.scrollTop > MAX_HEIGHT) {
      setHeight(MIN_HEIGHT);
    } else {
      setHeight(MAX_HEIGHT);
    }
  }, [root, MIN_HEIGHT]);
  const handleScroll = React.useCallback(() => {
    if (root && headerRef.current) {
      if (root.scrollTop <= MAX_HEIGHT - MIN_HEIGHT) {
        setHeight(MAX_HEIGHT - root.scrollTop);
      } else if (headerRef.current.offsetHeight !== MIN_HEIGHT) {
        setHeight(MIN_HEIGHT);
      }
    }
  }, [MIN_HEIGHT, root]);

  React.useEffect(() => {
    root?.removeEventListener('scroll', handleScroll, true);
    root?.addEventListener('scroll', handleScroll, true);
    return () => root?.removeEventListener('scroll', handleScroll, true);
  }, [handleScroll, root]);

  return (
    <div
      style={{ height: MAX_HEIGHT, top: MIN_HEIGHT - MAX_HEIGHT }}
      className={clsx('sticky', 'z-10')}
    >
      <header
        ref={headerRef}
        id="header"
        style={{ height }}
        className={clsx('sticky', 'top-0', 'bg-white/70', 'backdrop-blur-md', 'z-10')}
      >
        <div
          className={clsx(
            'h-full',
            'w-full',
            'bg-main-primary',
            'border-b-[1px]',
            'border-gray-100',
            {
              'opacity-0': height <= MIN_HEIGHT,
              'opacity-100': height > MIN_HEIGHT,
            }
          )}
        ></div>
        <div
          className={clsx(
            'h-full',
            'w-full',
            'transition-opacity',
            'duration-500',
            'bg-white/50',
            'absolute',
            'top-0',
            'border-b-[1px]',
            'border-gray-100',
            'ease-in-out',
            {
              'opacity-0': height > MIN_HEIGHT,
              'opacity-100': height <= MIN_HEIGHT,
            }
          )}
        ></div>
        <div
          className={clsx(
            'flex',
            'flex-col',
            'items-left',
            'justify-center',
            'lg:px-20 px-8',
            'h-full',
            'w-full',
            'absolute',
            'top-0'
          )}
        >
          <div className="flex flex-row justify-between items-center">
            <div>
              <h1
                data-testid="shrink-header"
                className={clsx(
                  'max-w-[450px] lg:max-w-[600px] xl:max-w-[650px]',
                  'transition-all',
                  'my-1',
                  'duration-500',
                  'font-bold',
                  'group',
                  {
                    'text-xl': height > MIN_HEIGHT,
                    'lg:text-2xl': height > MIN_HEIGHT,
                    'text-white': height > MIN_HEIGHT,
                    'text-primary-600': height <= MIN_HEIGHT,
                    'text-base': height <= MIN_HEIGHT,
                    'lg:text-lg': height <= MIN_HEIGHT,
                    'is-shrinked': height <= MIN_HEIGHT,
                  }
                )}
              >
                {title}
              </h1>
              {subTitle && (
                <div
                  data-testid="updated"
                  className={clsx({
                    'text-white': height > MIN_HEIGHT,
                    'text-gray-600': height === MIN_HEIGHT,
                  })}
                >
                  {subTitle}
                </div>
              )}
            </div>
            <div
              className={clsx('flex', 'flex-row', 'items-center', 'h-9', 'gap-5', {
                'text-white': height > MIN_HEIGHT,
                'text-primary-600': height === MIN_HEIGHT,
              })}
            >
              {buttons}
            </div>
          </div>
          {tabs && (
            <div
              className={clsx('absolute', 'bottom-0', {
                'text-white': height > MIN_HEIGHT,
                'text-gray-600': height === MIN_HEIGHT,
              })}
              data-testid="shrink-header-tabs"
            >
              <Tabs
                tabs={tabs}
                noBackground={height <= MIN_HEIGHT}
                onTabChange={onTabChange}
              />
            </div>
          )}
        </div>
      </header>
    </div>
  );
};
