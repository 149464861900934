import { useState } from 'react';
import React from 'react';

import { Switch } from '@headlessui/react';

import { cn } from 'utils/styles';

interface ToggleProps {
  label?: string;
  className?: string;
  onChange?: (enabled: boolean) => void;
}

export const Toggle: React.FC<ToggleProps> = ({ label, className, onChange }) => {
  const [enabled, setEnabled] = useState(false);
  const handleChange = React.useCallback(() => {
    const val = !enabled;
    setEnabled(val);
    onChange?.(val);
  }, [enabled, onChange]);

  return (
    <Switch.Group>
      <div className={cn('flex items-center', className)}>
        <Switch.Label className="mr-4 font-semibold">{label}</Switch.Label>
        <Switch
          checked={enabled}
          onChange={handleChange}
          className={`${
            enabled ? 'bg-primary-600' : 'bg-gray-300'
          } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
        >
          <span
            className={`${
              enabled ? 'translate-x-6' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
};
