import { HelpIcon, TextInput, TextInputProps } from 'components';
import { Control, Controller } from 'react-hook-form';

import { LoanProposalFormValues } from '../types';

export type TextboxControllerProps = TextInputProps & {
  name: keyof LoanProposalFormValues;
  hidden?: boolean;
  formatFn?: (value: string) => string;
  filterFn?: (value: string) => string;
  control: Control<LoanProposalFormValues, any>;
  helpText?: string | null | undefined;
};

export const TextboxController: React.FC<TextboxControllerProps> = ({
  name,
  hidden,
  formatFn,
  filterFn,
  control,
  helpText,
  ...props
}) => {
  if (hidden) {
    return null;
  }
  return (
    <>
      <Controller
        render={({ field, fieldState: { error } }) => {
          return (
            <div className="flex">
              <TextInput
                text={field?.value?.toString() || undefined}
                onChange={field.onChange}
                onBlur={field.onBlur}
                maxLength={140}
                formatFn={formatFn}
                filterFn={filterFn}
                errorMessage={error?.message}
                {...props}
                className="grow"
              />

              <div className="w-5 mb-4 pl-1 flex items-center">
                {helpText && <HelpIcon text={helpText} />}
              </div>
            </div>
          );
        }}
        name={name}
        control={control}
      />
    </>
  );
};
