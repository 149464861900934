import React from 'react';

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

type Props = Pick<
  FontAwesomeIconProps,
  'icon' | 'className' | 'size' | 'onClick' | 'title'
> & {
  disabled?: boolean;
  testid?: string;
};
export const FontIcon: React.FC<Props> = ({
  icon,
  className,
  size,
  onClick,
  testid,
  disabled = false,
  title,
}) => {
  return (
    <FontAwesomeIcon
      onClick={disabled ? undefined : onClick}
      {...(!disabled && onClick ? { role: 'button' } : {})}
      icon={icon}
      aria-disabled={disabled}
      data-testid={testid}
      className={className}
      size={size}
      title={title}
    />
  );
};
