import React from 'react';
import { generatePath, useNavigate, useParams } from 'react-router';

import { Notification } from 'components';
import {
  ActivityAuditType,
  LoanStatus,
  ReleaseRequestAccountsQuery,
  ReleaseRequestCustomerQuery,
  useReleaseRequestAccountsQuery,
  useReleaseRequestCustomerQuery,
} from 'generated/graphql';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';

import { accountsAtom } from 'modules/ReleaseRequest/models/account';
import { breadcrumbsAtom } from 'modules/ReleaseRequest/models/breadcrumb';
import {
  isCustomerEligibleAtom,
  selCustomerAtom,
} from 'modules/ReleaseRequest/models/customer';
import {
  AssetIndexer,
  SelectedAccount,
  assetIndexerAtom,
  selectedAccountsAtom,
} from 'modules/ReleaseRequest/models/input';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { INELIGIBLE_STATUSES } from 'modules/common/constants/status';
import { usePaths } from 'modules/root/Settings';

import { AssetSelector } from './AssetSelector/AssetSelector';

interface RequestStepProps {}

export const useSetCustomer = () => {
  const [selCustomer, setSelCustomer] = useAtom(selCustomerAtom);
  const paths = usePaths();
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [breadcrumbs, setBreadcrumbs] = useAtom(breadcrumbsAtom);

  const { isLoading, isError } = useQueryFetch(useReleaseRequestCustomerQuery, {
    queryHookOptions: {
      enabled: !selCustomer,
      onSuccess(data) {
        const result = (data as ReleaseRequestCustomerQuery).customer;
        setSelCustomer({
          id: result.id,
          customerId: result.customerId,
          displayName: result.displayName,
          serviceStatus: result.serviceStatus?.status,
        });
      },
    },
    queryHookParams: { customerID: customerId || '' },
    extra: {
      auditReport: { activityType: ActivityAuditType.Read },
    },
  });

  React.useEffect(() => {
    if (selCustomer) {
      const path = generatePath(paths.collateralRelease.proceed, {
        customerId: selCustomer.id,
      });
      if (!breadcrumbs.some((bc) => bc.link === path)) {
        setBreadcrumbs([
          ...breadcrumbs.slice(0, 3),
          {
            label: selCustomer.displayName,
            link: path,
          },
        ]);
        navigate(path, { replace: true });
      }
    }
  }, [breadcrumbs, navigate, paths, selCustomer, setBreadcrumbs]);

  return { breadcrumbs, selCustomer, isLoading, isError };
};

export const RequestStep: React.FC<RequestStepProps> = (props) => {
  const { t } = useTranslation();
  const { isLoading: isCustomerLoading, isError: isCustomerError } = useSetCustomer();
  const customer = useAtomValue(selCustomerAtom);
  const isCustomerEligible = useAtomValue(isCustomerEligibleAtom);

  const [accounts, setAccounts] = useAtom(accountsAtom);
  const setAssetIndexer = useSetAtom(assetIndexerAtom);
  const setSelectedAccounts = useSetAtom(selectedAccountsAtom);

  const { isLoading, isError } = useQueryFetch(useReleaseRequestAccountsQuery, {
    queryHookParams: { customerId: customer?.id || '', pledged: true },
    queryHookOptions: {
      enabled: !!customer?.customerId && !accounts,
      onSuccess: (data) => {
        const collateralAccounts = (data as ReleaseRequestAccountsQuery).customer
          ?.collateralAccounts;
        const assetIndexes: AssetIndexer = {};
        const selectedAccounts: SelectedAccount = {};
        collateralAccounts?.forEach((acc, accountIndex) => {
          acc?.assetHoldings?.forEach((asset, assetIndex) => {
            assetIndexes[asset?.id || ''] = { accountIndex, assetIndex };
          });
          selectedAccounts[acc?.collateralAccountId || ''] = {
            selectedAssets: 0,
            allAssets: acc?.assetHoldings?.length || 0,
          };
        });
        setAccounts(collateralAccounts);
        setAssetIndexer(assetIndexes);
        setSelectedAccounts(selectedAccounts);
      },
    },
  });

  return (
    <QuerySuspense
      error={isError || isCustomerError}
      isLoading={isLoading || isCustomerLoading}
      className="w-full"
    >
      <>
        {isCustomerEligible ? (
          <h2 className="text-lg font-bold mb-8">
            {t('releaseRequest.headers.selectAssets')}
          </h2>
        ) : (
          <Notification
            className="m-0 mb-5 self-center"
            type="error"
            data-testid="not-eligible-alert"
          >
            {customer?.serviceStatus &&
            INELIGIBLE_STATUSES.includes(customer.serviceStatus) ? (
              <>
                {t('releaseRequest.messages.notEligible')}
                <br />
                {t('common.askSupport')}
              </>
            ) : (
              t('releaseRequest.messages.notEligibleProspect')
            )}
          </Notification>
        )}
        {customer?.serviceStatus && customer?.serviceStatus !== LoanStatus.Prospect && (
          <AssetSelector />
        )}
      </>
    </QuerySuspense>
  );
};
