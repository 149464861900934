import React from 'react';
import { generatePath, useNavigate } from 'react-router';

import { Button, ButtonLink, Modal, ModalBackToTitle } from 'components';
import { ActivityAuditType } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { ResourceAccessed, useSendAuditReport } from 'services/auditReport';

import { useCustomisation, usePaths } from 'modules/root/Settings';
import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';

import { ApplicationSection } from './sections/ApplicationSection';
import { BookedLoanSection } from './sections/BookedLoanSection';
import { EvaluationSection } from './sections/EvaluationSection';

interface CustomerActvityModalProps {
  onClose: () => void;
  customerId: string;
  customerExternalId: string;
  customerName: string;
}

export const CustomerActvityModalTitle: React.FC<CustomerActvityModalProps> = ({
  onClose,
  customerName,
  customerId,
  customerExternalId,
}) => {
  const paths = usePaths();
  const { permissions } = useAccess();
  const navigate = useNavigate();
  const sendAudit = useSendAuditReport({
    audit: { activityType: ActivityAuditType.Read },
  });
  const { featureFlags } = useCustomisation();
  const { t } = useTranslation();

  const handleClickNewEvaluation = () => {
    sendAudit({
      customerExternalId,
      resourceAccessed: ResourceAccessed.CustomerActivityNewEvaluation,
    });
    const path = featureFlags?.evaluationV2
      ? paths.evaluationsV2.proceedNew
      : paths.evaluations.create;
    navigate(generatePath(path, { customerId: customerId }));
  };

  const handleClickViewCustomer = () => {
    sendAudit({
      customerExternalId,
      resourceAccessed: ResourceAccessed.CustomerActivityViewCustomerProfile,
    });
    navigate(
      generatePath(paths.customer.main, {
        customerId: customerId,
      })
    );
  };
  const { labels } = useCustomisation();

  return (
    <div className="bg-gray-50 px-10 py-4">
      <div className="flex justify-between h-9">
        <ModalBackToTitle onClose={onClose} />
      </div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-x-4">
            <span>{customerName}</span>
          </div>
        </div>
        <div className="flex flex-row gap-y-2 flex-nowrap justify-end">
          <Button
            data-testid="view-customer-full-profile-btn"
            state="secondary"
            className="h-9 gap-x-3 mr-2"
            size="md"
            onClick={handleClickViewCustomer}
          >
            {labels.viewFullCustomerProfile}
          </Button>
          {permissions.canCreateModifyRunEvaluations && (
            <Button
              data-testid="customer-new-evaluation-btn"
              className="h-9"
              size="md"
              onClick={handleClickNewEvaluation}
            >
              {labels.newEvaluation}
            </Button>
          )}

          {permissions.canCreateReleaseRequest && (
            <ButtonLink
              className="ml-2 h-9"
              data-testid="release-collateral-button"
              url={generatePath(paths.collateralRelease.proceed, {
                customerId: customerId,
              })}
            >
              {t('releaseRequest.collateralRelease')}
            </ButtonLink>
          )}

          {permissions.canCreateDrawdownRequest && (
            <ButtonLink
              className="ml-2 h-9"
              data-testid="drawdown-request-button"
              url={generatePath(paths.drawdown.proceed, { customerId: customerId })}
            >
              {t('drawdownRequest.title')}
            </ButtonLink>
          )}
        </div>
      </div>
    </div>
  );
};

export const CustomerActvityModal: React.FC<CustomerActvityModalProps> = (props) => {
  const { labels } = useCustomisation();
  return (
    <Modal
      className="w-[750px] lg:w-[850px] xl:w-[1050px]"
      isOpen={true}
      onClose={props.onClose}
      title={<CustomerActvityModalTitle {...props} />}
    >
      <div className="h-[460px] px-10 py-6 flex flex-col overflow-scroll">
        <div className="mb-6 text-lg font-semibold">{labels.loanOrigination}</div>
        <EvaluationSection
          customerId={props.customerId}
          customerExternalId={props.customerExternalId}
        />
        <ApplicationSection
          customerId={props.customerId}
          customerExternalId={props.customerExternalId}
        />
        <BookedLoanSection
          customerId={props.customerId}
          customerExternalId={props.customerExternalId}
        />
      </div>
    </Modal>
  );
};
