import React from 'react';

import { LoadingOver } from 'components';
import { ActivityAuditType, useRunReleaseRequestMutation } from 'generated/graphql';
import { useAtom, useAtomValue } from 'jotai';
import { useQueryMutation } from 'queries/apiFetch/useQueryMutation';

import { selCustomerAtom } from 'modules/ReleaseRequest/models/customer';
import { releaseRequestInputAtom } from 'modules/ReleaseRequest/models/input';
import { releaseRequestResultAtom } from 'modules/ReleaseRequest/models/result';
import {
  persistAtom,
  releaseRequestStatusAtom,
} from 'modules/ReleaseRequest/models/status';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation } from 'modules/root/Settings';

import { QueryHandler } from './QueryHandler';
import { Figures } from './sections/Figures';
import { LinkedLoans } from './sections/LinkedLoans';
import { Recommendation } from './sections/Recommendation';

interface ReleaseRequestResultProps {}

export const ReleaseRequestResult: React.FC<ReleaseRequestResultProps> = (props) => {
  const selectedCustomer = useAtomValue(selCustomerAtom);
  const [status, setStatus] = useAtom(releaseRequestStatusAtom);
  const [result, setResult] = useAtom(releaseRequestResultAtom);
  const persist = useAtomValue(persistAtom);
  const { releaseRequest } = useCustomisation();
  const loanStatus = result?.runReleaseRequest?.afterLoanInfo?.loanInfo?.status;
  const isApproved = loanStatus
    ? releaseRequest.approvedStatuses.includes(loanStatus)
    : false;

  const { mutateAsync, isLoading, error } = useQueryMutation(
    useRunReleaseRequestMutation,
    {
      extra: {
        auditReport: {
          activityType: ActivityAuditType.Write,
          customerExternalId: selectedCustomer?.customerId,
        },
      },
    }
  );
  const releaseRequestInput = useAtomValue(releaseRequestInputAtom);

  React.useEffect(() => {
    if (status === 'progress') {
      mutateAsync({
        request: {
          ...releaseRequestInput,
          customerId: persist ? selectedCustomer?.id : undefined,
          persist,
        },
      })
        .then((data) => {
          setResult(data);
          setStatus(persist ? 'saved' : 'success');
        })
        .catch(() => {
          setResult(null);
          setStatus('error');
        });
    }
  }, [
    mutateAsync,
    persist,
    releaseRequestInput,
    selectedCustomer,
    setResult,
    setStatus,
    status,
  ]);
  return (
    <QuerySuspense
      error={error}
      isLoading={isLoading && !persist}
      loadingRenderer={() => <QueryHandler.ResultLoading />}
      errorRenderer={() => (
        <QueryHandler.ResultFailedMessage
          rerunReleaseRequest={() => {
            setStatus('progress', persist ? 'persist' : 'non-persist');
          }}
        />
      )}
    >
      {result && (
        <LoadingOver show={isLoading}>
          <Recommendation
            isApproved={isApproved}
            collateralRelease={-result.runReleaseRequest.afterCalculation.adjustmentValue}
          />

          {/* Collateral value before after */}
          <Figures isApproved={isApproved} result={result.runReleaseRequest} />

          <LinkedLoans result={result.runReleaseRequest} />
        </LoadingOver>
      )}
    </QuerySuspense>
  );
};
