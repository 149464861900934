import React from 'react';

import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontIcon } from 'components';
import {
  ActivityAuditType,
  CustomerAlertsSearchResultItem,
  DataBarrierFunctionality,
  LoanStatus,
  useCustomerAlertsSearchQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import { ResourceAccessed, useSendAuditReport } from 'services/auditReport';
import { ArrayElement, DATE_FORMAT_SHORT_MONTH_NAME, formatDate } from 'utils';

import { DashboardList } from 'modules/Home/components/DashboardList';
import { ShowingLatestFive } from 'modules/Home/components/ShowingLatestFive';
import { StatusBadge } from 'modules/common';
import { CustomerIcon } from 'modules/common/CustomerIcon/CustomerIcon';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation } from 'modules/root/Settings';
import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';
import { printMoney } from 'utils/print';

import { AlertModal } from './AlertModal';

interface AlertCardProps {}

export type AlertToShow = NonNullable<
  ArrayElement<CustomerAlertsSearchResultItem['alerts']>
>;

export type ModalState = {
  customerName: string;
  alert: AlertToShow;
  customerId: string;
  customerExternalId: string;
};

export const AlertCard: React.FC<AlertCardProps> = () => {
  const { data, isLoading, error } = useQueryFetch(useCustomerAlertsSearchQuery, {
    queryHookParams: {
      fromIndex: 0,
      numResults: 5,
      dataBarrierFunctionality: DataBarrierFunctionality.Home,
    },
  });

  const customerAlerts = data?.customerAlertsSearch;
  const [modalState, setModalState] = React.useState<ModalState | null>(null);
  const sendAudit = useSendAuditReport();
  const showAlertModal = (state: ModalState) => () => {
    sendAudit({
      activityType: ActivityAuditType.Read,
      customerExternalId: state.customerExternalId,
      resourceAccessed: ResourceAccessed.ViewCustomerAlert,
    });
    setModalState(state);
  };
  const { t } = useTranslation();
  const { permissions } = useAccess();

  return permissions.canViewCollateralAccounts ? (
    <QuerySuspense
      isLoading={isLoading}
      noData={!data?.customerAlertsSearch.items?.length}
      error={error}
    >
      <ShowingLatestFive data-testid="show-top-five" />
      <DashboardList>
        {customerAlerts?.items &&
          customerAlerts.items.map(
            (customerAlert) =>
              customerAlert && (
                <DashboardList.Item key={customerAlert?.id}>
                  <Alert data={customerAlert} showAlertModal={showAlertModal} />
                </DashboardList.Item>
              )
          )}
      </DashboardList>
      {modalState && (
        <AlertModal
          onClose={() => setModalState(null)}
          customerName={modalState.customerName}
          alert={modalState.alert}
          customerId={modalState.customerId}
          customerExternalId={modalState.customerExternalId}
          onBackToAll={() => setModalState(null)}
        />
      )}
    </QuerySuspense>
  ) : (
    <div className="warning m-2" data-testid="no-permissions-message">
      {t('auth.disallow')}
    </div>
  );
};

interface AlertProps {
  data: CustomerAlertsSearchResultItem;
  showAlertModal: (state: ModalState) => () => void;
}

export const Alert: React.FC<AlertProps> = ({ data, showAlertModal }) => {
  const { t } = useTranslation();
  const { serviceStatus } = useCustomisation();
  return (
    <div
      className="flex flex-row w-full gap-x-2 overflow-hidden"
      data-testid="customer-alerts-record"
    >
      <div className="w-10">
        <CustomerIcon customerName={data.displayName} />
      </div>
      <div className="flex flex-col grow text-sm text-gray-600 min-w-0 gap-y-2">
        <div className="flex gap-x-2 font-bold" data-testid="alert-customer-name">
          {data.displayName}
        </div>
        {data.alerts.map((alert) => {
          if (!alert) {
            return null;
          }
          const showFixMessage =
            [LoanStatus.SellOut, LoanStatus.TopUp].includes(alert.status) &&
            Boolean(alert.fixAmount && alert.repaymentFixAmount);
          return (
            <div
              data-testid="alert-record"
              key={alert.obligorObligation}
              className="border-b last:border-b-0 pb-3 last:pb-0 cursor-pointer flex flex-col gap-y-2"
              onClick={showAlertModal({
                alert: alert,
                customerName: data.displayName,
                customerId: data?.id,
                customerExternalId: data?.customerExternalId,
              })}
            >
              <div className="flex flex-row gap-x-2" data-testid="alert-loan">
                <div className="whitespace-nowrap text-2xs">
                  <StatusBadge
                    status={alert.status}
                    size="sm"
                    label={serviceStatus[alert.status].label}
                  />
                </div>
                <div className="grow whitespace-nowrap overflow-hidden text-ellipsis text-xs">
                  <span className="lowercase mr-1">{t('common.on')}</span>
                  <span className="font-bold" data-testid="loan-number">
                    {alert.obligorObligation}
                  </span>
                </div>

                <div className="text-xs whitespace-nowrap" data-testid="date-opened">
                  {formatDate(alert.dateOpened, DATE_FORMAT_SHORT_MONTH_NAME)}
                </div>
                <div className="flex items-center">
                  <FontIcon
                    size="sm"
                    className="text-gray-300 mr-2"
                    icon={faAngleRight}
                  />
                </div>
              </div>
              {showFixMessage && (
                <div
                  className="text-xs text-gray-600 flex gap-x-1"
                  data-testid="alert-fix-amount"
                >
                  <span>{t('dashboard.alerts.addCollateral')}</span>
                  <span>{printMoney(alert.fixAmount)}</span>
                  <span>{t('common.or')}</span>
                  <span>{t('dashboard.alerts.paydown')}</span>
                  <span>{printMoney(alert.repaymentFixAmount)}</span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
