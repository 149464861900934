import React from 'react';

import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { FontIcon } from 'components';

interface EligibilityBadgeProps {
  eligible: boolean;
  yes: string;
  no: string;
}

export const EligibilityBadge: React.FC<EligibilityBadgeProps> = ({
  eligible,
  yes,
  no,
}) => {
  return (
    <div
      data-testid="eligibility-badge"
      className={clsx(
        'rounded-sm uppercase text-3xs tracking-widest px-2 leading-4 whitespace-nowrap',
        {
          'bg-green-50 text-green-700': eligible,
          'bg-red-50 text-red-600': !eligible,
        }
      )}
    >
      <div className="flex gap-2 items-center">
        {eligible ? (
          <>
            <FontIcon icon={faCheck} /> {yes}
          </>
        ) : (
          <>
            <FontIcon icon={faClose} /> {no}
          </>
        )}
      </div>
    </div>
  );
};
