import React from 'react';
import { Navigate } from 'react-router-dom';

import clsx from 'clsx';
import { Loading, NavIcon } from 'components';
import { useTranslation } from 'react-i18next';

import { usePageTitle, usePaths } from 'modules/root/Settings';
import { useBranding } from 'modules/root/Settings/contexts/branding';
import { IDP_PROVIDER_DASHBOARD_URL } from 'modules/root/Settings/envVars';
import { useAuth } from 'modules/root/auth/auth.context';

const backgroundStyles = clsx(
  'h-full',
  'flex',
  'items-center',
  'flex-col',
  'justify-center',
  'bg-gradient-to-br',
  'from-primary-400',
  'to-primary-500'
);

const containerStyles = clsx(
  'bg-white',
  'rounded-md',
  'border',
  'border-gray-200',
  'shadow-md p-2',
  'w-[440px]',
  'min-h-[240px]'
);

const AUTH0_CONTAINER_ID = 'container';

const LoginForm: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { loginLogo } = useBranding();
  const { isAuthenticated, isLoading, login } = useAuth();
  const paths = usePaths();
  const isMounted = React.useRef(false);

  React.useLayoutEffect(() => {
    if (isMounted.current) {
      return;
    }
    setTimeout(() => {
      if (document.getElementById(AUTH0_CONTAINER_ID)) {
        login({
          callbackFn: () => {
            if (IDP_PROVIDER_DASHBOARD_URL) {
              setTimeout(() => {
                const button = document.querySelector(
                  '.auth0-lock-social-button'
                ) as HTMLElement;
                if (button) {
                  button.click();
                }
              }, 2000);
            }
          },
        });
      }
    }, 500);
    isMounted.current = true;
  }, [login]);

  usePageTitle(t('auth.signIn'));

  if (isAuthenticated && !isLoading) {
    return <Navigate to={paths.initial} />;
  }
  return (
    <div className={backgroundStyles} data-testid="login-page">
      {isLoading ? (
        <Loading />
      ) : (
        <div id="center">
          <div
            className={clsx(
              'flex',
              'select-none',
              'justify-around',
              'items-center',
              'mb-4',
              'w-[420px]',
              'items-center',
              'text-white'
            )}
          >
            <NavIcon
              data-testid="login-logo"
              className="h-10"
              alt="logo"
              source={loginLogo}
            />
            <div className={clsx('text-3xl', 'font-bold', 'leading-[48px]')}>
              {t('common.navigator')}
            </div>
          </div>

          <div id={AUTH0_CONTAINER_ID} className={containerStyles}></div>
        </div>
      )}
    </div>
  );
});

LoginForm.displayName = 'PureLoginForm';

export { LoginForm };
