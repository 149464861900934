import React from 'react';

import { ButtonLink, Pagination, useClientPagination } from 'components';
import { useTranslation } from 'react-i18next';


const ROWS_PER_PAGE = 3;

interface ActivitySectionProps {
  label: string;
  items: unknown[];
  children: (items: any) => React.ReactElement | React.ReactElement[];
  btn?: {
    path: string;
    title: string;
    onClick?: () => void;
  };
}



export const ActivitySection: React.FC<ActivitySectionProps> = ({
  label,
  children,
  items,
  btn,
}) => {
  const { t } = useTranslation();
  const { page, nextPage, previousPage, changePage, total, currentPageItems } =
    useClientPagination(items, 3);

  return (
    <div>
      <div
        className="flex flex-row items-center py-4 px-3 h-[56px] bg-secondary-50
     border-l border-t border-r rounded-tl-xl rounded-tr-xl justify-between mt-2 w-full"
      >
        <div className="flex items-center">
          <div className="text-gray-700 font-semibold">{label}</div>
          <div className="ml-4 uppercase text-xs text-gray-500 font-semibold tracking-wider">{`${t(
            'common.total'
          )}: ${total}`}</div>
          {btn && (
            <ButtonLink
              data-testid="view-loan-summary-details"
              state="secondary"
              className="ml-5"
              size="sm"
              url={btn.path}
              onClick={btn.onClick}
            >
              {btn.title}
            </ButtonLink>
          )}
        </div>
        <div className="basis-[280px] mr-3">
          <Pagination
            currentPage={page}
            pageRange={0}
            rowsPerPage={ROWS_PER_PAGE}
            totalRows={total}
            nextPage={nextPage}
            previousPage={previousPage}
            onChange={changePage}
          />
        </div>
      </div>
      <div className="p-4 max-h-[208px] min-h-[35px] bg-white border-l border-b border-r rounded-bl-xl rounded-br-xl">
        {children(currentPageItems)}
      </div>
    </div>
  );
};
