import React from 'react';

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { DayPicker } from 'react-day-picker';

import { FontIcon } from 'components/FontIcon/FontIcon';
import { cn } from 'utils/styles';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

// Based on https://ui.shadcn.com/docs/components/calendar
export const Calendar: React.FC<CalendarProps> = ({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}) => {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col space-y-4',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          'border border-input bg-background hover:bg-gray-100 hover:text-text-secondary',
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-text-primary/90 rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2 gap-1',
        cell: cn(
          'h-9 w-9 text-center text-sm p-0 relative',
          '[&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-100/50',
          '[&:has([aria-selected])]:bg-gray-100 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md',
          'focus-within:relative focus-within:z-20'
        ),
        day: cn(
          'hover:bg-gray-100 hover:text-text-secondary rounded',
          'h-9 w-9 p-0 font-normal aria-selected:opacity-100'
        ),
        day_range_end: 'day-range-end',
        day_selected:
          'bg-primary-600 text-white hover:bg-primary-200 hover:text-text-primary focus:bg-primary-600 focus:text-white',
        day_today: 'bg-gray-50 border border-primary-200 text-text-primary',
        day_outside:
          'day-outside text-text-primary/70 aria-selected:bg-primary-200/70 aria-selected:text-text-primary',
        day_disabled: 'text-text-secondary opacity-50',
        day_range_middle:
          'aria-selected:bg-gray-100 aria-selected:text-text-secondary',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <FontIcon icon={faChevronLeft} className="h-4 w-4" />,
        IconRight: ({ ...props }) => (
          <FontIcon icon={faChevronRight} className="h-4 w-4" />
        ),
      }}
      {...props}
    />
  );
};
