import React from 'react';

import { useAtom } from 'jotai';

import { ID_STEPS, currentStepAtom } from '../models/step';
import { AccountSelector } from './steps/AccountSelector/AccountSelector';
import { CreditPolicySelector } from './steps/CreditPolicySelector/CreditPolicySelector';
import { CustomerSelectorStep } from './steps/CustomerSelectorStep/CustomerSelectorStep';

interface EvaluationStepProps {}

export const EvaluationStep: React.FC<EvaluationStepProps> = (props) => {
  const [step] = useAtom(currentStepAtom);

  return step?.active ? (
    <div className="flex flex-col items-center w-full justify-between h-full mt-5">
      {step.id === ID_STEPS.Customer && <CustomerSelectorStep className="mb-14" />}
      {step.id === ID_STEPS.Accounts && <AccountSelector className="mb-14" />}
      {step.id === ID_STEPS.CreditPolicy && <CreditPolicySelector />}
    </div>
  ) : null;
};
