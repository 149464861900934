import { DrawdownRequestInput } from 'generated/graphql';
import { atom } from 'jotai';

import { amountAtom, loanAtom } from './amount';
import { selCustomerAtom } from './customer';
import { detailsAtom } from './details';
import { persistAtom } from './status';

type DrawdownRequestInputAtom = DrawdownRequestInput;

export const drawdownRequestInputAtom = atom<DrawdownRequestInputAtom>((get) => {
  const amount = get(amountAtom);
  const loan = get(loanAtom);
  const selectedCustomer = get(selCustomerAtom);
  const persist = get(persistAtom);
  const details = get(detailsAtom);
  return {
    // required
    customerId: selectedCustomer?.id || '',
    drawDownAmount: amount || 0,
    obligation: loan?.obligation || '',
    obligor: loan?.obligor || '',

    // optional
    accountNumber: details.accountNumber,
    routingNumber: details.routingNumber,
    preferredPaymentMethod: details.preferredPaymentMethod,
    drawDownDate: details.drawDownDate,

    // Save (true / false)
    persist: persist || false,
  };
});
