import React from 'react';
import { useOutletContext } from 'react-router';

import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import { Ellipsis, HelpIcon, LoadingOver, TableSkeleton } from 'components';
import {
  ActivityAuditType,
  EvaluationSearchResultItem,
  EvaluationSortableFieldEnum,
  EvaluationsListQueryVariables,
  useEvaluationsListQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import { useDownloadPdf } from 'services';

import {
  ReactTable,
  SearchQueryParams,
  useLimitedPagination,
  useSearchQueryParams,
  useSorting,
} from 'components/ReactTable';
import { EvaluationBadge } from 'modules/common/EvaluationBadge/EvaluationBadge';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePageTitle } from 'modules/root/Settings';
import { notEmpty } from 'utils/helpers';
import { EMPTY_DASH, printDate, printMoney, printPrimitive } from 'utils/print';

import { EvaluationAction } from './Actions/EvaluationAction';

const DEFAULT_PAGE_SIZE = 10;
const QUERY_LIMIT = 10000;

const useEvaluationQuery = (queryParams: SearchQueryParams) => {
  const [currentItems, setCurrentItems] = React.useState<
    null | EvaluationSearchResultItem[]
  >(null);
  const [total, setTotal] = React.useState(0);
  const { data, error, isLoading, refetch, isFetched, isFetching } = useQueryFetch(
    useEvaluationsListQuery,
    {
      queryHookParams: queryParams as EvaluationsListQueryVariables,
      queryHookOptions: { enabled: false },
      extra: {
        auditReport: {
          activityType: queryParams.searchText
            ? ActivityAuditType.Search
            : ActivityAuditType.Read,
        },
      },
    }
  );

  React.useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch, queryParams]);

  React.useEffect(() => {
    if (isFetched && data && !error) {
      const items = data?.evaluationsSearch?.items?.filter(notEmpty) || [];
      setCurrentItems(items);
      setTotal(data?.evaluationsSearch?.totalHits);
    }
  }, [data, error, isFetched, queryParams.searchText]);

  return {
    total,
    items: currentItems,
    isLoading: currentItems === null && isLoading,
    isUpdating: isFetching,
    error,
  };
};

export const EvaluationsTable: React.FC = () => {
  const { t } = useTranslation();
  const { download, isError: pdfDownloadError } = useDownloadPdf();
  const { labels, evaluationsList, helpText } = useCustomisation();
  usePageTitle(labels.evaluations);

  const columnHelper = createColumnHelper<EvaluationSearchResultItem>();
  const columns = React.useMemo(() => {
    const headerCellsFromConfig = [];
    if (evaluationsList.showFields.customerName) {
      headerCellsFromConfig.push(
        columnHelper.accessor('customerName', {
          header: labels.customer,
          id: EvaluationSortableFieldEnum.CustomerNamePlain,
          cell: ({ getValue }) => (
            <Ellipsis data-testid="customer-name" className="font-semibold max-w-[150px]">
              {printPrimitive(getValue())}
            </Ellipsis>
          ),
        })
      );
    }
    if (evaluationsList.showFields.externalId) {
      headerCellsFromConfig.push(
        columnHelper.accessor('externalId', {
          header: () => (
            <div className="flex flex-nowrap items-center gap-1">
              {labels.id}
              <HelpIcon text={helpText.evaluationId} />
            </div>
          ),
          cell: ({ getValue }) => <Ellipsis>{getValue()}</Ellipsis>,
        })
      );
    }
    if (evaluationsList.showFields.lastEvaluated) {
      headerCellsFromConfig.push(
        columnHelper.accessor('lastEvaluated', {
          header: labels.date,
          cell: ({ getValue }) => <>{printDate(getValue())}</>,
        })
      );
    }
    if (evaluationsList.showFields.commitmentAmount) {
      headerCellsFromConfig.push(
        columnHelper.accessor('commitmentAmount', {
          header: labels.commitmentAmount,
          cell: ({ getValue }) => <>{printMoney(getValue())}</>,
        })
      );
    }
    if (evaluationsList.showFields.status) {
      headerCellsFromConfig.push(
        columnHelper.accessor('status', {
          header: labels.status,
          cell: ({ getValue }) => {
            const value = getValue();
            return value ? <EvaluationBadge status={value} /> : <>{EMPTY_DASH}</>;
          },
        })
      );
    }
    if (evaluationsList.showFields.actions) {
      headerCellsFromConfig.push(
        columnHelper.accessor('id', {
          header: labels.actions,
          cell: ({ row }) => (
            <EvaluationAction evaluation={row.original} downloadPdf={download} />
          ),
          enableSorting: false,
        })
      );
    }
    return headerCellsFromConfig;
  }, [labels, evaluationsList, columnHelper, helpText, download]);

  const { searchValue } = useOutletContext<{ searchValue: string }>();
  const { handleSortChange, sorting } = useSorting();
  const { handlePageChange, isLimitReached, pagination } = useLimitedPagination(
    DEFAULT_PAGE_SIZE,
    QUERY_LIMIT
  );
  const queryParams = useSearchQueryParams({
    sorting,
    pagination,
    searchText: searchValue,
  });
  const { total, items, error, isLoading, isUpdating } = useEvaluationQuery(queryParams);
  React.useEffect(() => {
    handlePageChange({ pageSize: DEFAULT_PAGE_SIZE, pageIndex: 0 });
  }, [handlePageChange, searchValue]);

  const sortingMemo = React.useMemo(
    () => ({
      onChange: handleSortChange,
      state: sorting,
    }),
    [sorting, handleSortChange]
  );
  const paginationMemo = React.useMemo(
    () => ({
      onChange: handlePageChange,
      state: pagination,
      total,
    }),
    [handlePageChange, pagination, total]
  );

  return (
    <>
      <QuerySuspense
        error={error}
        isLoading={isLoading}
        noData={Boolean(!items?.length && !isLoading && !isUpdating)}
        noDataMessage={searchValue ? t('common.searchBox.noRecords') : undefined}
        loadingRenderer={() => <TableSkeleton />}
      >
        {pdfDownloadError && (
          <div data-testid="download-failed-msg" className="alert">
            {t('common.error.unspecific')}
          </div>
        )}
        {searchValue && total > QUERY_LIMIT && (
          <div className={clsx('warning', 'mt-4')}>
            {t('common.searchBox.manyRecords')}
          </div>
        )}
        {items && (
          <LoadingOver show={isUpdating}>
            <ReactTable<EvaluationSearchResultItem>
              data={items}
              columns={columns}
              data-testid="evaluations-table"
              stickyFromTopPx={90}
              sorting={sortingMemo}
              pagination={paginationMemo}
            />
          </LoadingOver>
        )}
      </QuerySuspense>
      {isLimitReached && (
        <div className={clsx('float-right', 'alert', 'mt-4')}>
          {t('common.pagination.limitRecords')}
        </div>
      )}
    </>
  );
};
