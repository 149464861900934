import React from 'react';

import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Button, FontIcon } from 'components';
import { useTranslation } from 'react-i18next';

interface ResultFailedMessageProps {
  rerunReleaseRequest: () => void;
}

export const ResultFailedMessage: React.FC<ResultFailedMessageProps> = ({
  rerunReleaseRequest,
}) => {
  const { t } = useTranslation();

  return (
    <div className="text-center" data-testid="release-request-error">
      <h2 className="mb-10 text-xl">{t('common.error.processingFailed')}</h2>
      <div>
        <FontIcon size="3x" className="text-red-500" icon={faCircleExclamation} />
      </div>
      <div className="mt-10 mb-8">{t('releaseRequest.messages.processingFailedMSG')}</div>
      <div>
        <Button onClick={rerunReleaseRequest} data-testid="release-request-try-again">
          {t('common.tryAgain')}
        </Button>
      </div>
    </div>
  );
};

const ResultLoading: React.FC = () => {
  return (
    <>
      <div className="flex animate-pulse flex-col gap-2 items-center my-6">
        <div className="bg-gray-100 w-full h-8 rounded-xl"></div>
        <div className="bg-gray-100 w-full h-12 rounded-xl"></div>
      </div>
      <div
        className="animate-pulse flex flex-col gap-2"
        data-testid="release-request-processing"
      >
        <div className="flex flex-col gap-3 mb-6">
          <div className="bg-gray-100 w-full h-32 rounded-xl"></div>
        </div>
        <div className="mb-14 h-56 w-full bg-gray-100 rounded-md" />
      </div>
    </>
  );
};

export const QueryHandler = {
  ResultFailedMessage,
  ResultLoading,
};
