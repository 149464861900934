import React from 'react';

import { faCheckCircle, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontIcon } from 'components';
import { Trans, useTranslation } from 'react-i18next';
import { printMoney } from 'utils';
import { Recommendation } from 'generated/graphql';


interface RecommendationSectionProps {
  drawdownAmount: number;
  warnings?: string[];
  recommendation: Recommendation;
}

export const RecommendationSection: React.FC<RecommendationSectionProps> = ({
  drawdownAmount,
  recommendation,
  warnings
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 items-center">
      <div className="text-green-600">
        {(recommendation === Recommendation.Recommend || recommendation === Recommendation.RecommendWithWarnings) && (
          <FontIcon className="text-green-600" size="2x" icon={faCheckCircle} />
        )}
        {recommendation === Recommendation.NotRecommended &&
          (
            <FontIcon className="text-red-600" size="2x" icon={faCircleXmark} />
          )}
      </div>
      <h3 className="flex gap-2">
        <span>{t('drawdownRequest.recommendation')}</span>
        <span>{'-'}</span>
        {(recommendation === Recommendation.Recommend || recommendation === Recommendation.RecommendWithWarnings) && (
          <span className="text-green-600">{t('drawdownRequest.approved')}</span>
        )}
        {recommendation === Recommendation.NotRecommended && (
          <span className="text-red-600">{t('drawdownRequest.declined')}</span>
        )}
      </h3>
      <div className="text-base">
        <Trans
          i18nKey={
            (recommendation === Recommendation.Recommend ||
              recommendation === Recommendation.RecommendWithWarnings)
              ? 'drawdownRequest.approvedMsg' : 'drawdownRequest.declinedMsg'
          }
          values={{
            drawdownAmount: printMoney(drawdownAmount),
          }}
          components={{ bold: <span className="font-semibold tracking-wider" /> }}
        />
      </div>
      {warnings && warnings.length > 0 && (
        <div className='warning text-left m-0 mt-3'>
          <ul className="list-disc ml-5">
            {warnings.map((warning, index) => (
              <li key={index} className="text-sm">{warning}</li>
            ))}
          </ul>
        </div>
      )}

    </div>
  );
};
