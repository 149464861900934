import React from 'react';

import { faDownload, faFile } from '@fortawesome/free-solid-svg-icons';
import { ActionMenu, FontIcon, notification } from 'components';
import {
  ActivityAuditType,
  ReleaseRequestSearchResultItem,
  useAuditReportMutation,
} from 'generated/graphql';
import { graphqlClient, useAuthClient } from 'queries/apiFetch/graphqlClient';
import { useTranslation } from 'react-i18next';
import { ResourceAccessed } from 'services/auditReport';
import { DATE_FORMAT_DASH, formatDate } from 'utils';

import { useCustomisation } from 'modules/root/Settings';
import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';

interface CollateralReleaseActionsProps {
  releaseRequest: ReleaseRequestSearchResultItem;
  downloadPdf: (urlPath: string, filename: string) => Promise<void>;
}

export const CollateralReleaseActions: React.FC<CollateralReleaseActionsProps> = ({
  releaseRequest,
  downloadPdf,
}) => {
  const { permissions } = useAccess();
  const {
    labels,
    releaseRequestList: {
      showFields: { actions: actionsConfig },
    },
  } = useCustomisation();
  const authClient = useAuthClient(graphqlClient);
  const { mutateAsync } = useAuditReportMutation(authClient);
  const { t } = useTranslation();

  const releaseRequestActions = React.useMemo(() => {
    const handleDownloadPdf = (releaseRequest: ReleaseRequestSearchResultItem) => {
      let date = new Date();
      const offset = date.getTimezoneOffset();
      const fileName = `CollateralRelease_${releaseRequest.externalId}_${formatDate(
        date.toISOString(),
        DATE_FORMAT_DASH
      )}`;
      const urlPath = `/schema/release-request/${
        releaseRequest.externalId
      }/pdf?timeZoneOffset=${-offset}`;
      const commonAuditData = {
        activityType: ActivityAuditType.Read,
        resourceAccessed: ResourceAccessed.DownloadReleaseRequestDocs,
        customerExternalId: releaseRequest.customerExternalId,
      };
      downloadPdf(urlPath, fileName)
        .then(() => {
          mutateAsync({
            ...commonAuditData,
            queryDetails: JSON.stringify({ externalId: releaseRequest.externalId }),
          });
        })
        .catch((error) => {
          mutateAsync({
            ...commonAuditData,
            queryDetails: JSON.stringify({
              externalId: releaseRequest.externalId,
              error: true,
            }),
          });
          notification.error(error?.message || t('common.error.unspecific'));
        });
    };

    const actions = [];
    if (actionsConfig.view && permissions.canViewReleaseRequest) {
      actions.push({
        action: (row: ReleaseRequestSearchResultItem) => {},
        text: labels.view,
        icon: <FontIcon icon={faFile} />,
      });
    }
    if (actionsConfig.downloadPdf && permissions.canAccessReleaseRequestPdf) {
      actions.push({
        action: (row: ReleaseRequestSearchResultItem) => handleDownloadPdf(row),
        text: labels.downloadPdf,
        icon: <FontIcon icon={faDownload} />,
      });
    }
    return actions;
  }, [actionsConfig, downloadPdf, mutateAsync, labels, permissions, t]);

  return (
    <ActionMenu
      position="center"
      items={releaseRequestActions}
      data={releaseRequest}
      data-testid="release-request-action-menu"
    />
  );
};
