import { atom } from 'jotai';

import { CustomerSelection } from 'modules/common';
import { INELIGIBLE_STATUSES } from 'modules/common/constants/status';

import { ID_STEPS, stepsAtom } from './step';

type CustomerAtom = CustomerSelection | null;

export const isCustomerEligibleAtom = atom<boolean>(false);

export const selCustomerAtom = atom<CustomerAtom, [CustomerAtom], void>(
  null,
  (get, set, update: CustomerAtom) => {
    if (update) {
      const steps = get(stepsAtom);
      // enable next step after Customer selection
      const index = steps.findIndex((step) => step.id === ID_STEPS.Customer);
      const updatedSteps = steps.map((step, i) => {
        if (i === index) {
          return { ...step, valid: true };
        } else if (i === index + 1) {
          return { ...step, enable: true };
        }
        return step;
      });
      set(stepsAtom, updatedSteps);

      const isEligible = !(
        update.serviceStatus && INELIGIBLE_STATUSES.includes(update.serviceStatus)
      );
      set(isCustomerEligibleAtom, isEligible);
    }
    set(selCustomerAtom, update);
  }
);
