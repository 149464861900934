import React from 'react';

import { CheckboxOnChange, HelpIcon, notification } from 'components';
import { HoldingType } from 'generated/graphql';
import { useAtomValue, useSetAtom } from 'jotai/react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EmptyCards } from 'assets/icons/emptyCards.svg';

import { Toggle } from 'components/Toggle/Toggle';
import { SearchBox } from 'modules/Customers/SearchBox/SearchBox';
import {
  AssetHoldings,
  ReleaseRequestAccounts,
  accountsAtom,
} from 'modules/ReleaseRequest/models/account';
import { isCustomerEligibleAtom } from 'modules/ReleaseRequest/models/customer';
import {
  SelectedAsset,
  assetIndexerAtom,
  selectedAccountsAtom,
  selectedAssetsAtom,
} from 'modules/ReleaseRequest/models/input';
import { useCustomisation } from 'modules/root/Settings';

import { AccountItem } from '../AccountItem/AccountItem';

interface UpdateSelectedAccountsFunction {
  (accountId: string, operation: 'increment' | 'decrement'): void;
  (accountId: string, operation: 'set', selectedAssets: number): void;
}

const CASH_SUB_TYPE = 'Cash';

interface AssetSelectorProps {}
interface FilterParams {
  searchText?: string;
  isCashOnly?: boolean;
}

const searchAssets = (assets: AssetHoldings, searchText: string) => {
  const filteredAssets = assets?.filter(
    (asset) =>
      (asset?.assetName && asset?.assetName.toUpperCase().includes(searchText)) ||
      asset?.securityId.toUpperCase().includes(searchText)
  );
  return filteredAssets;
};

const filterCashAssets = (assets: AssetHoldings) => {
  return assets?.filter((asset) => asset?.assetSubType === CASH_SUB_TYPE);
};

export const AssetSelector: React.FC<AssetSelectorProps> = (props) => {
  const { t } = useTranslation();
  const isCustomerEligible = useAtomValue(isCustomerEligibleAtom);
  const accounts = useAtomValue(accountsAtom);
  const assetIndexer = useAtomValue(assetIndexerAtom);
  const [filteredAccounts, setFilteredAccounts] =
    React.useState<ReleaseRequestAccounts>(null);
  const setSelectedAssets = useSetAtom(selectedAssetsAtom);
  const setSelectedAccounts = useSetAtom(selectedAccountsAtom);
  const [filterParams, setFilterParams] = React.useState<FilterParams>({});
  const { helpText } = useCustomisation();

  const updateSelectedAccounts: UpdateSelectedAccountsFunction = (
    accountId: string,
    operation: 'increment' | 'decrement' | 'set',
    selectedAssets: number = 0
  ) => {
    setSelectedAccounts((selectedAccounts) => {
      const updatedAssets =
        operation === 'set'
          ? selectedAssets
          : selectedAccounts[accountId].selectedAssets +
            (operation === 'increment' ? 1 : -1);
      return {
        ...selectedAccounts,
        [accountId]: {
          ...selectedAccounts[accountId],
          selectedAssets: updatedAssets,
        },
      };
    });
  };

  const selectAccount = (accountId: string, checked: boolean) => {
    setSelectedAssets((assets: SelectedAsset) => {
      const otherAssets = Object.fromEntries(
        Object.entries(assets).filter(([_, asset]) => asset.accountId !== accountId)
      );
      if (checked) {
        const account = accounts?.find((acc) => acc?.collateralAccountId === accountId);
        const accountAssets = account?.assetHoldings?.reduce<SelectedAsset>(
          (acc, asset) => {
            if (asset) {
              acc[asset.id] = {
                accountId,
                amount: asset.marketValue || 0,
                unit: asset.quantity,
                releaseAll: true,
                type: HoldingType.Quantity,
                securityId: asset.securityId,
              };
            }
            return acc;
          },
          {}
        );
        updateSelectedAccounts(accountId, 'set', account?.assetHoldings?.length || 0);
        return { ...otherAssets, ...accountAssets };
      } else {
        updateSelectedAccounts(accountId, 'set', 0);
        return otherAssets;
      }
    });
  };

  const selectAsset = (assetId: string, checked: boolean) => {
    setSelectedAssets((assets: SelectedAsset) => {
      const { accountIndex, assetIndex } = assetIndexer[assetId];
      const accountId = accounts?.[accountIndex]?.collateralAccountId || '';
      const asset = accounts?.[accountIndex]?.assetHoldings?.[assetIndex];
      if (checked) {
        updateSelectedAccounts(accountId, 'increment');
        return {
          ...assets,
          [assetId]: {
            accountId,
            amount: asset?.marketValue || 0,
            unit: asset?.quantity || 0,
            releaseAll: true,
            type: HoldingType.Quantity,
            securityId: asset?.securityId || '',
          },
        };
      }
      delete assets[assetId];
      updateSelectedAccounts(accountId, 'decrement');
      return { ...assets };
    });
  };

  const handleChange: CheckboxOnChange = ({ name, checked, value }) => {
    if (name === 'account') {
      const accountId = value || '';
      if (checked) {
        notification.info(t('releaseRequest.messages.accountFullyReleased'));
      }
      selectAccount(accountId, checked);
    } else if (name === 'asset') {
      const assetId = value || '';
      selectAsset(assetId, checked);
    }
  };

  React.useEffect(() => {
    const searchText = filterParams?.searchText?.toUpperCase().trim();
    let filterAccs = null;
    if (searchText && searchText.length > 2 && accounts) {
      const accountsToFilter = accounts;
      filterAccs = accountsToFilter.reduce<ReleaseRequestAccounts>((accs, acc) => {
        const filteredAssets = searchAssets(acc?.assetHoldings, searchText);
        if (acc && filteredAssets?.length !== 0) {
          accs?.push({ ...acc, assetHoldings: filteredAssets });
        }
        return accs;
      }, []);
      setFilteredAccounts(filterAccs);
    }
    if (filterParams?.isCashOnly && accounts) {
      const accountsToFilter = filterAccs || accounts;
      filterAccs = accountsToFilter.reduce<ReleaseRequestAccounts>((accs, acc) => {
        const filteredAssets = filterCashAssets(acc?.assetHoldings);
        if (acc && filteredAssets?.length !== 0) {
          accs?.push({ ...acc, assetHoldings: filteredAssets });
        }
        return accs;
      }, []);
    }
    setFilteredAccounts(filterAccs);
  }, [accounts, filterParams]);

  const handleSearch = React.useCallback(
    (value: string) => setFilterParams({ ...filterParams, searchText: value }),
    [filterParams]
  );

  const handleCashOnly = React.useCallback(
    (isCashOnly: boolean) => setFilterParams({ ...filterParams, isCashOnly }),
    [filterParams]
  );

  const displayedAccounts = filteredAccounts ? filteredAccounts : accounts;

  return (
    <div>
      <div className="flex justify-between items-center mb-8">
        <SearchBox
          className="basis-3/5"
          onChange={handleSearch}
          placeholder={t('releaseRequest.findAssetsToRelease')}
        />
        <div className="flex gap-2 items-center">
          <Toggle label={t('releaseRequest.cashOnly')} onChange={handleCashOnly} />
          <HelpIcon text={helpText.cashOnly} />
        </div>
      </div>
      <div className="flex flex-col whitespace-pre-wrap items-center">
        {(() => {
          if (displayedAccounts && displayedAccounts.length) {
            return (
              <div className="flex flex-col w-full">
                <div className="flex flex-col gap-4 w-full mb-8 overflow-y-auto">
                  {displayedAccounts.map((account) => (
                    <AccountItem
                      key={account?.collateralAccountId}
                      account={account}
                      onChange={handleChange}
                      inactive={!isCustomerEligible}
                    />
                  ))}
                </div>
              </div>
            );
          } else if (!accounts || accounts.length === 0) {
            return (
              <div className="text-center">
                <h2 className="font-bold text-lg mb-8">
                  {t('accounts.noAccountsRetrieved')}
                </h2>
                <EmptyCards data-testid="empty-cards" className="mx-auto my-10 h-48" />
              </div>
            );
          } else if (displayedAccounts && displayedAccounts.length === 0) {
            return (
              <div data-testid="no-data" className="notification">
                {t('releaseRequest.messages.noAssets')}
              </div>
            );
          }
        })()}
      </div>
    </div>
  );
};
