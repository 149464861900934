import React from 'react';
import { useOutletContext } from 'react-router';

import { SortingState, createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import {
  Ellipsis,
  HelpIcon,
  LoadingOver,
  ReactTable,
  SearchQueryParams,
  TableSkeleton,
  useLimitedPagination,
  useSearchQueryParams,
  useSorting,
} from 'components';
import {
  ActivityAuditType,
  DrawdownRequestSearchQueryVariables,
  DrawdownRequestSearchResultItem,
  DrawdownRequestSortableFieldEnum,
  useDrawdownRequestSearchQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import { useDownloadPdf } from 'services';
import { printDate, printMoney, printPrimitive } from 'utils';

import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePageTitle } from 'modules/root/Settings';
import { notEmpty } from 'utils/helpers';

import { DrawdownRequestActions } from './DrawdownRequestActions';

const DEFAULT_PAGE_SIZE = 10;
const QUERY_LIMIT = 10000;

interface DrawdownRequestsTableProps {}

const useDrawdownRequestQuery = (queryParams: SearchQueryParams) => {
  const [currentItems, setCurrentItems] = React.useState<
    null | DrawdownRequestSearchResultItem[]
  >(null);
  const [total, setTotal] = React.useState(0);
  const { data, error, isLoading, refetch, isFetched, isFetching } = useQueryFetch(
    useDrawdownRequestSearchQuery,
    {
      queryHookParams: queryParams as DrawdownRequestSearchQueryVariables,
      queryHookOptions: { enabled: false },
      extra: {
        auditReport: {
          activityType: queryParams.searchText
            ? ActivityAuditType.Search
            : ActivityAuditType.Read,
        },
      },
    }
  );

  React.useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch, queryParams]);

  React.useEffect(() => {
    if (isFetched && data && !error) {
      const items = data?.drawdownRequestSearch?.items?.filter(notEmpty) || [];
      setCurrentItems(items);
      setTotal(data?.drawdownRequestSearch?.totalHits);
    }
  }, [data, error, isFetched, queryParams.searchText]);

  return {
    total,
    items: currentItems,
    isLoading: currentItems === null && isLoading,
    isUpdating: isFetching,
    error,
  };
};

export const DrawdownRequestsTable: React.FC<DrawdownRequestsTableProps> = () => {
  const { t } = useTranslation();
  const { download } = useDownloadPdf();
  const { labels, drawdownRequestList, helpText } = useCustomisation();
  const columnHelper = createColumnHelper<DrawdownRequestSearchResultItem>();
  usePageTitle(t('drawdownRequest.servicingTitle'));
  const { searchValue } = useOutletContext<{ searchValue: string }>();

  const { handleSortChange, sorting } = useSorting();
  const [updatedSorting, setUpdatedSorting] = React.useState<SortingState>(sorting);
  const { handlePageChange, isLimitReached, pagination } = useLimitedPagination(
    DEFAULT_PAGE_SIZE,
    QUERY_LIMIT
  );
  const queryParams = useSearchQueryParams({
    pagination,
    searchText: searchValue,
    sorting: updatedSorting,
  });

  const columns = React.useMemo(() => {
    const headerCellsFromConfig = [];
    if (drawdownRequestList.showFields.customerName) {
      headerCellsFromConfig.push(
        columnHelper.accessor('customerName', {
          header: labels.customer,
          id: DrawdownRequestSortableFieldEnum.CustomerNamePlain,
          cell: ({ getValue }) => (
            <Ellipsis data-testid="customer-name" className="font-semibold max-w-[150px]">
              {printPrimitive(getValue())}
            </Ellipsis>
          ),
        })
      );
    }
    if (drawdownRequestList.showFields.externalId) {
      headerCellsFromConfig.push(
        columnHelper.accessor('externalId', {
          header: labels.id,
          id: DrawdownRequestSortableFieldEnum.ExternalId,
          cell: ({ getValue }) => <Ellipsis>{getValue()}</Ellipsis>,
        })
      );
    }
    if (drawdownRequestList.showFields.lastUpdateDate) {
      headerCellsFromConfig.push(
        columnHelper.accessor('lastUpdateDate', {
          header: labels.date,
          id: DrawdownRequestSortableFieldEnum.LastUpdateDate,
          cell: ({ getValue }) => <>{printDate(getValue())}</>,
        })
      );
    }
    if (drawdownRequestList.showFields.loanNumber) {
      headerCellsFromConfig.push(
        columnHelper.accessor('obligor', {
          header: () => (
            <>
              {labels.loanNumber}
              <HelpIcon text={helpText.loanNumber} />
            </>
          ),
          id: DrawdownRequestSortableFieldEnum.Obligor,
          cell: ({ row }) => (
            <Ellipsis>{`${row.original.obligor}-${row.original.obligation}`}</Ellipsis>
          ),
        })
      );
    }
    if (drawdownRequestList.showFields.drawAmount) {
      headerCellsFromConfig.push(
        columnHelper.accessor('drawAmount', {
          header: labels.drawAmount,
          id: DrawdownRequestSortableFieldEnum.DrawAmount,
          cell: ({ getValue }) => <>{printMoney(getValue())}</>,
        })
      );
    }
    if (drawdownRequestList.showFields.actions.downloadPdf) {
      headerCellsFromConfig.push(
        columnHelper.accessor('id', {
          header: labels.actions,
          cell: ({ row }) => (
            <DrawdownRequestActions
              drawdownRequest={row.original}
              downloadPdf={download}
            />
          ),
          enableSorting: false,
        })
      );
    }
    return headerCellsFromConfig;
  }, [drawdownRequestList, columnHelper, labels, helpText.loanNumber, download]);

  const { items, error, isLoading, isUpdating, total } =
    useDrawdownRequestQuery(queryParams);

  React.useEffect(() => {
    handlePageChange({ pageSize: DEFAULT_PAGE_SIZE, pageIndex: 0 });
  }, [handlePageChange, searchValue]);

  const sortingMemo = React.useMemo(
    () => ({
      onChange: (sortState: SortingState) => {
        // for Loan Number column sort by both obligor and obligation
        const updatedSortState: SortingState = sortState.flatMap((sort) =>
          sort.id === 'obligor'
            ? [
                { id: 'obligor', desc: sort.desc },
                { id: 'obligation', desc: sort.desc },
              ]
            : sort
        );
        setUpdatedSorting(updatedSortState);
        handleSortChange(sortState);
      },
      state: sorting,
    }),
    [sorting, handleSortChange]
  );

  const paginationMemo = React.useMemo(
    () => ({
      onChange: handlePageChange,
      state: pagination,
      total,
    }),
    [handlePageChange, pagination, total]
  );

  return (
    <>
      <QuerySuspense
        error={error}
        isLoading={isLoading}
        noData={Boolean(!items?.length && !isLoading && !isUpdating)}
        noDataMessage={searchValue ? t('common.searchBox.noRecords') : undefined}
        loadingRenderer={() => <TableSkeleton />}
      >
        {searchValue && total > QUERY_LIMIT && (
          <div className={clsx('warning', 'mt-4')}>
            {t('common.searchBox.manyRecords')}
          </div>
        )}
        {items && (
          <LoadingOver show={isUpdating}>
            <ReactTable<DrawdownRequestSearchResultItem>
              data={items}
              columns={columns}
              data-testid="drawdown-request-table"
              stickyFromTopPx={90}
              sorting={sortingMemo}
              pagination={paginationMemo}
            />
          </LoadingOver>
        )}
      </QuerySuspense>
      {isLimitReached && (
        <div className={clsx('float-right', 'alert', 'mt-4')}>
          {t('common.pagination.limitRecords')}
        </div>
      )}
    </>
  );
};
