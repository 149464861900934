import React from 'react';

import { LoanStatus } from 'generated/graphql';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import {
  amountAtom,
  availabilityAtom,
  loanAtom,
} from 'modules/DrawdownRequest/models/amount';
import { selCustomerAtom } from 'modules/DrawdownRequest/models/customer';
import { CustomerSelection, CustomerSelector } from 'modules/common';
import { EligibilityBadge } from 'modules/common/EligibilityBadge/EligibilityBadge';
import { INELIGIBLE_STATUSES } from 'modules/common/constants/status';

interface CustomerSelectorStepProps {
  className?: string;
}

const getEligibitity = (customer: CustomerSelection) => {
  return (
    !!customer.serviceStatus &&
    ![...INELIGIBLE_STATUSES, LoanStatus.Prospect].includes(customer.serviceStatus)
  );
};

export const CustomerSelectorStep: React.FC<CustomerSelectorStepProps> = ({
  className,
}) => {
  const [selCustomer, setSelCustomer] = useAtom(selCustomerAtom);
  const setAmount = useSetAtom(amountAtom);
  const setAvailability = useSetAtom(availabilityAtom);
  const setLoan = useSetAtom(loanAtom);
  const { t } = useTranslation();

  const handleSelect = React.useCallback(
    (customer: CustomerSelection) => {
      setSelCustomer(customer);
      setAmount(0);
      setAvailability(0);
      setLoan(null);
    },
    [setAmount, setAvailability, setLoan, setSelCustomer]
  );

  return (
    <CustomerSelector
      className={className}
      defaultId={selCustomer?.id}
      onSelect={handleSelect}
      getEligibitity={getEligibitity}
      renderBadge={(eligible) => (
        <EligibilityBadge
          eligible={eligible}
          yes={t('customer.eligible.drawdownRequest.yes')}
          no={t('customer.eligible.drawdownRequest.no')}
        />
      )}
    />
  );
};
