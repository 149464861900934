import { Button, Notification } from 'components';
import { LoanProposalQuery } from 'generated/graphql';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LoanProposalFormValues } from '../types';

export enum ConfirmationValues {
  Yes = 'yes',
  No = 'no',
}

export interface ConfirmationControllerProps {
  name: keyof LoanProposalFormValues;
  control: Control<LoanProposalFormValues, any>;
  config: LoanProposalQuery['applicationConfig']['loanProposal']['confirmation'];
  hidden?: boolean;
  'data-testid'?: string;
}

export const ConfirmationController: React.FC<ConfirmationControllerProps> = ({
  name,
  control,
  config,
  hidden,
  'data-testid': dataTestId,
}) => {
  const { t } = useTranslation();

  if (hidden) {
    return null;
  }
  return (
    <>
      <Controller
        render={({ field: { onChange, value } }) => {
          if (value === ConfirmationValues.Yes) {
            return <></>;
          } else if (value === ConfirmationValues.No) {
            return (
              <Notification type="info" className="ml-0 text-left" data-testid={`${dataTestId}-no`}>
                <>{config?.cancelMessage}</>
              </Notification>
            );
          }
          return (
            <Notification type="info" className="ml-0 text-left" data-testid={dataTestId}>
              <div className="flex flex-col gap-4">
                {config?.message}
                <div className="flex justify-end gap-3">
                  <Button
                    state="secondary"
                    onClick={() => {
                      onChange(ConfirmationValues.No);
                    }}
                  >
                    {t('common.no')}
                  </Button>
                  <Button
                    state="primary"
                    onClick={() => {
                      onChange(ConfirmationValues.Yes);
                    }}
                  >
                    {t('common.yes')}
                  </Button>
                </div>
              </div>
            </Notification>
          );
        }}
        name={name}
        control={control}
      />
    </>
  );
};
