import React from 'react';

import { Badge, HelpIcon } from 'components';
import { LoanStatus } from 'generated/graphql';

import { useCustomisation } from 'modules/root/Settings';

import { STATUS_BACKGROUND } from '../enums/statusBackground';

const STATUS_MAP: Record<LoanStatus, STATUS_BACKGROUND> = {
  [LoanStatus.Active]: STATUS_BACKGROUND.GREEN,
  [LoanStatus.TopUp]: STATUS_BACKGROUND.YELLOW,
  [LoanStatus.DataIssue]: STATUS_BACKGROUND.RED,
  [LoanStatus.OverAdvanced]: STATUS_BACKGROUND.GRAY,
  [LoanStatus.SellOut]: STATUS_BACKGROUND.RED_LIGHT,
  [LoanStatus.Prospect]: STATUS_BACKGROUND.PRIMARY,
};

interface StatusBadgeProps {
  status: LoanStatus;
  label?: string;
  size?: 'sm' | 'md';
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({
  status,
  label,
  size = 'md',
}) => {
  const { helpText } = useCustomisation();
  const color = STATUS_MAP[status];
  const helpTextMap = {
    [LoanStatus.TopUp]: helpText?.serviceStatus?.topUp,
    [LoanStatus.DataIssue]: helpText?.serviceStatus?.dataIssue,
    [LoanStatus.OverAdvanced]: helpText?.serviceStatus?.overAdvanced,
    [LoanStatus.SellOut]: helpText?.serviceStatus?.sellOut,
    [LoanStatus.Active]: null,
    [LoanStatus.Prospect]: null,
  };
  const text = helpTextMap[status];
  const badge = (
    <Badge size={size} label={label ? label : status.replace('_', ' ')} color={color} />
  );

  return text ? (
    <div className="flex gap-1 items-center">
      {badge}
      <HelpIcon text={text} />
    </div>
  ) : (
    badge
  );
};
