import React from 'react';
import { generatePath } from 'react-router-dom';

import clsx from 'clsx';
import { ButtonLink, Content, Ellipsis, Loading } from 'components';
import { useDatafeedQuery } from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import { ResourceAccessed, useSendAuditReport } from 'services/auditReport';

import { NewEvaluationButton } from 'modules/common';
import { useCustomisation, usePageTitle, usePaths } from 'modules/root/Settings';
import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';
import { useAuth } from 'modules/root/auth/auth.context';
import { DATE_FORMAT_MONTH_NAME_TIME, formatDate } from 'utils/date';

import { ShrinkHeader } from '../../components/PageLayout/Content/ShrinkHeader/ShrinkHeader';
import { DashboardSection } from './Dashboard/DashboardSection';
import { DashboardCardKeys, cardsMap, sectionViewMap } from './Dashboard/cardsMap';

export const Home: React.FC = () => {
  const { profile } = useAuth();
  const paths = usePaths();
  const {
    permissions: {
      canCreateModifyRunEvaluations,
      canCreateReleaseRequest,
      canCreateDrawdownRequest,
    },
  } = useAccess();
  const { t } = useTranslation();
  const username = profile ? profile.name || profile.username : '';
  const { data, isLoading } = useQueryFetch(useDatafeedQuery);
  const dateTimeStr = data?.datafeed
    ? formatDate(data.datafeed.updated, DATE_FORMAT_MONTH_NAME_TIME)
    : null;

  const {
    dashboard: { sections },
    labels,
  } = useCustomisation();
  usePageTitle(labels.home);
  useSendAuditReport({
    audit: { resourceAccessed: ResourceAccessed.LandOnHomePage },
    sendOnMount: true,
  });

  return (
    <Content
      shrinkHeader={
        <ShrinkHeader
          title={
            <div className="flex flex-col group-[.is-shrinked]:flex-row lg:flex-row">
              <div className="mr-2 lg:group-[.is-shrinked]:block">{`${t(
                'home.welcome'
              )},`}</div>
              <div className="whitespace-nowrap overflow-hidden" data-testid="username">
                <Ellipsis>{username}</Ellipsis>
              </div>
            </div>
          }
          subTitle={
            isLoading ? (
              <Loading />
            ) : (
              <div
                data-testid="updated"
                className={clsx('detail', 'transition-all', 'duration-500')}
              >{`${t('home.updated')}: ${dateTimeStr}`}</div>
            )
          }
          buttons={
            <>
              {/* <button data-testid="configure-dashboard-btn">
            <DashboardIcon />
          </button> */}
              {canCreateModifyRunEvaluations && (
                <NewEvaluationButton data-testid="new-evaluation-btn" />
              )}
              {canCreateReleaseRequest && (
                <ButtonLink
                  url={generatePath(paths.collateralRelease.new)}
                  size="md"
                  state="secondary"
                >
                  {t('releaseRequest.collateralRelease')}
                </ButtonLink>
              )}
              {canCreateDrawdownRequest && (
                <ButtonLink
                  state="secondary"
                  className="mr-4"
                  size="md"
                  data-testid="drawdown-request-button"
                  url={generatePath(paths.drawdown.new)}
                >
                  {t('drawdownRequest.title')}
                </ButtonLink>
              )}
            </>
          }
        />
      }
    >
      <div className="flex flex-wrap py-10 px-6 xl:px-20 gap-4">
        {sections.map((section) =>
          section.show ? (
            <DashboardSection
              key={section.id}
              label={section.label}
              view={sectionViewMap[section.id]}
            >
              {Object.entries(section.cards).map(([key, config]) => {
                const card = cardsMap[key as DashboardCardKeys]({
                  key,
                  config: config,
                });
                return card;
              })}
            </DashboardSection>
          ) : null
        )}
      </div>
    </Content>
  );
};
