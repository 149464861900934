import React from 'react';

import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { ActionMenu, FontIcon, notification } from 'components';
import {
  ActivityAuditType,
  DrawdownRequestSearchResultItem,
  useAuditReportMutation,
} from 'generated/graphql';
import { graphqlClient, useAuthClient } from 'queries/apiFetch/graphqlClient';
import { useTranslation } from 'react-i18next';
import { ResourceAccessed } from 'services/auditReport';
import { DATE_FORMAT_MONTH_DASH, formatDate } from 'utils';

import { useCustomisation } from 'modules/root/Settings';
import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';

interface DrawdownRequestActionsProps {
  drawdownRequest: DrawdownRequestSearchResultItem;
  downloadPdf: (urlPath: string, filename: string) => Promise<void>;
}

export const DrawdownRequestActions: React.FC<DrawdownRequestActionsProps> = ({
  drawdownRequest,
  downloadPdf,
}) => {
  const {
    labels,
    drawdownRequestList: {
      showFields: { actions: actionsConfig },
    },
  } = useCustomisation();
  const authClient = useAuthClient(graphqlClient);
  const { mutateAsync } = useAuditReportMutation(authClient);
  const { t } = useTranslation();
  const {
    permissions: { canAccessDrawdownRequestPdf },
  } = useAccess();

  const drawdownRequestActions = React.useMemo(() => {
    const handleDownloadPdf = (drawdownRequest: DrawdownRequestSearchResultItem) => {
      let date = new Date();
      const offset = date.getTimezoneOffset();
      const fileName = `DrawdownRequest_${drawdownRequest.externalId}_${formatDate(
        date.toISOString(),
        DATE_FORMAT_MONTH_DASH
      )}`;
      const urlPath = `/schema/drawdown-request/${
        drawdownRequest.externalId
      }/pdf?timeZoneOffset=${-offset}`;
      const commonAuditData = {
        activityType: ActivityAuditType.Read,
        resourceAccessed: ResourceAccessed.DownloadDrawdownRequestDocs,
        customerExternalId: drawdownRequest.customerExternalId,
      };
      downloadPdf(urlPath, fileName)
        .then(() => {
          mutateAsync({
            ...commonAuditData,
            queryDetails: JSON.stringify({ externalId: drawdownRequest.externalId }),
          });
        })
        .catch((error) => {
          mutateAsync({
            ...commonAuditData,
            queryDetails: JSON.stringify({
              externalId: drawdownRequest.externalId,
              error: true,
            }),
          });
          notification.error(error?.message || t('common.error.unspecific'));
        });
    };
    const actions = [];
    if (actionsConfig.downloadPdf && canAccessDrawdownRequestPdf) {
      actions.push({
        action: (row: DrawdownRequestSearchResultItem) => handleDownloadPdf(row),
        text: labels.downloadPdf,
        icon: <FontIcon icon={faDownload} />,
      });
    }
    return actions;
  }, [
    actionsConfig.downloadPdf,
    canAccessDrawdownRequestPdf,
    downloadPdf,
    mutateAsync,
    t,
    labels.downloadPdf,
  ]);

  return (
    <ActionMenu
      position="center"
      items={drawdownRequestActions}
      data={drawdownRequest}
      data-testid="drawdown-request-action-menu"
    />
  );
};
