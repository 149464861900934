import { atom } from 'jotai';

export enum StepIds {
  Customer = 'CUSTOMER',
  Amount = 'AMOUNT',
  Results = 'RESULTS',
  Details = 'DETAILS',
}

export type StepAtom = {
  id: StepIds;
  label: string;
  active: boolean;
  enable: boolean;
  valid: boolean;
};

export const stepsAtom = atom<StepAtom[]>([]);

export const currentStepAtom = atom(
  (get) => get(stepsAtom).find((step) => step.active),
  (get, set, newStep: StepAtom) => {
    const steps = get(stepsAtom);
    if (!newStep.enable) {
      return;
    }

    const newSteps: StepAtom[] = steps.map((step) => ({
      ...step,
      active: step.id === newStep.id,
    }));
    set(stepsAtom, newSteps);
  }
);

export const nextStepAtom = atom<StepAtom | null, unknown[], void>(
  (get) => {
    const steps = get(stepsAtom);
    const currentStep = get(currentStepAtom);
    const currentStepIndex = steps.findIndex((step) => step.id === currentStep?.id);
    if (currentStepIndex !== -1) {
      const nextStep = steps[currentStepIndex + 1] || null;
      return nextStep;
    }
    return null;
  },
  (get, set) => {
    const nextStep = get(nextStepAtom);
    if (nextStep) {
      set(currentStepAtom, nextStep);
    } else {
      const steps = get(stepsAtom);
      set(
        stepsAtom,
        steps.map((step) => ({ ...step, active: false }))
      );
    }
  }
);
