import React from 'react';

import clsx from 'clsx';

interface BaseProps {
  children: React.ReactElement;
}

interface CommonHeaderProps extends BaseProps {
  header: React.ReactElement;
  shrinkHeader?: undefined;
}

interface ShrinkHeaderProps extends BaseProps {
  shrinkHeader: React.ReactElement;
  header?: undefined;
}

interface NoHeader extends BaseProps {
  header?: undefined;
  shrinkHeader?: undefined;
}

type ContentProps = CommonHeaderProps | ShrinkHeaderProps | NoHeader;

const ContentTop: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div
      className={clsx(
        'min-h-[theme(spacing.52)]',
        'bg-main-primary',
        'px-8 lg:px-20',
        'pt-10',
        'text-white'
      )}
    >
      {children}
    </div>
  );
};

export const Content: React.FC<ContentProps> = ({ children, header, shrinkHeader }) => {
  return (
    <>
      <div className="flex flex-col">
        {header && <ContentTop>{header}</ContentTop>}
        {shrinkHeader}
        <div role="main" id="main-content" className="h-full">
          {children}
        </div>
      </div>
    </>
  );
};
