import Highcharts, { SeriesColumnOptions } from 'highcharts';

import { palette } from 'modules/root/theme/visualization';
import { printMoney, printMoneyInLetters } from 'utils/print';

import { ChartSeriesData } from './EvaluationGraph';

export const evaluationGraphOptions = (
  data: ChartSeriesData[],
  categoriesArr: string[],
  fontFamily: string
): Highcharts.Options => ({
  accessibility: { enabled: false },
  title: {
    text: '',
  },
  xAxis: {
    categories: categoriesArr,
    labels: {
      style: {
        fontSize: '1.1rem',
        color: palette.text,
        width: 150,
      },
    },
  },
  yAxis: {
    lineWidth: 1,
    gridLineWidth: 1.4,
    tickAmount: 7,
    gridLineDashStyle: 'Dash',
    minorGridLineWidth: 0,
    min: 0,
    title: {
      text: '',
    },
    labels: {
      formatter(): string {
        return printMoneyInLetters(Number(this.value));
      },
      style: {
        fontSize: '1rem',
      },
    },
  },
  tooltip: {
    formatter(): string | false {
      if (this.series.type === 'spline') {
        return false;
      }
      return `<span style="font-size: 1rem; color: ${palette.text}">${
        this.series.name
      }: ${printMoney(
        // @ts-ignore realY property is added in EvaluationGraphtsx
        this.point.realY
      )}</span>`;
    },
    backgroundColor: palette.white,
    borderColor: palette.gray200,
    borderRadius: 6,
  },
  credits: {
    enabled: false,
  },

  plotOptions: {
    column: {
      stacking: 'normal',
      showInLegend: false,
      pointWidth: 60,
      events: {
        legendItemClick(): boolean {
          return false;
        },
      },
    },
    spline: {
      connectNulls: false,
      showInLegend: false,
    },
  },
  series: data as SeriesColumnOptions[],
  chart: {
    width: 550,
    height: 400,
    style: {
      fontFamily,
    },
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
});
