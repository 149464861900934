import React from 'react';

import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { accountsAtom, selAccountIdsAtom } from 'modules/EvaluationV2/models/account';
import { selCustomerAtom } from 'modules/EvaluationV2/models/customer';
import { CustomerSelection, CustomerSelector } from 'modules/common';
import { EligibilityBadge } from 'modules/common/EligibilityBadge/EligibilityBadge';

interface CustomerSelectorStepProps {
  className?: string;
}

export const CustomerSelectorStep: React.FC<CustomerSelectorStepProps> = ({
  className,
}) => {
  const [selCustomer, setSelCustomer] = useAtom(selCustomerAtom);
  const setSelAccounts = useSetAtom(selAccountIdsAtom);
  const setAccounts = useSetAtom(accountsAtom);
  const { t } = useTranslation();

  const handleSelect = React.useCallback(
    (customer: CustomerSelection) => {
      setSelCustomer(customer);
      setAccounts(null);
      setSelAccounts(new Set());
    },
    [setSelCustomer, setAccounts, setSelAccounts]
  );

  return (
    <CustomerSelector
      className={className}
      defaultId={selCustomer?.id}
      onSelect={handleSelect}
      renderBadge={(eligible) => (
        <EligibilityBadge
          eligible={eligible}
          yes={t('customer.eligible.sbl.yes')}
          no={t('customer.eligible.sbl.no')}
        />
      )}
    />
  );
};
