import React from 'react';

import clsx from 'clsx';
import { Ellipsis, HelpIcon } from 'components';
import { useAtomValue } from 'jotai';
import { printMoney, printPercent, printPrimitive } from 'utils';

import { evaluationAtom } from 'modules/EvaluationV2/models/evaluation';
import { useCustomisation } from 'modules/root/Settings';

import { getAssetColor } from './EvaluationGraph/evaluationGraphColorMap';

interface AssetsProps {
  className?: string;
}

const Header: React.FC<AssetsProps> = () => {
  const { labels, helpText } = useCustomisation();
  return (
    <div className="flex h-14 items-center font-semibold uppercase px-4">
      <div className="flex flex-nowrap items-center justify-start text-right gap-1 text-xs w-32">
        {labels.assetType}
        <HelpIcon size="sm" text={helpText.assetType} />
      </div>
      <div className="flex flex-nowrap items-center gap-1 justify-end text-right text-xs w-32">
        {labels.marketValue}
        <HelpIcon size="sm" text={helpText.marketValue} />
      </div>
      <div className="flex flex-nowrap items-center justify-end text-right gap-1 text-xs w-44">
        {labels.collateralValue}
        <HelpIcon size="sm" text={helpText.collateralValue} />
      </div>
      <div className="flex flex-nowrap items-center justify-end text-right gap-1 text-xs w-28">
        {labels.rate} <HelpIcon size="sm" text={helpText.rate} />
      </div>
    </div>
  );
};

const Table: React.FC<AssetsProps> = ({ className }) => {
  const evaluation = useAtomValue(evaluationAtom);
  return (
    <div className={className}>
      <div data-testid="summary-asset-table">
        <Header />
        <div className="border-2 rounded-lg">
          {evaluation?.evaluationAssetSummary?.map((asset, index) => (
            <div
              key={index}
              data-testid="asset-record"
              className={clsx('items-center h-14 flex py-2 px-4', {
                'border-t-2': index !== 0,
              })}
            >
              <div className="flex text-xs font-bold w-32 items-center gap-2">
                <div
                  className="w-4 h-4 rounded-sm mb-0.5"
                  style={{ background: getAssetColor(asset.assetType) }}
                ></div>
                <Ellipsis>{printPrimitive(asset?.assetType)}</Ellipsis>
              </div>
              <div className="text-right w-32">{printMoney(asset?.marketValue)}</div>
              <div className="text-right w-44">{printMoney(asset?.collateralValue)}</div>
              <div className="text-right w-32">{printPercent(asset?.advanceRate)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const Assets = {
  Header,
  Table,
};
