import React from 'react';
import { generatePath } from 'react-router';

import { Button, ButtonLink } from 'components';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { usePaths } from 'modules/root/Settings';
import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';

import { DownloadPDF } from '../Calculation/Result/sections/DownloadPDF';
import { isCustomerEligibleAtom } from '../models/customer';
import { hasSelectedAssetsChangedAtom, totalMarketValueAtom } from '../models/input';
import { releaseRequestStatusAtom } from '../models/status';
import { StepIds, currentStepAtom, nextStepAtom } from '../models/step';
import { TotalMarketValue } from './TotalMarketValue';

interface ControlPanelProps {}

export const ControlPanel: React.FC<ControlPanelProps> = (props) => {
  const { t } = useTranslation();
  const currentStep = useAtomValue(currentStepAtom);
  const [nextStep, setNextStep] = useAtom(nextStepAtom);
  const [status, setStatus] = useAtom(releaseRequestStatusAtom);
  const isCustomerEligible = useAtomValue(isCustomerEligibleAtom);
  const totalMV = useAtomValue(totalMarketValueAtom);
  const [hasSelectedAssetsChanged, setHasSelectedAssetsChanged] = useAtom(
    hasSelectedAssetsChangedAtom
  );
  const paths = usePaths();
  const { permissions } = useAccess();

  if (currentStep) {
    return (
      <div className="w-[625px] h-full mx-auto flex flex-row justify-end items-center">
        {currentStep.id === StepIds.Request && <TotalMarketValue />}
        {nextStep ? (
          <Button
            disabled={!nextStep?.enable || !currentStep?.valid}
            onClick={setNextStep}
          >
            {t('common.continue')}
          </Button>
        ) : (
          <Button
            onClick={() => {
              setNextStep();
              setStatus('progress', 'non-persist');
            }}
            data-testid="calculate-btn"
            disabled={!isCustomerEligible || !currentStep?.valid}
          >
            {t('releaseRequest.buttons.calculate')}
          </Button>
        )}
      </div>
    );
  }
  return (
    <div className="h-full flex max-w-[calc(100vw-103px)] 2xl:max-w-[1356px]">
      <div className="h-full w-[50%] max-w-[50%] border-r border-r-gray-200 flex items-center px-10 gap-4">
        <div className="grow">
          <TotalMarketValue />
        </div>
        <Button
          className="whitespace-nowrap"
          data-testid="calculate-btn"
          disabled={totalMV === 0 || (status === 'saved' && !hasSelectedAssetsChanged)}
          onClick={() => {
            setStatus('progress', 'non-persist');
          }}
        >
          {t('releaseRequest.buttons.calculate')}
        </Button>
      </div>
      <div className="h-full w-[50%] max-w-[50%] flex items-center px-10 gap-4">
        <div className="overflow-hidden ">
          {permissions.canAccessReleaseRequestPdf && <DownloadPDF />}
        </div>
        <div className="grow"></div>
        <ButtonLink
          state="secondary"
          url={generatePath(paths.servicing.collateralRelease)}
          data-testid="release-request-cancel-btn"
          disabled={status === 'saved'}
        >
          {t('common.cancel')}
        </ButtonLink>
        <Button
          className="whitespace-nowrap"
          data-testid="release-request-save-btn"
          disabled={status !== 'success'}
          onClick={() => {
            setStatus('progress', 'persist');
            setHasSelectedAssetsChanged(false);
          }}
        >
          {t('releaseRequest.buttons.submit')}
        </Button>
      </div>
    </div>
  );
};
