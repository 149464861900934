import {
  ActivityAuditType,
  CustomersSearchQuery,
  useCustomersSearchQuery,
} from 'generated/graphql';
import { useAtom, useSetAtom } from 'jotai';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';

import { canShowMoreCustomersAtom, customerSearchAtom, customersAtom } from './model';

export const useCustomers = (
  customerPagesAtom = customersAtom,
  searchAtom = customerSearchAtom,
  showMoreAtom = canShowMoreCustomersAtom,
  queryWhenSearchTextIsEmpty = true
) => {
  const [customers, setCustomers] = useAtom(customerPagesAtom);
  const [customerSearch] = useAtom(searchAtom);
  const setCanShowMoreCustomers = useSetAtom(showMoreAtom);

  const { isLoading,error } = useQueryFetch(useCustomersSearchQuery, {
    queryHookOptions: {
      enabled: queryWhenSearchTextIsEmpty
        ? !!customerSearch
        : !!customerSearch?.searchText,
      onSuccess(data) {
        const result = (data as CustomersSearchQuery).customersSearch;
        const fromIndex = customerSearch?.fromIndex ?? 0;
        const newCustomersPage = { items: result.items, fromIndex };
        if (fromIndex === 0) {
          // first page
          setCustomers([newCustomersPage]);
        } else {
          const pageIndex = customers.findIndex((page) => page.fromIndex === fromIndex);
          if (pageIndex !== -1) {
            // existing page
            const newCustomers = [...customers];
            newCustomers[pageIndex] = newCustomersPage;
            setCustomers(newCustomers);
          } else {
            // new page
            setCustomers(customers.concat(newCustomersPage));
          }
        }

        const numResults = result.numResults ?? 0;
        const totalHits = result.totalHits;
        setCanShowMoreCustomers(fromIndex + numResults < totalHits);
      },
    },
    queryHookParams: customerSearch ? customerSearch : undefined,
    extra: {
      auditReport: { activityType: ActivityAuditType.Read },
    },
  });
  return { isLoading, customers, error };
};
