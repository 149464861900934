import React from 'react';

import { faCheckCircle, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontIcon } from 'components';
import { Trans, useTranslation } from 'react-i18next';
import { printMoney } from 'utils';

interface RecommendationProps {
  collateralRelease: number;
  isApproved: boolean;
}

export const Recommendation: React.FC<RecommendationProps> = ({
  collateralRelease,
  isApproved,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 items-center">
      <div className="text-green-600">
        {isApproved ? (
          <FontIcon className="text-green-600" size="2x" icon={faCheckCircle} />
        ) : (
          <FontIcon className="text-red-600" size="2x" icon={faCircleXmark} />
        )}
      </div>
      <h3 className="flex gap-2">
        <span>{t('releaseRequest.recommendation')}</span>
        <span>{'-'}</span>
        {isApproved ? (
          <span className="text-green-600">{t('releaseRequest.approved')}</span>
        ) : (
          <span className="text-red-600">{t('releaseRequest.declined')}</span>
        )}
      </h3>
      <div className="text-base">
        <Trans
          i18nKey={
            isApproved ? 'releaseRequest.approvedMsg' : 'releaseRequest.declinedMsg'
          }
          values={{
            collateralRelease: printMoney(collateralRelease),
          }}
          components={{ bold: <span className="font-semibold tracking-wider" /> }}
        />
      </div>
    </div>
  );
};
