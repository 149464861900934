import React from 'react';

import { useAtomValue } from 'jotai';

import { StepIds, currentStepAtom } from '../models/step';
import { LeaveBeforeSaving } from './LeaveBeforeSaving';
import { AmountStep } from './steps/AmountStep/AmountStep';
import { CustomerSelectorStep } from './steps/CustomerSelectorStep/CustomerSelectorStep';
import { DetailsStep } from './steps/DetailsStep/DetailsStep';
import { ResultStep } from './steps/ResultStep/ResultStep';

interface DrawdownRequestStepProps {}

export const DrawdownRequestStep: React.FC<DrawdownRequestStepProps> = (props) => {
  const step = useAtomValue(currentStepAtom);

  return (
    <div className="flex flex-col items-center w-full justify-between h-full mt-5">
      {step?.id === StepIds.Customer && <CustomerSelectorStep className="mb-14" />}
      {step?.id === StepIds.Amount && <AmountStep />}
      {step?.id === StepIds.Results && <ResultStep />}
      {step?.id === StepIds.Details && (
        <>
          <DetailsStep />
          <LeaveBeforeSaving />
        </>
      )}
    </div>
  );
};
