import { Dropdown, DropdownProps, HelpIcon, DropdownOption } from 'components';
import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';

import { LoanProposalFormValues } from '../types';

type LoanProposalFormDropdown = Pick<
  LoanProposalFormValues,
  | 'loanPurpose'
  | 'primaryLoanPurpose'
  | 'loanType'
  | 'loanTerm'
  | 'interestRateType'
  | 'proposedPricing'
  | 'borrowerType'
  | 'exceptionRequestDropdown'
  | 'productType'
>;
export interface DropdownControllerProps
  extends Pick<DropdownProps, 'label' | 'data-testid'> {
  name: keyof LoanProposalFormDropdown;
  options: DropdownOption[];
  showOther?: boolean;
  control: Control<LoanProposalFormValues, any>;
  hidden?: boolean;
  helpText?: string | null | undefined;
}
export const DropdownController: React.FC<DropdownControllerProps> = ({
  name,
  control,
  showOther = false,
  options,
  hidden,
  helpText,
  ...props
}) => {
  const { t } = useTranslation();
  if (hidden) {
    return null;
  }
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <div className="flex">
            <Dropdown
              {...props}
              options={showOther ? [...options, t('common.other')] : options}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              errorMessage={error?.message}
            />
            <div className="w-5 mb-4 pl-1 flex items-center">
              {helpText && <HelpIcon text={helpText} />}
            </div>
          </div>
        )}
      />
    </>
  );
};
