import { Content } from 'components';
import { useAtomValue } from 'jotai';

import { Evaluation } from './Evaluation/Evaluation';
import { EvaluationBreadcrumb } from './EvaluationBreadcrumb/EvaluationBreadcrumb';
import { EvaluationSettings } from './EvaluationSettings/EvaluationSettings';
import { ControlPanel } from './EvaluationStep/ControlPanel';
import { EvaluationStep } from './EvaluationStep/EvaluationStep';
import { EvaluationStepper } from './EvaluationStep/EvaluationStepper';
import { EvaluationAction, evaluationActionAtom } from './models/evaluation';
import { currentStepAtom } from './models/step';

interface EvaluationProps {}

export const EvaluationLayout: React.FC<EvaluationProps> = (props) => {
  const currentStep = useAtomValue(currentStepAtom);
  const evalAction = useAtomValue(evaluationActionAtom);

  return (
    <div className="grid grid-rows-[min-content_1fr] h-full relative ">
      <EvaluationSettings />
      {evalAction !== EvaluationAction.None && (
        <>
          <div className="sticky top-0 bg-white/70 backdrop-blur-sm flex flex-col w-full z-10">
            <EvaluationBreadcrumb />
          </div>
          <Content>
            {currentStep ? (
              <>
                <div className="sticky top-12 bg-white/70 backdrop-blur-sm flex flex-col w-full z-10">
                  <EvaluationStepper />
                </div>
                <div className="flex flex-col w-[556px] mx-auto">
                  <EvaluationStep />
                </div>
              </>
            ) : (
              <div className="flex flex-col h-full max-w-[calc(100vw-103px)] 2xl:max-w-[1356px]">
                <Evaluation />
              </div>
            )}
          </Content>
          <div className="sticky bottom-0 bg-white/70 backdrop-blur-sm w-full h-[72px] border-t border-t-gray-200">
            <ControlPanel />
          </div>
        </>
      )}
    </div>
  );
};

// The base principles that I followed here

// 1) Break down components into smaller components
//    so that there are not a lot of dependencies (in our case used atoms) in one component
//    if there are multiple atoms and it's possible to isolate ones in the separate component,
//    then it's worth taking that element and its atoms out.

// 2) All the basic logic lies in atoms and is passed through them.
//    I.e. we don't use props here, we use dependencies on atoms as much as possible.
