import React from 'react';

import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import {
  Ellipsis,
  SelectableStateList,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextInput,
  TextInputOnBlur,
} from 'components';
import { HoldingType } from 'generated/graphql';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { filterDecimal, formatDecimal, formatDecimalWithCommas } from 'utils';

import { Checkbox, CheckboxOnChange } from 'components/Checkbox/Checkbox';
import {
  ReleaseRequestAccount,
  accountsAtom,
} from 'modules/ReleaseRequest/models/account';
import {
  SelectedAsset,
  assetIndexerAtom,
  selectedAssetsAtom,
} from 'modules/ReleaseRequest/models/input';
import { useCustomisation } from 'modules/root/Settings';
import { printMoney } from 'utils/print';

interface AssetTableProps {
  assets: NonNullable<ReleaseRequestAccount>['assetHoldings'];
  inactive?: boolean;
  onChange: CheckboxOnChange;
}

export const AssetTable: React.FC<AssetTableProps> = ({
  assets,
  inactive,
  onChange: handleChange,
}) => {
  const { t } = useTranslation();
  const { labels } = useCustomisation();
  const [selectedAssets, setSelectedAssets] = useAtom(selectedAssetsAtom);
  const [stateType, setStateType] = React.useState(HoldingType.Quantity);
  const accounts = useAtomValue(accountsAtom);
  const assetIndexer = useAtomValue(assetIndexerAtom);

  const handleChangeUnits = React.useCallback((index: number) => {
    if (index === 0) {
      setStateType(HoldingType.Quantity);
    } else {
      setStateType(HoldingType.Mv);
    }
  }, []);

  const updateAssets = React.useCallback(
    (assetId: string, units: number, amount: number, type: HoldingType) => {
      const { accountIndex, assetIndex } = assetIndexer[assetId];
      const accountId = accounts?.[accountIndex]?.collateralAccountId || '';
      const asset = accounts?.[accountIndex]?.assetHoldings?.[assetIndex];
      if (units > (asset?.quantity || 0)) {
        units = asset?.quantity || 0;
        amount = asset?.marketValue || 0;
      }
      return {
        [assetId]: {
          accountId,
          unit: units,
          amount: amount,
          releaseAll: false,
          type: type,
          securityId: asset?.securityId || '',
        },
      };
    },
    [accounts, assetIndexer]
  );

  const handleUnitChange: TextInputOnBlur = React.useCallback(
    (event) => {
      const element = event.target;
      setSelectedAssets((assets: SelectedAsset) => {
        const assetId = element.name;
        const { accountIndex, assetIndex } = assetIndexer[assetId];
        const asset = accounts?.[accountIndex]?.assetHoldings?.[assetIndex];
        let units = parseFloat(element.value);
        if (units) {
          const assetUpdate = updateAssets(
            assetId,
            units,
            units * (asset?.assetPrice || 0),
            HoldingType.Quantity
          );
          return { ...assets, ...assetUpdate };
        }
        delete assets[assetId];
        return { ...assets };
      });
    },
    [accounts, assetIndexer, setSelectedAssets, updateAssets]
  );

  const handleAmountChange: TextInputOnBlur = React.useCallback(
    (event) => {
      const element = event.target;
      setSelectedAssets((assets: SelectedAsset) => {
        let amount = parseFloat(element.value);
        const assetId = element.name;
        const { accountIndex, assetIndex } = assetIndexer[assetId];
        const asset = accounts?.[accountIndex]?.assetHoldings?.[assetIndex];
        if (amount && asset?.assetPrice) {
          const assetUpdate = updateAssets(
            assetId,
            amount / asset?.assetPrice,
            amount,
            HoldingType.Mv
          );
          return { ...assets, ...assetUpdate };
        }
        delete assets[assetId];
        return { ...assets };
      });
    },
    [accounts, assetIndexer, setSelectedAssets, updateAssets]
  );

  return (
    <Table>
      <TableHead>
        <TableRow className="table w-full table-fixed ">
          <TableCell className={clsx('text-sm', { 'w-36': !inactive, 'w-72': inactive })}>
            {labels.securityId}
          </TableCell>
          <TableCell className="text-sm w-24 text-center">{labels.units}</TableCell>
          <TableCell className="text-sm group-first:px-0 text-center">
            {labels.marketValue}
          </TableCell>
          {!inactive ? (
            <TableCell className="text-sm group-first:px-0 flex justify-center">
              <SelectableStateList
                states={[
                  t('releaseRequest.units.unit'),
                  t('releaseRequest.units.amount'),
                ]}
                onChange={handleChangeUnits}
              />
            </TableCell>
          ) : null}
          {!inactive ? <TableCell className="w-10"></TableCell> : null}
        </TableRow>
      </TableHead>
      <TableBody className="max-h-[400px] block overflow-auto">
        {assets?.map((asset, index) => {
          return (
            <TableRow
              key={asset?.id}
              className={clsx('table w-full table-fixed', 'border-t border-gray-200')}
            >
              <TableCell
                className={clsx('text-sm', { 'w-36': !inactive, 'w-72': inactive })}
              >
                <Ellipsis>{asset?.securityId}</Ellipsis>
                <Ellipsis className="text-gray-400 capitalize">
                  {asset?.assetName}
                </Ellipsis>
              </TableCell>
              <TableCell className="text-sm w-24 text-right">
                <Ellipsis>{asset?.quantity.toFixed(2)}</Ellipsis>
              </TableCell>
              <TableCell className="text-sm text-right">
                <Ellipsis>{printMoney(asset?.marketValue)}</Ellipsis>
              </TableCell>
              {!inactive ? (
                <TableCell>
                  {stateType === HoldingType.Quantity ? (
                    <TextInput
                      name={asset?.id}
                      data-testid="unit"
                      className="my-0"
                      size="sm"
                      filterFn={filterDecimal}
                      formatFn={formatDecimal}
                      disabled={
                        selectedAssets[asset?.id || '']?.releaseAll === true
                      }
                      text={selectedAssets[asset?.id || '']?.unit.toFixed(2)}
                      onBlur={handleUnitChange}
                    />
                  ) : (
                    <TextInput
                      name={asset?.id}
                      data-testid="amount"
                      className="my-0"
                      size="sm"
                      filterFn={filterDecimal}
                      formatFn={formatDecimalWithCommas}
                      disabled={
                        selectedAssets[asset?.id || '']?.releaseAll === true
                      }
                      text={selectedAssets[asset?.id || '']?.amount.toFixed(2)}
                      prefixIcon={faDollarSign}
                      onBlur={handleAmountChange}
                    />
                  )}
                </TableCell>
              ) : null}
              {!inactive ? (
                <TableCell className="w-10 group-last:px-0">
                  <Checkbox
                    checked={
                      asset ? !!selectedAssets[asset.id]?.releaseAll : false
                    }
                    name="asset"
                    value={asset?.id}
                    onChange={handleChange}
                  />
                </TableCell>
              ) : null}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
