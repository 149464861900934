import { LoadingSpinner } from './LoadingSpinner';

interface LoadingOverProps {
  show: boolean;
}
export const LoadingOver: React.FC<React.PropsWithChildren<LoadingOverProps>> = ({
  children,
  show,
}) => {
  return (
    <div className="w-full h-full relative">
      {show && (
        <div className="absolute bg-white/50 w-full h-full flex justify-center items-center animate-pulse z-50">
          <LoadingSpinner size="5x" />
        </div>
      )}
      {children}
    </div>
  );
};
