import React from 'react';

import clsx from 'clsx';
import { HiddenDetails } from 'components';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import {
  EvaluationState,
  evaluationAtom,
  evaluationDetailsAtom,
  evaluationStateAtom,
} from 'modules/EvaluationV2/models/evaluation';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { NotificationAtom, NotificationType } from 'modules/common/models/notify';
import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';

import { Assets } from './sections/Assets';
import { DownloadPDF } from './sections/DownloadPDF';
import { Figures } from './sections/Figures';
import { Graph } from './sections/Graph';
import { LeaveBeforeSaving } from './sections/LeaveBeforeSaving';
import { QueryHandler } from './sections/QueryHandler';
import { SaveEvalutionButton } from './sections/SaveEvaluationButton';
import { useEvaluationQuery } from './useEvaluationQuery';

interface SummaryProps {}

export const Summary: React.FC<SummaryProps> = (props) => {
  const { t } = useTranslation();
  const evaluation = useAtomValue(evaluationAtom);
  const notification = useAtomValue(NotificationAtom);
  const setEvalState = useSetAtom(evaluationStateAtom);
  const evaluationDetails = useAtomValue(evaluationDetailsAtom);
  const { permissions } = useAccess();

  const { error, isFetching } = useEvaluationQuery();

  return (
    <div className="w-full flex flex-col gap-2 p-5 xl:p-10 overflow-auto text-sm xl:text-md">
      <QuerySuspense
        error={error}
        isLoading={isFetching}
        noData={!evaluation}
        errorRenderer={() => (
          <QueryHandler.EvaluationErrorMessage
            rerunEvaluation={() => {
              setEvalState(EvaluationState.Processing);
            }}
          />
        )}
        loadingRenderer={() => <QueryHandler.EvaluationLoading />}
      >
        <div className="flex mb-4 items-center">
          <div className="grow">
            <div className="font-bold text-lg ">
              {t('evaluationV2.evaluationResults')}
            </div>
            {evaluationDetails && evaluationDetails.label && (
              <div className="text-sm text-primary-600">{evaluationDetails.label}</div>
            )}
          </div>

          <div className="flex items-end">
            {permissions.canAccessEvaluationPdf && <DownloadPDF />}
            <SaveEvalutionButton />
          </div>
        </div>
        <div className="mb-4">
          {notification && (
            <div
              data-testid="common-nofication-msg"
              className={clsx({
                alert: notification.type === NotificationType.error,
                notification: notification.type === NotificationType.info,
                warning: notification.type === NotificationType.warning,
              })}
            >
              {notification.message}
            </div>
          )}
        </div>
        <Figures className="mb-14" />
        <Graph />
        <HiddenDetails
          className="mt-6"
          showLine={false}
          showLabel={t('evaluationV2.graph.showDetails')}
          hideLabel={t('evaluationV2.graph.hideDetails')}
        >
          <Assets.Table />
        </HiddenDetails>
        <LeaveBeforeSaving />
      </QuerySuspense>
    </div>
  );
};
