import { ReleaseRequestAccountsQuery } from 'generated/graphql';
import { atom } from 'jotai';
import { ArrayElement } from 'utils';

export type ReleaseRequestAccounts =
  ReleaseRequestAccountsQuery['customer']['collateralAccounts'];

export type ReleaseRequestAccount = ArrayElement<ReleaseRequestAccounts>;

export type AssetHoldings = NonNullable<ReleaseRequestAccount>['assetHoldings'];

export const accountsAtom = atom<ReleaseRequestAccounts | null>(null);
