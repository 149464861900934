import { faXmark } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { Renderable, Toast, ValueOrFunction, toast } from 'react-hot-toast';

import { FontIcon } from 'components/FontIcon/FontIcon';

import { Notification, NotificationType } from './Notification';

type CustomMessage = ValueOrFunction<Renderable, Toast>;
interface NotificationOptions {
  // can be set to Infinity
  duration?: number;
  showCloseButton?: boolean;
}

const create = (
  message: Renderable,
  type: NotificationType,
  options?: NotificationOptions
) => {
  return toast.custom(
    (t) => (
      <Notification
        type={type}
        className={clsx('m-1 py-8 max-w-[500px] shadow-md', {
          'justify-between': options?.showCloseButton,
        })}
      >
        <>
          {message}
          {options?.showCloseButton && (
            <FontIcon onClick={() => toast.remove(t.id)} icon={faXmark} />
          )}
        </>
      </Notification>
    ),
    options
  );
};

const success = (message: Renderable, options?: NotificationOptions) => {
  return create(message, 'success', options);
};

const error = (message: Renderable, options?: NotificationOptions) => {
  return create(message, 'error', options);
};

const warning = (message: Renderable, options?: NotificationOptions) => {
  return create(message, 'warning', options);
};

const info = (message: Renderable, options?: NotificationOptions) => {
  return create(message, 'info', options);
};

const custom = (message: CustomMessage, options?: NotificationOptions) => {
  return toast.custom(message, options);
};

const notification = {
  success,
  error,
  warning,
  info,
  custom,
};

export { notification, Notification };
