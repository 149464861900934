import React from 'react';

import { Content } from 'components';
import { useTranslation } from 'react-i18next';

import { usePageTitle } from 'modules/root/Settings';

import { ControlPanel } from './ControlPanel/ControlPanel';
import { DrawdownRequestBreadcrumb } from './DrawdownRequestBreadcrumb/DrawdownRequestBreadcrumb';
import { DrawdownRequestSettings } from './DrawdownRequestSettings/DrawdownRequestSettings';
import { DrawdownRequestStep } from './DrawdownRequestStep/DrawdownRequestStep';
import { DrawdownRequestStepper } from './DrawdownRequestStep/DrawdownRequestStepper';

interface DrawdownRequestLayoutProps {}

export const DrawdownRequestLayout: React.FC<DrawdownRequestLayoutProps> = (props) => {
  const { t } = useTranslation();
  usePageTitle(t('drawdownRequest.title'));
  return (
    <div className="grid grid-rows-[min-content_1fr] h-full relative">
      <DrawdownRequestSettings />
      <div className="sticky top-0 bg-white/70 backdrop-blur-sm flex flex-col w-full z-10">
        <DrawdownRequestBreadcrumb />
      </div>
      <Content>
        <>
          <DrawdownRequestStepper />
          <div className="flex flex-col max-w-[600px] mx-auto">
            <DrawdownRequestStep />
          </div>
        </>
      </Content>
      <div className="sticky bottom-0 bg-white/70 backdrop-blur-sm w-full h-[72px] border-t border-t-gray-200">
        <ControlPanel />
      </div>
    </div>
  );
};
