import React from 'react';

import { useTranslation } from 'react-i18next';
import { printMoney } from 'utils';

import { LoanIcon } from 'modules/common/LoanIcon/LoanIcon';
import { useCustomisation } from 'modules/root/Settings';
import { useAtomValue } from 'jotai/react';
import { drawdownRequestResultAtom } from 'modules/DrawdownRequest/models/result';

interface LinkedLoansProps {}

export const LinkedLoans: React.FC<LinkedLoansProps> = (props) => {
  const { t } = useTranslation();
  const { labels } = useCustomisation();
  const result = useAtomValue(drawdownRequestResultAtom);
  const loans = result?.calculation.crossCollDetail?.loanDetails ?? [
    result?.afterLoanInfo.loanInfo,
  ];
  return (
    <div className='mb-8'>
      <div className="flex flex-row items-center py-4 px-3 h-[60px] bg-secondary-50 border-l border-t border-r rounded-tl-xl rounded-tr-xl">
        <span className="subheadline grow mr-5">{t('drawdownRequest.linkedLoans')}</span>
      </div>
      <div className="px-8 py-6 bg-white border-l border-b border-r rounded-bl-xl rounded-br-xl flex flex-col gap-6">
        {loans && loans.map((loan, index) => (
          <React.Fragment key={index}>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <LoanIcon />
                <div>{t('customer.loans.single')}</div>
                <div>
                  <span>{'#'}</span>
                  <span>{`${loan?.obligor}-${loan?.obligation}`}</span>
                </div>
              </div>

              <div className="flex gap-10">
                <div className="flex flex-col basis-1/2 gap-1">
                  <div className="text-gray-500 font-semibold">
                    {labels.commitmentAmount}
                  </div>
                  {printMoney(loan?.commitmentAmount)}
                </div>
                <div className="flex flex-col basis-1/2 gap-1">
                  <div className="text-gray-500 font-semibold">
                    {labels.outstandingBalance}
                  </div>
                  {printMoney(loan?.outstandingBalance)}
                </div>
              </div>
            </div>
            {index < loans.length - 1 && <div className="h-0.5 bg-gray-50" />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
