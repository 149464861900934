import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontIcon } from 'components';

import { cn } from 'utils/styles';

export type TextInputOnBlur = (
  event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
) => void;

interface BaseInputProps {
  name?: string;
  label?: string;
  text?: string;
  size?: 'base' | 'sm';
  multiline?: boolean;
  maxLength?: number;
  errorMessage?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  prefixIcon?: IconProp;
  formatFn?: (value: string) => string;
  filterFn?: (value: string) => string;
  suffixIcon?: IconProp;
  tabIndex?: number;
  autocomplete?: 'on' | 'off';
  'data-testid'?: string;
  onChange?: (value: string) => void;
  onBlur?: TextInputOnBlur;
}

interface SuffixTextProps {
  suffixText: string;
  onSuffixClick?: () => void;
}

interface NoSuffixTextProps {
  suffixText?: undefined;
  onSuffixClick?: never;
}

export type TextInputProps = BaseInputProps & (NoSuffixTextProps | SuffixTextProps);

export type Ref = HTMLInputElement;

const wrapperStyles = (valid: boolean, disabled: boolean, size: TextInputProps['size']) =>
  cn(
    'group/text',
    'w-full',
    'flex',
    'items-center',
    'border',
    'rounded',
    'box-border',
    { 'border-gray-300': valid, 'border-red-500': !valid },
    { 'bg-gray-50': disabled, 'hover:bg-gray-50': !disabled },

    'outline-primary-600 outline-2',
    'focus-within:outline',
    { 'py-3 px-4': size === 'base', 'py-2 px-2': size === 'sm' }
  );

const inputStyles = (size: TextInputProps['size']) =>
  cn(
    'w-full',
    'leading-4',
    'outline-none',
    'box-content',
    'block',
    'bg-none',
    'border-0',
    'enabled:group-hover/text:bg-gray-50',
    'disabled:bg-gray-50',
    'disabled:text-gray-400',
    { 'text-base': size === 'base', 'text-sm': size === 'sm' }
  );

const labelStyles = (
  valid: boolean,
  shrinkLabel: boolean,
  hasFocus: boolean,
  size: TextInputProps['size']
) =>
  cn(
    'block',
    'text-gray-500',
    'w-full',
    {
      'text-primary-600': hasFocus,
      'text-red-500': !valid,
    },
    'absolute',
    'origin-top-left',
    'left-4',
    'transition-all',
    shrinkLabel ? 'scale-75 translate-y-1 font-semibold' : 'scale-100 translate-y-4',
    {
      'text-lg': size === 'base',
      'text-base': size === 'sm',
    }
  );

const expandTextarea = (textarea: HTMLTextAreaElement) => {
  textarea.style.height = 'auto';
  textarea.style.height = textarea.scrollHeight + 'px';
};

export const TextInput: React.FC<TextInputProps> = ({
  name,
  label,
  text,
  size = 'base',
  maxLength,
  multiline,
  errorMessage,
  placeholder,
  className,
  disabled = false,
  prefixIcon,
  suffixIcon,
  suffixText,
  onSuffixClick,
  tabIndex,
  autocomplete = 'on',
  'data-testid': dataTestId = 'text-input',
  formatFn,
  filterFn,
  onChange,
  onBlur,
}) => {
  const inputRef = React.useRef(null);
  const inputId = `text-input${React.useId()}`;

  const handleClick = React.useCallback(() => {
    if (inputRef.current) {
      (inputRef.current as HTMLInputElement | HTMLTextAreaElement).focus();
    }
  }, []);

  const [value, setValue] = React.useState(text ?? '');
  const [shrinkLabel, setShrinkLabel] = React.useState(!!value || false);
  const [hasFocus, setHasFocus] = React.useState(false);

  const setFormatValue = React.useCallback(
    (value?: string) => {
      if (formatFn && !hasFocus) {
        const formattedValue = formatFn(value ?? '');
        setValue(formattedValue);
        // this onChange is needed for react-hook-form, otherwise it will store unformatted value
        if (onChange) {
          onChange(formattedValue);
        }
      } else {
        setValue(value ?? '');
      }
    },
    [formatFn, hasFocus, onChange]
  );

  React.useEffect(() => {
    setFormatValue(text || '');
  }, [setFormatValue, text]);

  React.useEffect(() => {
    hasFocus ? setShrinkLabel(true) : setShrinkLabel(!!value);
  }, [hasFocus, value, setShrinkLabel]);

  React.useLayoutEffect(() => {
    if (multiline && inputRef.current) {
      const textarea = inputRef.current as HTMLTextAreaElement;
      if (textarea.scrollHeight) {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
      }
    }
  }, [inputRef, multiline]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = event.target.value;
      if (filterFn) {
        value = filterFn(value);
      }
      setValue(value);
      if (onChange) {
        onChange(value);
      }
      if (multiline) {
        expandTextarea(event.target as HTMLTextAreaElement);
      }
    },
    [onChange, multiline, filterFn]
  );

  const handleBlur = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = event.target.value;
      setFormatValue(value);
      if (!!value !== shrinkLabel) {
        setShrinkLabel(!!value);
      }

      if (onBlur) {
        onBlur(event);
      }
      if (multiline) {
        expandTextarea(event.target as HTMLTextAreaElement);
      }
      setHasFocus(false);
    },
    [setFormatValue, shrinkLabel, onBlur, multiline]
  );

  const handleFocus = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = event.target.value;
      if (filterFn) {
        value = filterFn(value);
        setFormatValue(value);
      }
      setShrinkLabel(true);
      if (multiline) {
        expandTextarea(event.target as HTMLTextAreaElement);
      }
      setHasFocus(true);
    },
    [filterFn, multiline, setFormatValue]
  );

  return (
    <div className={cn('mt-1 mb-4 relative', className)} data-testid={dataTestId}>
      {label && (
        <label
          className={labelStyles(!errorMessage, shrinkLabel, hasFocus, size)}
          htmlFor={inputId}
        >
          {label}
        </label>
      )}
      <div className={wrapperStyles(!errorMessage, disabled, size)} onClick={handleClick}>
        {prefixIcon && (
          <FontIcon
            className={cn({
              'h-3 w-3 mr-0.5': size === 'sm',
              'h-4 w-4 mr-2 mt-4': size === 'base',
            })}
            icon={prefixIcon}
          />
        )}
        {multiline ? (
          <textarea
            id={inputId}
            data-testid={`${dataTestId}-textarea`}
            name={name}
            value={value}
            placeholder={shrinkLabel ? placeholder : undefined}
            className={cn(
              inputStyles(size),
              'resize-none',
              'overflow-y-auto',
              'max-h-52',
              {
                'mt-4': label,
              }
            )}
            rows={3}
            maxLength={maxLength}
            disabled={disabled}
            ref={inputRef}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            aria-invalid={errorMessage ? 'true' : 'false'}
            tabIndex={tabIndex}
            autoComplete={autocomplete}
          />
        ) : (
          <input
            id={inputId}
            data-testid={`${dataTestId}-input`}
            type="text"
            name={name}
            value={value}
            placeholder={placeholder}
            className={cn(inputStyles(size), { 'mt-4': label })}
            maxLength={maxLength}
            disabled={disabled}
            ref={inputRef}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            aria-invalid={errorMessage ? 'true' : 'false'}
            tabIndex={tabIndex}
            autoComplete={autocomplete}
          ></input>
        )}
        {suffixIcon && <FontIcon className="h-4 w-4 ml-2" icon={suffixIcon} />}
        {!disabled && suffixText && (
          <div
            className={cn('text-sm whitespace-nowrap font-semibold z-10', {
              'text-primary-500 hover:text-primary-600 cursor-pointer select-none':
                onSuffixClick,
            })}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.stopPropagation();
              onSuffixClick?.();
            }}
          >
            {suffixText}
          </div>
        )}
      </div>
      {!!errorMessage && (
        <div role="alert" className="text-red-500 mt-1 text-sm">
          {errorMessage}
        </div>
      )}
    </div>
  );
};
