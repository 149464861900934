import React from 'react';

import { useTranslation } from 'react-i18next';

import { AssetSelector } from '../ReleaseRequestStep/steps/RequestStep/AssetSelector/AssetSelector';
import { ReleaseRequestResult } from './Result/Result';

interface ReleaseRequestCalcuationProps {}

export const ReleaseRequestCalcuation: React.FC<ReleaseRequestCalcuationProps> = (
  props
) => {
  const { t } = useTranslation();

  return (
    <div className="flex">
      <div className="basis-1/2 max-w-[50%]">
        <div className="w-full flex flex-col p-5 xl:p-10 h-[calc(100vh-72px)] overflow-y-auto border-r border-r-gray-200 sticky top-0">
          <h2 className="text-lg font-bold mb-8">{t('releaseRequest.headers.review')}</h2>
          <AssetSelector />
        </div>
      </div>
      <div className="basis-1/2 max-w-[50%] py-5 xl:py-10 px-8">
        <ReleaseRequestResult />
      </div>
    </div>
  );
};
