import React from 'react';
import { Outlet } from 'react-router';

import { Provider as JotaProvider, createStore } from 'jotai';

interface ReleaseRequestProviderProps {}

interface Context {
  resetStore: () => void;
}

const ReleaseRequestContext = React.createContext<Context>({
  resetStore: () => {},
});

export const ReleaseRequestRoot: React.FC<ReleaseRequestProviderProps> = (props) => {
  const store = createStore();
  const [reset, setReset] = React.useState(0);
  const resetStore = React.useCallback(() => {
    setReset(Math.random());
  }, []);
  return (
    <ReleaseRequestContext.Provider value={{ resetStore }}>
      <JotaProvider store={store}>
        <Outlet key={reset} />
      </JotaProvider>
    </ReleaseRequestContext.Provider>
  );
};

export const useReleaseRequestContext = () => React.useContext(ReleaseRequestContext);
