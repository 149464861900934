import React from 'react';
import { generatePath } from 'react-router';

import { Button, ButtonLink, LoadedButton } from 'components';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { usePaths } from 'modules/root/Settings';
import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';

import { DownloadPDF } from '../DrawdownRequestStep/steps/ResultStep/sections/DownloadPDF';
import { isCustomerEligibleAtom } from '../models/customer';
import { drawdownRequestStatusAtom } from '../models/status';
import { StepIds, currentStepAtom, nextStepAtom } from '../models/step';

interface ControlPanelProps {}

export const ControlPanel: React.FC<ControlPanelProps> = (props) => {
  const { t } = useTranslation();
  const currentStep = useAtomValue(currentStepAtom);
  const [nextStep, setNextStep] = useAtom(nextStepAtom);
  const [status, setStatus] = useAtom(drawdownRequestStatusAtom);
  const isCustomerEligible = useAtomValue(isCustomerEligibleAtom);
  const paths = usePaths();
  const {
    permissions: { canAccessDrawdownRequestPdf },
  } = useAccess();

  return (
    <div className="w-[600px] h-full mx-auto flex flex-row justify-end items-center">
      {(() => {
        if (nextStep?.id === StepIds.Results) {
          return (
            <Button
              onClick={() => {
                setNextStep();
                setStatus('progress', 'non-persist');
              }}
              data-testid="calculate-btn"
              disabled={!isCustomerEligible || !currentStep?.valid}
            >
              {t('drawdownRequest.buttons.calculate')}
            </Button>
          );
        } else if (currentStep?.id === StepIds.Details) {
          return (
            <div className="h-full w-full flex items-center gap-4">
              {canAccessDrawdownRequestPdf && (
                <div className="flex-none overflow-hidden">
                  <DownloadPDF />
                </div>
              )}
              <div className="grow"></div>
              <ButtonLink
                state="secondary"
                url={generatePath(paths.servicing.drawdownRequests)}
                data-testid="drawdown-request-cancel-btn"
                disabled={status === 'saved'}
              >
                {t('common.cancel')}
              </ButtonLink>
              <LoadedButton
                className="whitespace-nowrap"
                data-testid="drawdown-request-save-btn"
                isLoading={status === 'progress'}
                disabled={status === 'saved'}
                onClick={() => {
                  setStatus('progress', 'persist');
                }}
              >
                {t('drawdownRequest.buttons.submit')}
              </LoadedButton>
            </div>
          );
        } else {
          return (
            <Button
              disabled={!nextStep?.enable || !currentStep?.valid}
              onClick={setNextStep}
            >
              {t('common.continue')}
            </Button>
          );
        }
      })()}
    </div>
  );
};
