import React from 'react';

import { RunReleaseRequestMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { printMoney } from 'utils';

interface FiguresProps {
  result: RunReleaseRequestMutation['runReleaseRequest'];
  isApproved: boolean;
}

export const Figures: React.FC<FiguresProps> = ({ result, isApproved }) => {
  const { t } = useTranslation();
  return (
    <div className="py-6 px-8 flex flex-col gap-2 border border-indigo-100 bg-indigo-50/50 rounded-lg w-full my-8 font-semibold">
      <div className="flex">
        <div className="grow">{t('releaseRequest.marketValueBefore')}</div>
        <div className="text-xl tracking-wide">
          {printMoney(result.beforeCalculation.marketValue)}
        </div>
      </div>
      <div className="flex">
        <div className="grow">{t('releaseRequest.marketValueAfter')}</div>
        <div className="text-xl tracking-wide">
          {printMoney(result.afterCalculation.marketValue)}
        </div>
      </div>
      {!isApproved && (
        <div className="flex">
          <div className="grow">{t('releaseRequest.deficitAfter')}</div>
          <div className="text-xl tracking-wide">
            {printMoney(
              result.afterLoanInfo.loanInfo.outstandingBalance -
                result.afterCalculation.marketValue
            )}
          </div>
        </div>
      )}
    </div>
  );
};
