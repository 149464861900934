import React from 'react';

import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { FontIcon } from 'components/FontIcon/FontIcon';

interface HiddenDetailsProps {
  className?: string;
  showLine?: boolean;
  showLabel?: string;
  hideLabel?: string;
}

export const HiddenDetails: React.FC<React.PropsWithChildren<HiddenDetailsProps>> = ({
  className,
  showLabel,
  hideLabel,
  showLine = true,
  children,
}) => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = React.useState(false);
  return (
    <div className={clsx('overflow-hidden', className)}>
      <button
        type="button"
        className="mt-2 font-bold text-primary-600 text-sm flex gap-2 items-center h-6 w-fit select-none"
        onClick={(evt) => {
          setIsShow(!isShow);
          evt.stopPropagation();
        }}
      >
        {!isShow && (
          <>
            {showLabel || t('common.showDetails')} <FontIcon icon={faAngleDown} />
          </>
        )}
        {isShow && (
          <>
            {hideLabel || t('common.hideDetails')} <FontIcon icon={faAngleUp} />
          </>
        )}
      </button>
      <div
        className={clsx(' transition-all duration-500 ease-in-out', {
          'my-2': isShow,
          'max-h-0': !isShow,
          'max-h-[500px]': isShow,
        })}
      >
        <div className={clsx({ 'h-[1px] bg-gray-200': showLine })}></div>
        {children}
      </div>
    </div>
  );
};
