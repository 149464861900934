import React from 'react';
import { Link } from 'react-router-dom';

import { LoadingSpinner } from 'components/Loading/LoadingSpinner';
import { cn } from 'utils/styles';

interface ButtonGeneralProps {
  id?: string;
  children?: React.ReactNode;
  type?: 'submit' | 'reset' | 'button';
  state?: 'primary' | 'secondary' | 'text' | 'link';
  size?: 'lg' | 'md' | 'sm';
  disabled?: boolean;
  className?: string;
  'data-testid'?: string;
}
export interface ButtonProps extends ButtonGeneralProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

interface LoadedButtonProps extends ButtonProps {
  isLoading: boolean;
}

interface ButtonLinkProps extends ButtonGeneralProps {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  url: string;
}

const getByButtonSize = (size: ButtonProps['size']) => {
  switch (size) {
    case 'lg':
      return 'lg-button';
    case 'md':
      return 'py-2.5 px-2.5 text-[13px]';
    case 'sm':
      return 'p-2.5 text-xs';
  }
};

const getClassName = (state: ButtonProps['state'], size: ButtonProps['size']) => {
  const buttonSize = getByButtonSize(size);
  switch (state) {
    case 'primary':
      return `text-gray-50 bg-primary-600 hover:bg-primary-700 disabled:bg-primary-600/20 disabled:hover:bg-primary-600/20 ${buttonSize}`;
    case 'secondary':
      return `bg-white text-primary-700 border border-solid border-gray-300 hover:bg-primary-50 hover:border-primary-100 hover:text-primary-600
      disabled:bg-gray-100 disabled:border-gray-100 disabled:text-gray-300 ${buttonSize}`;
    case 'text':
      return `border-0 ${buttonSize}`;
    case 'link':
      return `text-primary-700 hover:text-primary-600 disabled:text-gray-300 underline border-0 ${buttonSize}`;
  }
};

export const Button: React.FC<ButtonProps> = ({
  id,
  type = 'button',
  state = 'primary',
  size = 'lg',
  children,
  disabled = false,
  className,
  onClick,
  'data-testid': dataTestId,
}) => {
  const classButtonName = getClassName(state, size);
  return (
    <button
      id={id}
      data-testid={dataTestId}
      className={cn(
        classButtonName,
        'rounded-sm',
        'font-semibold',
        'inline-flex',
        'items-center',
        className
      )}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  state = 'primary',
  size = 'lg',
  children,
  disabled = false,
  className,
  url,
  onClick,
  'data-testid': dataTestId,
}) => {
  const classButtonName = getClassName(state, size);
  const disableLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
  };

  return (
    <Link
      data-testid={dataTestId}
      to={url}
      className={cn(
        className,
        classButtonName,
        'rounded-sm',
        'font-semibold',
        'inline-flex',
        'items-center',
        {
          'bg-gray-100 border-gray-200 text-gray-300': disabled && state === 'secondary',
          'bg-primary-600/20 hover:bg-primary-600/20': disabled && state === 'primary',
        }
      )}
      onClick={disabled ? disableLink : onClick}
    >
      {children}
    </Link>
  );
};

export const LoadedButton: React.FC<LoadedButtonProps> = ({
  isLoading,
  children,
  ...props
}) => {
  return (
    <div className="relative inline-block">
      <Button {...props}>
        {isLoading && (
          <LoadingSpinner
            className="absolute top-1/2 left-1/2 transform-center"
            size="2x"
          />
        )}
        {children}
      </Button>
    </div>
  );
};
