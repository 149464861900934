import React from 'react';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { Popover } from '@headlessui/react';
import { Calendar, CalendarProps } from 'components';
import { DATE_FORMAT_MONTH_NAME, formatDate } from 'utils';

import { FontIcon } from 'components/FontIcon/FontIcon';
import { cn } from 'utils/styles';

import { ButtonProps } from './Button';

interface PickDateButtonProps extends Omit<ButtonProps, 'children' | 'state'> {
  label: string;
  disabled?: boolean;
  calendar?: Omit<CalendarProps, 'selected' | 'onSelect' | 'mode'>;
  onChange?: (date: Date | undefined) => void;
}

export const PickDateButton: React.FC<PickDateButtonProps> = ({
  label,
  className,
  disabled,
  onChange,
  calendar = {},
  ...props
}) => {
  const id = React.useId();
  const [date, setDate] = React.useState<Date | undefined>();
  const { className: calendarClassName, ...calendarProps } = calendar;

  const getHandleSelect = React.useCallback(
    (close: Function) => (date: Date | undefined) => {
      onChange?.(date);
      setDate(date);
      close();
    },
    [onChange]
  );

  return (
    <Popover className="relative w-full mt-1 mb-4">
      {({ open, close }) => (
        <>
          <Popover.Button
            disabled={disabled}
            className={cn(
              'w-full border rounded box-border relative py-3 px-4 border-gray-300 flex items-center justify-between',
              { 'bg-gray-50': disabled, 'hover:bg-gray-50': !disabled }
            )}
          >
            <>
              <label
                className={cn(
                  'block absolute origin-top-left top-0 left-4 transition-all text-lg',
                  {
                    'scale-75 translate-y-1 font-semibold': date,
                    'scale-100 translate-y-4': !date,
                  }
                )}
                htmlFor={`date-btn${id}`}
              >
                {label}
              </label>
              {
                <div className="mt-4 h-6">
                  {date && formatDate(date.toDateString(), DATE_FORMAT_MONTH_NAME)}
                </div>
              }
            </>
            <FontIcon icon={faCalendar} />
          </Popover.Button>

          {
            <Popover.Panel className="absolute z-10 mt-3 bg-white">
              <Calendar
                {...calendarProps}
                mode="single"
                selected={date}
                onSelect={getHandleSelect(close)}
                className={cn('rounded-md border', calendarClassName)}
              />
            </Popover.Panel>
          }
        </>
      )}
    </Popover>
  );
};
