import React from 'react';
import { generatePath, useNavigate, useParams } from 'react-router';

import { Notification } from 'components';
import {
  ActivityAuditType,
  DrawdownRequestAmountQuery,
  LoanStatus,
  useDrawdownRequestAmountQuery,
} from 'generated/graphql';
import { useAtom, useAtomValue } from 'jotai/react';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';

import { breadcrumbsAtom } from 'modules/DrawdownRequest/models/breadcrumb';
import {
  isCustomerEligibleAtom,
  selCustomerAtom,
} from 'modules/DrawdownRequest/models/customer';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { INELIGIBLE_STATUSES } from 'modules/common/constants/status';
import { usePaths } from 'modules/root/Settings';

import { AmountField } from './fields/AmountField';
import { LoanField } from './fields/LoanField';

export const useLoadData = () => {
  const [selCustomer, setSelCustomer] = useAtom(selCustomerAtom);
  const paths = usePaths();
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [breadcrumbs, setBreadcrumbs] = useAtom(breadcrumbsAtom);
  const [loans, setLoans] =
    React.useState<DrawdownRequestAmountQuery['customer']['loans']>(null);

  const { isLoading, error } = useQueryFetch(useDrawdownRequestAmountQuery, {
    queryHookOptions: {
      enabled: !(selCustomer && loans),
      onSuccess(data) {
        const result = (data as DrawdownRequestAmountQuery).customer;
        setSelCustomer({
          id: result.id,
          customerId: result.customerId,
          displayName: result.displayName,
          serviceStatus: result.serviceStatus?.status,
        });
        setLoans(result.loans || []);
      },
    },
    queryHookParams: { customerID: customerId || '' },
    extra: {
      auditReport: { activityType: ActivityAuditType.Read },
    },
  });

  React.useEffect(() => {
    if (selCustomer) {
      const path = generatePath(paths.drawdown.proceed, {
        customerId: selCustomer.id,
      });
      if (!breadcrumbs.some((bc) => bc.link === path)) {
        setBreadcrumbs([
          ...breadcrumbs.slice(0, 3),
          {
            label: selCustomer.displayName,
            link: path,
          },
        ]);
        navigate(path, { replace: true });
      }
    }
  }, [breadcrumbs, navigate, paths, selCustomer, setBreadcrumbs]);

  return { breadcrumbs, selCustomer, loans, isLoading, error };
};

interface AmountStepProps {}

export const AmountStep: React.FC<AmountStepProps> = (props) => {
  const { t } = useTranslation();
  const isCustomerEligible = useAtomValue(isCustomerEligibleAtom);
  const { loans, isLoading, error, selCustomer } = useLoadData();

  return (
    <QuerySuspense error={error} isLoading={isLoading} className="w-full flex flex-col">
      <>
        {isCustomerEligible ? (
          <h2 className="text-lg font-bold mb-8">
            {t('drawdownRequest.headers.setAmount')}
          </h2>
        ) : (
          <Notification
            className="m-0 mb-5 self-center"
            type="error"
            data-testid="not-eligible-alert"
          >
            {selCustomer?.serviceStatus &&
            INELIGIBLE_STATUSES.includes(selCustomer.serviceStatus) ? (
              <>
                {t('drawdownRequest.messages.notEligible')}
                <br />
                {t('common.askSupport')}
              </>
            ) : (
              t('drawdownRequest.messages.notEligibleProspect')
            )}
          </Notification>
        )}
        {selCustomer?.serviceStatus &&
          selCustomer.serviceStatus !== LoanStatus.Prospect && (
            <div className="flex flex-col gap-6">
              <LoanField loans={loans} />
              <AmountField />
            </div>
          )}
      </>
    </QuerySuspense>
  );
};
