import React from 'react';
import { Outlet, generatePath, useLocation, useNavigate } from 'react-router';

import { ButtonLink, Notification, ShrinkHeader, TabItem } from 'components';
import { useTranslation } from 'react-i18next';

import { SearchBox } from 'modules/Customers/SearchBox/SearchBox';
import { useCustomisation, usePaths } from 'modules/root/Settings';
import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';

interface ServicingProps {}

export const Servicing: React.FC<ServicingProps> = () => {
  const { labels } = useCustomisation();
  const paths = usePaths();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    permissions: {
      canCreateReleaseRequest,
      canCreateDrawdownRequest,
      canViewReleaseRequest,
      canViewDrawdownRequest,
    },
  } = useAccess();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [searchPlaceholderText, setSearchPlaceholderText] = React.useState(
    labels.evaluations
  );

  const tabs: TabItem[] = React.useMemo(() => {
    const items: TabItem[] = [];
    if (canViewReleaseRequest) {
      items.push({
        key: 'collateral-release',
        label: t('releaseRequest.collateralRelease'),
        path: paths.servicing.collateralRelease,
        show: true,
      });
    }
    if (canViewDrawdownRequest) {
      items.push({
        key: 'drawdown-requests',
        label: t('drawdownRequest.servicingTitle'),
        path: paths.servicing.drawdownRequests,
        show: true,
      });
    }
    return items;
  }, [
    canViewDrawdownRequest,
    canViewReleaseRequest,
    paths.servicing.collateralRelease,
    paths.servicing.drawdownRequests,
    t,
  ]);

  React.useEffect(() => {
    if (location.pathname === paths.servicing.list) {
      if (canViewReleaseRequest) {
        navigate(paths.servicing.collateralRelease, { replace: true });
      } else if (canViewDrawdownRequest) {
        navigate(paths.servicing.drawdownRequests, { replace: true });
      }
    }
  }, [navigate, paths, location.pathname, canViewReleaseRequest, canViewDrawdownRequest]);

  React.useEffect(() => {
    setSearchValue('');
  }, [searchPlaceholderText]);

  const handleTabChange = React.useCallback(
    (key: string) => {
      if (key === 'collateral-release') {
        setSearchPlaceholderText(t('releaseRequest.requests'));
      } else if (key === 'drawdown-requests') {
        setSearchPlaceholderText(t('drawdownRequest.servicingTitle'));
      }
    },
    [t]
  );

  const handleSearch = React.useCallback((value: string) => {
    setSearchValue(value.trim());
  }, []);

  return (
    <>
      <ShrinkHeader
        title={labels.servicing}
        tabs={tabs}
        onTabChange={handleTabChange}
        buttons={
          <div className="flex gap-4">
            {canCreateReleaseRequest && (
              <ButtonLink
                className="whitespace-nowrap"
                url={generatePath(paths.collateralRelease.new)}
                size="md"
                state="secondary"
              >
                {t('releaseRequest.collateralRelease')}
              </ButtonLink>
            )}
            {canCreateDrawdownRequest && (
              <ButtonLink
                className="whitespace-nowrap"
                url={generatePath(paths.drawdown.new)}
                size="md"
                state="secondary"
              >
                {t('drawdownRequest.title')}
              </ButtonLink>
            )}
            <SearchBox
              key={searchPlaceholderText}
              onChange={handleSearch}
              placeholder={`${t('common.searchBox.find')} ${searchPlaceholderText}`}
            />
          </div>
        }
      />
      <div className="mx-8 lg:mx-14 xl:mx-20 my-6 lg:my-10 xl:my-14">
        <Outlet context={{ searchValue }} />
        {!canViewReleaseRequest &&
          !canViewDrawdownRequest &&
          location.pathname === paths.servicing.list && (
            <Notification type="warning" data-testid="no-permissions-message">
              {t('auth.disallow')}
            </Notification>
          )}
      </div>
    </>
  );
};
