import React from 'react';

import { cn } from 'utils/styles';

import { TableCell } from './TableCell';
import { getWarning } from './utils';

type ChildRow = ReturnType<typeof TableCell>;
export interface TableRowProps {
  children?: ChildRow | ChildRow[];
  onRowClick?: (event: React.MouseEvent<HTMLTableRowElement>) => void;
  className?: string;
  'data-testid'?: string;
}

export const TableRow: React.FC<TableRowProps> = ({
  children,
  className,
  onRowClick,
  'data-testid': dataTestId,
}) => {
  return (
    <tr
      data-testid={dataTestId}
      className={cn(
        'border-0',
        {
          'cursor-pointer': !!onRowClick,
          'hover:bg-primary-200/10': !!onRowClick,
        },
        className
      )}
      onClick={onRowClick}
    >
      {React.Children.map(children, (child) => {
        if (child && child.type !== TableCell) {
          const warning = getWarning('TableRow', 'TableCell');
          console.error(warning);
        }
        return child;
      })}
    </tr>
  );
};
