import React from 'react';

import { BreadCrumb, BreadCrumbTheme } from 'components';
import { useAtomValue } from 'jotai';

import { breadcrumbsAtom } from '../models/breadcrumb';

interface ReleaseRequestBreadcrumbProps {}

export const ReleaseRequestBreadcrumb: React.FC<ReleaseRequestBreadcrumbProps> = (
  props
) => {
  const breadcrumbs = useAtomValue(breadcrumbsAtom);
  return (
    <div className="flex h-12 w-full border-b-[1px] border-b-gray-200 items-center pl-10">
      <BreadCrumb list={breadcrumbs} theme={BreadCrumbTheme.light} />
    </div>
  );
};
