import { LoanProposalQuery } from 'generated/graphql';
import { t } from 'i18next';
import { FieldErrors, Resolver } from 'react-hook-form';
import { getNumberValue, printMoney } from 'utils';

import { ConfirmationValues } from './controllers/ConfirmationController';
import { LoanProposalFormValues } from './types';

export enum LoanProposalErrorTypes {
  MinimumLoanAmount = 'Minimum Loan Amount',
  MaximumLoanAmount = 'Maximum Loan Amount',
  Confirmation = 'Confirmation',
}

export const buildLoanProposalResolver = (
  config: LoanProposalQuery['applicationConfig'] | undefined
): Resolver<LoanProposalFormValues> => {
  return async (values) => {
    const errors: FieldErrors<LoanProposalFormValues> = {};
    const minLoanAmount = config?.loanProposal.minimumLoanAmount;
    const maxLoanAmount = config?.loanProposal.maximumLoanAmount?.find(
      (item) => item?.productType === values.productType
    );
    const loanAmountValue = values?.loanAmount ? getNumberValue(values.loanAmount) : 0;
    if (minLoanAmount && loanAmountValue < minLoanAmount) {
      errors.loanAmount = {
        type: LoanProposalErrorTypes.MinimumLoanAmount,
        message: t('loanProposal.minLoanAmoutError', {
          minLoanAmount: printMoney(minLoanAmount),
        }),
      };
    } else if (maxLoanAmount && loanAmountValue > maxLoanAmount.value) {
      errors.loanAmount = {
        type: LoanProposalErrorTypes.MaximumLoanAmount,
        message: maxLoanAmount.errorMessage,
      };
    }

    if (
      config?.loanProposal.showFields.confirmation &&
      values.confirmation !== ConfirmationValues.Yes
    ) {
      errors.confirmation = {
        type: LoanProposalErrorTypes.Confirmation,
        message: '',
      };
    }
    return {
      values: Object.keys(errors).length ? {} : values,
      errors,
    };
  };
};
