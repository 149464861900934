import React from 'react';

import { SortingState } from '@tanstack/react-table';
import { debounce } from 'lodash';

import { PageChangeFn, SortChangeFn } from './ReactTable';

export const useLimitedPagination = (
  rowsPerPage: number,
  limit: number,
  reset: boolean = false
) => {
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(rowsPerPage);
  const [isLimitReached, setIsLimitReached] = React.useState(false);

  const handlePageChange: PageChangeFn = React.useCallback(
    ({ pageIndex, pageSize }) => {
      if (pageIndex < Math.ceil(limit / pageSize)) {
        setPageIndex(pageIndex);
        setPageSize(pageSize);
        setIsLimitReached(false);
      } else {
        setIsLimitReached(true);
      }
    },
    [limit]
  );
  return {
    handlePageChange,
    pagination: {
      pageIndex: reset ? 0 : pageIndex,
      pageSize,
    },
    isLimitReached,
  };
};

export const useSorting = () => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const handleSortChange: SortChangeFn = React.useCallback((state) => {
    setSorting(state);
  }, []);
  return {
    handleSortChange,
    sorting,
  };
};

export type SortingParam = ReturnType<typeof useSorting>['sorting'];
export type PaginationParam = ReturnType<typeof useLimitedPagination>['pagination'];
type QueryParams = {
  pagination: PaginationParam;
  sorting?: SortingParam;
  searchText?: string;
};
const getSearchQueryParams = ({ sorting, pagination, searchText }: QueryParams) => ({
  fromIndex: pagination.pageIndex * pagination.pageSize,
  numResults: pagination.pageSize,
  searchText: searchText ? searchText : undefined,
  sortBy: sorting?.length
    ? sorting.map((sort) => ({
        field: sort.id,
        order: sort.desc ? 'desc' : 'asc',
      }))
    : undefined,
});

export const useSearchQueryParams = ({
  sorting,
  pagination,
  searchText,
}: QueryParams) => {
  const newData = getSearchQueryParams({ sorting, pagination, searchText });
  const [data, setData] = React.useState(newData);
  const debounced = React.useRef(debounce(setData, 5));
  React.useEffect(() => {
    if (JSON.stringify(newData) !== JSON.stringify(data)) {
      debounced.current(newData);
    }
  }, [newData, data]);
  return data;
};

export type SearchQueryParams = ReturnType<typeof useSearchQueryParams>;
