import React from 'react';

import FileSaver from 'file-saver';
import { extractFileNameFromHeader } from 'utils';

import { AuthError } from 'modules/common/errors/AuthError';
import { SCHEMA_HOST } from 'modules/root/Settings/envVars';
import { useAuth } from 'modules/root/auth/auth.context';

export const useDownloadFile = () => {
  const { idToken } = useAuth();
  if (!idToken) {
    throw new AuthError('idToken is not provided');
  }

  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const download = React.useCallback(
    (urlPath: string, filename: string, contentType: string) => {
      setIsLoading(true);
      setIsError(false);
      const url = SCHEMA_HOST
        ? `${SCHEMA_HOST}${urlPath}`
        : `http://127.0.0.1:4000${urlPath}`;
      let fileNameFromServer: string | null;

      return fetch(url, {
        headers: {
          'Content-Type': contentType,
          Authorization: `Bearer ${idToken}`,
        },
        method: 'GET',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error();
          }
          fileNameFromServer = extractFileNameFromHeader(
            response.headers.get('content-disposition')
          );
          return response.blob();
        })
        .then((responseBlob) => {
          FileSaver.saveAs(
            new File([responseBlob], `${filename}` || `${fileNameFromServer}`, {
              type: contentType,
            })
          );
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsError(true);
          return Promise.reject(error);
        });
    },
    [idToken]
  );

  return { download, isError, isLoading };
};
