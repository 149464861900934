import React from 'react';


import { cn } from 'utils/styles';

import { TableRow } from './TableRow';
import { getWarning } from './utils';

export interface TableBodyProps {
  children?: JSX.Element | JSX.Element[];
  className?: string;
  'data-testid'?: string;
}

export const TableBody: React.FC<TableBodyProps> = ({
  children,
  className,
  'data-testid': dataTestId,
}) => {
  return (
    <tbody className={cn('group', className)} data-testid={dataTestId}>
      {React.Children.map(children, (child) => {
        if (child?.type !== TableRow) {
          const warning = getWarning('TableBody', 'TableRow');
          console.error(warning);
        }
        return child;
      })}
    </tbody>
  );
};
