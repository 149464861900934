import React from 'react';

import { useTranslation } from 'react-i18next';
import { printMoney } from 'utils';
import { drawdownRequestResultAtom } from 'modules/DrawdownRequest/models/result';
import { useAtomValue } from 'jotai/react';

interface FiguresProps {}

export const Figures: React.FC<FiguresProps> = (props) => {
  const { t } = useTranslation();
  const result = useAtomValue(drawdownRequestResultAtom);
  return (
    <div className="py-6 px-8 flex flex-col gap-2 border border-indigo-100 bg-indigo-50/50 rounded-lg w-full my-8 font-semibold">
      <div className="flex">
        <div className="grow">{t('drawdownRequest.newAvailability')}</div>
        <div className="text-xl tracking-wide">
          {printMoney(result?.afterLoanInfo?.loanInfo?.availability)}
        </div>
      </div>
      <div className="flex">
        <div className="grow">{t('drawdownRequest.newOutstandingBalance')}</div>
        <div className="text-xl tracking-wide">
          {printMoney(result?.afterLoanInfo?.loanInfo?.outstandingBalance)}
        </div>
      </div>
    </div>
  );
};
