import React from 'react';
import { useOutletContext } from 'react-router';

import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import {
  Ellipsis,
  LoadingOver,
  ReactTable,
  SearchQueryParams,
  TableSkeleton,
  useLimitedPagination,
  useSearchQueryParams,
  useSorting,
} from 'components';
import {
  ActivityAuditType,
  ReleaseRequestSearchQueryVariables,
  ReleaseRequestSearchResultItem,
  ReleaseRequestSortableFieldEnum,
  useReleaseRequestSearchQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import { useDownloadPdf } from 'services';
import { printDate, printMoney, printPrimitive } from 'utils';

import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePageTitle } from 'modules/root/Settings';
import { notEmpty } from 'utils/helpers';

import { CollateralReleaseActions } from './CollateralReleaseActions';

const DEFAULT_PAGE_SIZE = 10;
const QUERY_LIMIT = 10000;

interface CollateralReleaseTableProps {}

const useReleaseRequestQuery = (queryParams: SearchQueryParams) => {
  const [currentItems, setCurrentItems] = React.useState<
    null | ReleaseRequestSearchResultItem[]
  >(null);
  const [total, setTotal] = React.useState(0);
  const { data, error, isLoading, refetch, isFetched, isFetching } = useQueryFetch(
    useReleaseRequestSearchQuery,
    {
      queryHookParams: queryParams as ReleaseRequestSearchQueryVariables,
      queryHookOptions: { enabled: false },
      extra: {
        auditReport: {
          activityType: queryParams.searchText
            ? ActivityAuditType.Search
            : ActivityAuditType.Read,
        },
      },
    }
  );

  React.useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch, queryParams]);

  React.useEffect(() => {
    if (isFetched && data && !error) {
      const items = data?.releaseRequestSearch?.items?.filter(notEmpty) || [];
      setCurrentItems(items);
      setTotal(data?.releaseRequestSearch?.totalHits);
    }
  }, [data, error, isFetched, queryParams.searchText]);

  return {
    total,
    items: currentItems,
    isLoading: currentItems === null && isLoading,
    isUpdating: isFetching,
    error,
  };
};

export const CollateralReleaseTable: React.FC<CollateralReleaseTableProps> = (props) => {
  const { t } = useTranslation();
  const { download } = useDownloadPdf();
  const { labels, releaseRequestList } = useCustomisation();
  const columnHelper = createColumnHelper<ReleaseRequestSearchResultItem>();
  usePageTitle(t('releaseRequest.collateralRelease'));
  const { searchValue } = useOutletContext<{ searchValue: string }>();

  const { handleSortChange, sorting } = useSorting();
  const { handlePageChange, isLimitReached, pagination } = useLimitedPagination(
    DEFAULT_PAGE_SIZE,
    QUERY_LIMIT
  );
  const queryParams = useSearchQueryParams({
    pagination,
    searchText: searchValue,
    sorting,
  });

  const columns = React.useMemo(() => {
    const headerCellsFromConfig = [];
    if (releaseRequestList.showFields.customerName) {
      headerCellsFromConfig.push(
        columnHelper.accessor('customerName', {
          header: labels.customer,
          id: ReleaseRequestSortableFieldEnum.CustomerNamePlain,
          cell: ({ getValue }) => (
            <Ellipsis data-testid="customer-name" className="font-semibold max-w-[150px]">
              {printPrimitive(getValue())}
            </Ellipsis>
          ),
        })
      );
    }
    if (releaseRequestList.showFields.externalId) {
      headerCellsFromConfig.push(
        columnHelper.accessor('externalId', {
          header: labels.id,
          id: ReleaseRequestSortableFieldEnum.ExternalId,
          cell: ({ getValue }) => <Ellipsis>{getValue()}</Ellipsis>,
        })
      );
    }
    if (releaseRequestList.showFields.lastUpdateDate) {
      headerCellsFromConfig.push(
        columnHelper.accessor('lastUpdateDate', {
          header: labels.date,
          id: ReleaseRequestSortableFieldEnum.LastUpdateDate,
          cell: ({ getValue }) => <>{printDate(getValue())}</>,
        })
      );
    }
    if (releaseRequestList.showFields.releaseAmount) {
      headerCellsFromConfig.push(
        columnHelper.accessor('releaseAmount', {
          header: labels.releaseAmount,
          id: ReleaseRequestSortableFieldEnum.ReleaseAmount,
          cell: ({ getValue }) => <>{printMoney(getValue())}</>,
        })
      );
    }
    if (
      releaseRequestList.showFields.actions.view ||
      releaseRequestList.showFields.actions.downloadPdf
    ) {
      headerCellsFromConfig.push(
        columnHelper.accessor('id', {
          header: labels.actions,
          cell: ({ row }) => (
            <CollateralReleaseActions
              releaseRequest={row.original}
              downloadPdf={download}
            />
          ),
          enableSorting: false,
        })
      );
    }
    return headerCellsFromConfig;
  }, [labels, columnHelper, releaseRequestList, download]);

  const { items, error, isLoading, isUpdating, total } =
    useReleaseRequestQuery(queryParams);

  React.useEffect(() => {
    handlePageChange({ pageSize: DEFAULT_PAGE_SIZE, pageIndex: 0 });
  }, [handlePageChange, searchValue]);

  const sortingMemo = React.useMemo(
    () => ({
      onChange: handleSortChange,
      state: sorting,
    }),
    [sorting, handleSortChange]
  );

  const paginationMemo = React.useMemo(
    () => ({
      onChange: handlePageChange,
      state: pagination,
      total,
    }),
    [handlePageChange, pagination, total]
  );

  return (
    <>
      <QuerySuspense
        error={error}
        isLoading={isLoading}
        noData={Boolean(!items?.length && !isLoading && !isUpdating)}
        noDataMessage={searchValue ? t('common.searchBox.noRecords') : undefined}
        loadingRenderer={() => <TableSkeleton />}
      >
        {searchValue && total > QUERY_LIMIT && (
          <div className={clsx('warning', 'mt-4')}>
            {t('common.searchBox.manyRecords')}
          </div>
        )}
        {items && (
          <LoadingOver show={isUpdating}>
            <ReactTable<ReleaseRequestSearchResultItem>
              data={items}
              columns={columns}
              data-testid="collateral-release-table"
              stickyFromTopPx={90}
              sorting={sortingMemo}
              pagination={paginationMemo}
            />
          </LoadingOver>
        )}
      </QuerySuspense>
      {isLimitReached && (
        <div className={clsx('float-right', 'alert', 'mt-4')}>
          {t('common.pagination.limitRecords')}
        </div>
      )}
    </>
  );
};
