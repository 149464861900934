import { HoldingType, ReleaseRequestInput } from 'generated/graphql';
import { SetStateAction, atom } from 'jotai';

import { StepIds, stepsAtom } from './step';

// This object maps assetId to accountIndex-assetIndex.
export type AssetIndexer = Record<string, { assetIndex: number; accountIndex: number }>;

export type SelectedAsset = Record<
  string,
  {
    accountId: string;
    type: HoldingType;
    unit: number;
    amount: number;
    releaseAll: boolean;
    securityId: string;
  }
>;

type ReleaseRequestInputAtom = Omit<ReleaseRequestInput, 'persist'>;

export type SelectedAccount = Record<
  string,
  { selectedAssets: number; allAssets: number }
>;

export const selectedAssetsAtom = atom<
  SelectedAsset,
  [SetStateAction<SelectedAsset>],
  void
>({}, (get, set, update: SetStateAction<SelectedAsset>) => {
  const steps = get(stepsAtom);
  const selectedAssets = get(selectedAssetsAtom);
  const updatedAssets = typeof update === 'function' ? update(selectedAssets) : update;
  const totalMV = Object.values(updatedAssets).reduce(
    (acc, asset) => acc + asset.amount,
    0
  );
  const isValid = totalMV > 0;
  const index = steps.findIndex((step) => step.id === StepIds.Request);
  const updatedSteps = steps.map((step, i) =>
    i === index ? { ...step, valid: isValid } : step
  );
  set(stepsAtom, updatedSteps);
  set(selectedAssetsAtom, updatedAssets);
  set(hasSelectedAssetsChangedAtom, true);
});

export const hasSelectedAssetsChangedAtom = atom<boolean>(false);

// Tracks number of assets selected for each account
export const selectedAccountsAtom = atom<SelectedAccount>({});

export const assetIndexerAtom = atom<AssetIndexer>({});

export const releaseRequestInputAtom = atom<ReleaseRequestInputAtom>((get) => {
  const selectedAssets = get(selectedAssetsAtom);

  return {
    retrieveCreditPolicy: false,
    holdings: Object.entries(selectedAssets).map(([assetId, account]) => {
      let amount = null;
      let type = null;
      if (!account.releaseAll) {
        amount = account.type === HoldingType.Quantity ? account.unit : account.amount;
        type = account.type;
      }
      return {
        accountId: account.accountId,
        releaseAll: account.releaseAll,
        amount,
        type,
        assetId: account.securityId, // need to send securityId in the name of assetId
      };
    }),
  };
});

export const totalMarketValueAtom = atom((get) => {
  const assets = get(selectedAssetsAtom);
  return Object.values(assets).reduce((acc, asset) => acc + asset.amount, 0);
});
