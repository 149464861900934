import React from 'react';
import { useParams } from 'react-router';

import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { usePaths } from 'modules/root/Settings';

import { breadcrumbsAtom } from '../models/breadcrumb';
import { StepIds, stepsAtom } from '../models/step';

const useInitSteps = () => {
  const [, setSteps] = useAtom(stepsAtom);
  const { t } = useTranslation();
  const { customerId } = useParams();
  React.useEffect(() => {
    const isFirstStep = !customerId;
    const initialSteps = [
      {
        id: StepIds.Customer,
        label: t('drawdownRequest.customer'),
        enable: true,
        active: isFirstStep,
        valid: !isFirstStep,
      },
      {
        id: StepIds.Amount,
        label: t('drawdownRequest.amount'),
        enable: !isFirstStep,
        active: !isFirstStep,
        valid: false,
      },
      {
        id: StepIds.Results,
        label: t('drawdownRequest.results'),
        enable: false,
        active: false,
        valid: false,
      },
      {
        id: StepIds.Details,
        label: t('drawdownRequest.details'),
        enable: false,
        active: false,
        valid: false,
      },
    ];

    setSteps(initialSteps);
  }, [customerId, setSteps, t]);
};

const useInitBreadcrumbs = () => {
  const { t } = useTranslation();
  const setBreadcrumbs = useSetAtom(breadcrumbsAtom);
  const paths = usePaths();

  React.useEffect(() => {
    setBreadcrumbs([
      {
        label: t('home.label'),
        link: paths.home,
      },
      {
        label: t('drawdownRequest.servicing'),
        link: paths.servicing.list,
      },
      {
        label: t('drawdownRequest.new'),
        link: paths.drawdown.new,
      },
    ]);
  }, [paths, setBreadcrumbs, t]);
};

export const DrawdownRequestSettings: React.FC = (props) => {
  useInitSteps();
  useInitBreadcrumbs();
  return null;
};
