import { DrawdownRequestAmountQuery } from 'generated/graphql';
import { atom } from 'jotai';
import { ArrayElement } from 'utils';

import { StepIds, stepsAtom } from './step';

type LoanType = ArrayElement<DrawdownRequestAmountQuery['customer']['loans']>;

export const availabilityAtom = atom(0);
export const loanAtom = atom<LoanType>(null);

export const amountAtom = atom<number | null, [number], void>(
  null,
  (get, set, update: number) => {
    // enable next step after Amount
    set(stepsAtom, (steps) => {
      const index = steps.findIndex((step) => step.id === StepIds.Amount);
      const isValid = update > 0 && update <= get(availabilityAtom);
      const updatedSteps = steps.map((step, i) => {
        if (i === index) {
          return { ...step, valid: isValid };
        } else if (i === index + 1) {
          return { ...step, enable: isValid };
        }
        return step;
      });
      return updatedSteps;
    });

    set(amountAtom, update);
  }
);
