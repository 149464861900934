import React from 'react';

import { HelpIcon, Notification, TextInput } from 'components';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import {
  filterDecimal,
  formatDecimalWithCommas,
  getNumberValue,
  printMoney,
} from 'utils';

import {
  amountAtom,
  availabilityAtom,
  loanAtom,
} from 'modules/DrawdownRequest/models/amount';
import { isCustomerEligibleAtom } from 'modules/DrawdownRequest/models/customer';
import { useCustomisation } from 'modules/root/Settings';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

interface AmountFieldProps {}

export const AmountField: React.FC<AmountFieldProps> = (props) => {
  const { t } = useTranslation();
  const availability = useAtomValue(availabilityAtom);
  const loan = useAtomValue(loanAtom);
  const [amount, setAmount] = useAtom(amountAtom);
  const { helpText } = useCustomisation();
  const isCustomerEligible = useAtomValue(isCustomerEligibleAtom);

  const handleSetAmount = React.useCallback(() => {
    setAmount(availability);
  }, [availability, setAmount]);

  const handleChange = React.useCallback(
    (val: string) => {
      setAmount(getNumberValue(val));
    },
    [setAmount]
  );

  const exceedErrorMsg =
    loan && amount && availability < amount
      ? t('drawdownRequest.messages.exceedAvailability', {
          loanNum: `${loan.obligor} - ${loan.obligation}`,
        })
      : undefined;

  return (
    <div>
      <TextInput
        data-testid="drawdown-amount-field"
        label={t('drawdownRequest.amountFieldLabel')}
        suffixText={t('drawdownRequest.amountFieldSuffixText')}
        onSuffixClick={handleSetAmount}
        text={amount?.toString()}
        formatFn={formatDecimalWithCommas}
        filterFn={filterDecimal}
        onChange={handleChange}
        disabled={availability === 0 || !isCustomerEligible}
        errorMessage={exceedErrorMsg}
        prefixIcon={faDollarSign}
      />
      {loan &&
        (availability > 0 ? (
          <div className="flex gap-1 items-center" data-testid="availability-info-label">
            {t('drawdownRequest.messages.availableToDrawdown')}
            <HelpIcon text={helpText.availabilityDrawdown} />
            <span className="font-bold tracking-wider ml-2">
              {printMoney(availability)}
            </span>
          </div>
        ) : (
          <Notification type="warning" data-testid="not-available-message">
            {t('drawdownRequest.messages.zeroAvailability', {
              loanNum: `${loan.obligor} - ${loan.obligation}`,
            })}
          </Notification>
        ))}
    </div>
  );
};
