import { atom } from 'jotai';

type Status = 'pre' | 'progress' | 'success' | 'error' | 'saved';
type Persistance = 'persist' | 'non-persist';

export const persistAtom = atom(false);

export const drawdownRequestStatusAtom = atom<Status, [Status, Persistance?], void>(
  'pre',
  (_get, set, update, saveFlag) => {
    if (update === 'progress') {
      set(persistAtom, saveFlag === 'persist');
    }
    set(drawdownRequestStatusAtom, update, saveFlag);
  }
);
