import React from 'react';

import clsx from 'clsx';

interface SelectableStateListProps {
  states: string[];
  onChange: (index: number) => void;
  className?: string;
  'data-testid'?: string;
}

export const SelectableStateList: React.FC<SelectableStateListProps> = ({
  states,
  onChange,
}) => {
  const [selected, setSelected] = React.useState(0);
  const getHandleChange = (index: number) => () => {
    setSelected(index);
    onChange(index);
  };
  return (
    <div className="flex gap-1 flex-nowrap">
      {states.map((state, index) => (
        <div
          role="radio"
          aria-checked={selected === index}
          key={index}
          className={clsx('px-2 py-1', {
            'bg-gray-200/60 text-gray-600 rounded-md': selected === index,
            'cursor-pointer hover:bg-gray-50 rounded-md font-normal': selected !== index,
          })}
          onClick={getHandleChange(index)}
        >
          {state}
        </div>
      ))}
    </div>
  );
};
