import React from 'react';

import { Button } from 'components';
import {
  ActivityAuditType,
  CustomerActivitiesByTypeQuery,
  CustomerActivityType,
  DataBarrierFunctionality,
  useCustomerActivitiesByTypeQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import { ResourceAccessed, useSendAuditReport } from 'services/auditReport';
import { ArrayElement } from 'utils';

import { DashboardList } from 'modules/Home/components/DashboardList';
import { ActivityBadge } from 'modules/common/ActivityBadge/ActivityBadge';
import { LoanIcon } from 'modules/common/LoanIcon/LoanIcon';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation } from 'modules/root/Settings';
import { handleViewInformApplication } from 'utils/inform';

import { ActivitySection } from './ActivitySection';

interface ApplicationSectionProps {
  customerId: string;
  customerExternalId: string;
}

type ActivityList = Exclude<
  CustomerActivitiesByTypeQuery['customerActivitiesByType'],
  null | undefined
>['activities'];

export const ApplicationSection: React.FC<ApplicationSectionProps> = ({
  customerId,
  customerExternalId,
}) => {
  const { labels } = useCustomisation();
  const { t } = useTranslation();

  const { data, isLoading, error } = useQueryFetch(useCustomerActivitiesByTypeQuery, {
    queryHookParams: {
      customerId: customerId,
      activityType: CustomerActivityType.LoanApplication,
      dataBarrierFunctionality: DataBarrierFunctionality.Home,
    },
  });
  const sendAudit = useSendAuditReport();
  const getHandlerApplicationView = (activity: ArrayElement<ActivityList>) => () => {
    sendAudit({
      activityType: ActivityAuditType.Read,
      customerExternalId,
      resourceAccessed: ResourceAccessed.CustomerActivityViewApplication,
      queryDetails: JSON.stringify({ formBundleId: activity?.referenceId }),
    });
    handleViewInformApplication(activity?.referenceId);
  };

  return (
    <ActivitySection
      label={labels.applications}
      items={data?.customerActivitiesByType?.activities || []}
    >
      {(items: ActivityList) => (
        <QuerySuspense
          error={error}
          isLoading={isLoading}
          noData={data?.customerActivitiesByType === null}
          noDataMessage={t('dashboard.activities.modal.noApplications')}
        >
          <DashboardList>
            {items.map((activity) => (
              <DashboardList.Item key={activity?.id}>
                <div
                  className="flex justify-between"
                  data-testid="application-section-item"
                >
                  <div className="flex items-center">
                    <LoanIcon />
                    <span className="ml-[14px] mr-2">
                      {t('dashboard.activities.application')}
                    </span>
                    <span>{`#${activity?.referenceId.split('-')[0]}`}</span>
                    <span className="ml-2">
                      <ActivityBadge status={activity ? activity.description : ''} />
                    </span>
                  </div>
                  <Button
                    data-testid="application-btn"
                    state="secondary"
                    size="sm"
                    onClick={getHandlerApplicationView(activity)}
                  >
                    {t('dashboard.activities.view')}
                  </Button>
                </div>
              </DashboardList.Item>
            ))}
          </DashboardList>
        </QuerySuspense>
      )}
    </ActivitySection>
  );
};
