import React from 'react';

import {
  LoadingOver,
  PickDateButton,
  TextInput,
  TextInputOnBlur,
  notification,
} from 'components';
import { isPast, isToday } from 'date-fns';
import { ActivityAuditType, useRunDrawdownRequestMutation } from 'generated/graphql';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useQueryMutation } from 'queries/apiFetch/useQueryMutation';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT, filterInteger, formatDate, printMoney } from 'utils';

import { amountAtom } from 'modules/DrawdownRequest/models/amount';
import { selCustomerAtom } from 'modules/DrawdownRequest/models/customer';
import { detailsAtom } from 'modules/DrawdownRequest/models/details';
import { drawdownRequestInputAtom } from 'modules/DrawdownRequest/models/input';
import { drawdownRequestResultAtom } from 'modules/DrawdownRequest/models/result';
import { drawdownRequestStatusAtom } from 'modules/DrawdownRequest/models/status';
import { GraphqlQueryError } from 'modules/common/errors/GraphqlQueryError';

interface DetailsStepProps {}

export const DetailsStep: React.FC<DetailsStepProps> = (props) => {
  const { t } = useTranslation();
  const calendarProps = React.useMemo(() => {
    return { disabled: (date: Date) => isPast(date) && !isToday(date) };
  }, []);

  const amount = useAtomValue(amountAtom);
  const [details, setDetails] = useAtom(detailsAtom);
  const selectedCustomer = useAtomValue(selCustomerAtom);
  const [status, setStatus] = useAtom(drawdownRequestStatusAtom);
  const drawdownRequestInput = useAtomValue(drawdownRequestInputAtom);
  const setResult = useSetAtom(drawdownRequestResultAtom);

  const { mutateAsync, isLoading } = useQueryMutation(useRunDrawdownRequestMutation, {
    extra: {
      auditReport: {
        activityType: ActivityAuditType.Write,
        customerExternalId: selectedCustomer?.customerId,
      },
    },
  });

  React.useEffect(() => {
    if (status === 'progress') {
      mutateAsync({
        request: {
          ...drawdownRequestInput,
        },
      })
        .then((data) => {
          notification.success(t('drawdownRequest.messages.success'));
          setResult(data.runDrawdownRequest);
          setStatus('saved');
        })
        .catch((error: GraphqlQueryError) => {
          notification.error(
            error?.response?.errors?.[0]?.message ?? t('common.error.unspecific')
          );
          setStatus('error');
        });
    }
  }, [mutateAsync, drawdownRequestInput, setStatus, status, t, setResult]);

  const handleChange = React.useCallback<TextInputOnBlur>(
    (event) => {
      if (event.target.name === 'routingNumber') {
        setDetails({
          ...details,
          routingNumber: event.target.value ? parseInt(event.target.value, 10) : undefined,
        });
      } else {
        setDetails({ ...details, [event.target.name]: event.target.value });
      }
    },
    [details, setDetails]
  );

  const handleDateSelect = React.useCallback(
    (date: Date | undefined) => {
      setDetails({
        ...details,
        drawDownDate: date ? formatDate(date.toUTCString(), DATE_FORMAT) : undefined,
      });
    },
    [details, setDetails]
  );

  return (
    <LoadingOver show={isLoading}>
      <h2 className="text-lg font-bold mb-8">{t('drawdownRequest.details')}</h2>
      <div className="flex flex-col gap-8">
        <div className="py-9 px-8 flex border border-indigo-100 bg-indigo-50/50 rounded-lg w-full font-semibold">
          <div className="grow">{t('drawdownRequest.detailsField.amount')}</div>
          <div className="tracking-wider text-xl">{printMoney(amount)}</div>
        </div>
        <div>
          <PickDateButton
            label={t('drawdownRequest.detailsField.date')}
            calendar={calendarProps}
            onChange={handleDateSelect}
          />
        </div>
        <div>
          <TextInput
            name="preferredPaymentMethod"
            label={t('drawdownRequest.detailsField.method')}
            maxLength={255}
            onBlur={handleChange}
            text={details.preferredPaymentMethod || ''}
          />
        </div>
        <div>
          <TextInput
            name="routingNumber"
            label={t('drawdownRequest.detailsField.routingNum')}
            maxLength={9}
            onBlur={handleChange}
            text={details.routingNumber?.toString() || ''}
            filterFn={filterInteger}
          />
        </div>
        <div>
          <TextInput
            name="accountNumber"
            label={t('drawdownRequest.detailsField.accountNum')}
            maxLength={50}
            onBlur={handleChange}
            text={details.accountNumber || ''}
          />
        </div>
      </div>
    </LoadingOver>
  );
};
