import React from 'react';

import { Content } from 'components';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { usePageTitle } from 'modules/root/Settings';

import { ReleaseRequestCalcuation } from './Calculation/Calculation';
import { ControlPanel } from './ControlPanel/ControlPanel';
import { LeaveBeforeSaving } from './LeaveBeforeSaving';
import { ReleaseRequestBreadcrumb } from './ReleaseRequestBreadcrumb/ReleaseRequestBreadcrumb';
import { ReleaseRequestSettings } from './ReleaseRequestSettings/ReleaseRequestSettings';
import { ReleaseRequestStep } from './ReleaseRequestStep/ReleaseRequestStep';
import { ReleaseRequestStepper } from './ReleaseRequestStep/ReleaseRequestStepper';
import { releaseRequestStatusAtom } from './models/status';

interface ReleaseRequestLayoutProps {}

export const ReleaseRequestLayout: React.FC<ReleaseRequestLayoutProps> = (props) => {
  const status = useAtomValue(releaseRequestStatusAtom);
  const { t } = useTranslation();
  usePageTitle(t('releaseRequest.title'));
  return (
    <div className="grid grid-rows-[min-content_1fr] h-full relative">
      <ReleaseRequestSettings />
      <div className="sticky top-0 bg-white/70 backdrop-blur-sm flex flex-col w-full z-10">
        <ReleaseRequestBreadcrumb />
      </div>
      <Content>
        {status === 'pre' ? (
          <>
            <ReleaseRequestStepper />
            <div className="flex flex-col max-w-[625px] mx-auto">
              <ReleaseRequestStep />
            </div>
          </>
        ) : (
          <div className="flex flex-col h-full max-w-[calc(100vw-103px)] 2xl:max-w-[1356px]">
            <ReleaseRequestCalcuation />
          </div>
        )}
      </Content>
      <div className="sticky bottom-0 bg-white/70 backdrop-blur-sm w-full h-[72px] border-t border-t-gray-200">
        <ControlPanel />
      </div>
      <LeaveBeforeSaving />
    </div>
  );
};
