import { LoanStatus } from 'generated/graphql';
import { atom } from 'jotai';

import { CustomerSelection } from 'modules/common';
import { INELIGIBLE_STATUSES } from 'modules/common/constants/status';

import { StepIds, stepsAtom } from './step';

type CustomerAtom = CustomerSelection | null;

export const isCustomerEligibleAtom = atom<boolean>(false);

export const selCustomerAtom = atom<CustomerAtom, [CustomerAtom], void>(
  null,
  (get, set, update: CustomerAtom) => {
    if (update) {
      const current = get(selCustomerAtom);
      if (current && current.id === update.id) {
        return;
      }

      // enable next step after Customer selection
      set(stepsAtom, (steps) => {
        const index = steps.findIndex((step) => step.id === StepIds.Customer);
        const updatedSteps = steps.map((step, i) => {
          if (i === index) {
            return { ...step, valid: true };
          } else if (i === index + 1) {
            return { ...step, enable: true };
          }
          return step;
        });
        return updatedSteps;
      });

      const isEligible =
        !!update.serviceStatus &&
        ![...INELIGIBLE_STATUSES, LoanStatus.Prospect].includes(update.serviceStatus);

      set(isCustomerEligibleAtom, isEligible);
    }
    set(selCustomerAtom, update);
  }
);
