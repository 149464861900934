import React from 'react';
import { generatePath, useNavigate } from 'react-router';

import {
  PaginationState,
  Row,
  SortingState,
  createColumnHelper,
} from '@tanstack/react-table';
import { Ellipsis, PageChangeFn, ReactTable, SortChangeFn } from 'components';
import { LoanStatus, SortableFieldEnum } from 'generated/graphql';

import { StatusBadge } from 'modules/common';
import { useCustomisation, usePaths } from 'modules/root/Settings';
import { EMPTY_DASH, printMoney, printPercent, printPrimitive } from 'utils/print';

import { CustomerListItem } from './Customers';

interface CustomersTableProps {
  customers: CustomerListItem[];
  total: number;
  pagination: PaginationState;
  onPageChange: PageChangeFn;
  sorting: SortingState;
  onSortChange: SortChangeFn;
}

export const CustomersTable: React.FC<CustomersTableProps> = ({
  customers,
  total,
  pagination,
  onPageChange,
  sorting,
  onSortChange,
}) => {
  const columnHelper = createColumnHelper<CustomerListItem>();
  const { labels, customerList } = useCustomisation();

  const columns = React.useMemo(() => {
    let headerCellsFromConfig = [];
    if (customerList.customer.show) {
      headerCellsFromConfig.push(
        columnHelper.accessor('displayName', {
          header: labels.customer,
          id: SortableFieldEnum.DisplayNamePlain,
          cell: ({ getValue }) => (
            <Ellipsis
              data-testid="customer-name"
              className="font-bold"
              style={{ width: customerList.customer.width }}
            >
              {printPrimitive(getValue())}
            </Ellipsis>
          ),
        })
      );
    }
    if (customerList.customerId.show) {
      headerCellsFromConfig.push(
        columnHelper.accessor('customerId', {
          header: labels.customersTableId,
          id: SortableFieldEnum.CustomerId,
          cell: ({ getValue }) => (
            <Ellipsis
              data-testid="customer-id"
              style={{ width: customerList.customerId.width }}
            >
              {printPrimitive(getValue())}
            </Ellipsis>
          ),
        })
      );
    }
    if (customerList.email.show) {
      headerCellsFromConfig.push(
        columnHelper.accessor('email', {
          header: labels.email,
          id: SortableFieldEnum.EmailPlain,
          cell: ({ getValue }) => (
            <Ellipsis data-testid="email" style={{ width: customerList.email.width }}>
              {printPrimitive(getValue())}
            </Ellipsis>
          ),
        })
      );
    }
    if (customerList.phone.show) {
      headerCellsFromConfig.push(
        columnHelper.accessor('phone', {
          header: labels.phone,
          id: SortableFieldEnum.PhonePlain,
          cell: ({ getValue }) => (
            <Ellipsis data-testid="phone" style={{ width: customerList.phone.width }}>
              {printPrimitive(getValue())}
            </Ellipsis>
          ),
        })
      );
    }
    if (customerList.totalCollateralValue.show) {
      headerCellsFromConfig.push(
        columnHelper.accessor('totalCollateralValue', {
          id: SortableFieldEnum.TotalCollateralValue,
          header: () => (
            <div style={{ width: customerList.totalCollateralValue.width }}>
              {labels.totalCollateralValue}
            </div>
          ),
          cell: ({ getValue, row }) => (
            <div data-testid="collateral-value">
              {row.original.serviceStatus === LoanStatus.DataIssue
                ? EMPTY_DASH
                : printMoney(getValue())}
            </div>
          ),
        })
      );
    }
    if (customerList.totalMarketValue.show) {
      headerCellsFromConfig.push(
        columnHelper.accessor('totalMarketValue', {
          id: SortableFieldEnum.TotalMarketValue,
          header: () => (
            <div style={{ width: customerList.totalMarketValue.width }}>
              {labels.totalMarketValue}
            </div>
          ),
          cell: ({ getValue, row }) => (
            <div data-testid="market-value">
              {row.original.serviceStatus === LoanStatus.DataIssue
                ? EMPTY_DASH
                : printMoney(getValue())}
            </div>
          ),
        })
      );
    }
    if (customerList.numberOfLoans.show) {
      headerCellsFromConfig.push(
        columnHelper.accessor('numberOfLoans', {
          id: SortableFieldEnum.NumberOfLoans,
          header: () => (
            <div style={{ width: customerList.numberOfLoans.width }}>
              {labels.numberOfLoans}
            </div>
          ),
          cell: ({ getValue }) => (
            <div data-testid="number-of-loans">{printPrimitive(getValue())}</div>
          ),
        })
      );
    }
    if (customerList.totalCommitmentAmount.show) {
      headerCellsFromConfig.push(
        columnHelper.accessor('totalCommitments', {
          id: SortableFieldEnum.TotalCommitments,
          header: () => (
            <div style={{ width: customerList.totalCommitmentAmount.width }}>
              {labels.totalCommitment}
            </div>
          ),
          cell: ({ getValue, row }) => (
            <div data-testid="commitment-amount">
              {row.original.serviceStatus === LoanStatus.DataIssue
                ? EMPTY_DASH
                : printMoney(getValue())}
            </div>
          ),
        })
      );
    }
    if (customerList.totalOutstandingBalance.show) {
      headerCellsFromConfig.push(
        columnHelper.accessor('totalOutstandingBalance', {
          id: SortableFieldEnum.TotalOutstandingBalance,
          header: () => (
            <div style={{ width: customerList.totalOutstandingBalance.width }}>
              {labels.totalOutstandingBalance}
            </div>
          ),
          cell: ({ getValue, row }) => (
            <div data-testid="outstanding-balance">
              {row.original.serviceStatus === LoanStatus.DataIssue
                ? EMPTY_DASH
                : printMoney(getValue())}
            </div>
          ),
        })
      );
    }
    if (customerList.interestRate.show) {
      headerCellsFromConfig.push(
        columnHelper.accessor('interestRate', {
          id: SortableFieldEnum.InterestRate,
          header: () => (
            <div style={{ width: customerList.interestRate.width }}>
              {labels.interestRates}
            </div>
          ),
          cell: ({ getValue, row }) => (
            <div data-testid="weighted-interest-rate">
              {row.original.serviceStatus === LoanStatus.DataIssue
                ? EMPTY_DASH
                : printPercent(getValue())}
            </div>
          ),
        })
      );
    }
    if (customerList.availability.show) {
      headerCellsFromConfig.push(
        columnHelper.accessor('availability', {
          id: SortableFieldEnum.Availability,
          header: () => (
            <div style={{ width: customerList.availability.width }}>
              {labels.availability}
            </div>
          ),
          cell: ({ getValue, row }) => (
            <div data-testid="availability">
              {row.original.serviceStatus === LoanStatus.DataIssue
                ? EMPTY_DASH
                : printMoney(getValue())}
            </div>
          ),
        })
      );
    }
    if (customerList.serviceStatus.show) {
      headerCellsFromConfig.push(
        columnHelper.accessor('serviceStatusLabel', {
          id: SortableFieldEnum.ServiceStatusPriority,
          header: () => (
            <div style={{ width: customerList.serviceStatus.width }}>
              {labels.serviceStatus}
            </div>
          ),
          cell: ({ getValue, row }) => {
            const value = getValue();
            return value ? (
              <StatusBadge status={row.original.serviceStatus!} label={value} />
            ) : (
              <div>{EMPTY_DASH}</div>
            );
          },
        })
      );
    }
    return headerCellsFromConfig;
  }, [labels, customerList, columnHelper]);

  const navigate = useNavigate();
  const paths = usePaths();

  const handleClick = React.useCallback(
    (row: Row<CustomerListItem>) => {
      const path = generatePath(paths.customer.main, { customerId: row.original.id });
      navigate(path);
    },
    [navigate, paths.customer.main]
  );

  const paginationMemo = React.useMemo(
    () => ({
      onChange: onPageChange,
      state: pagination,
      total,
    }),
    [onPageChange, pagination, total]
  );

  const sortingMemo = React.useMemo(
    () => ({
      onChange: onSortChange,
      state: sorting,
    }),
    [sorting, onSortChange]
  );

  return (
    <ReactTable
      onClick={handleClick}
      data-testid="customers-table"
      stickyFromTopPx={80}
      data={customers}
      columns={columns}
      pagination={paginationMemo}
      sorting={sortingMemo}
    />
  );
};
