import React from 'react';

import { Ellipsis } from 'components';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { printMoney } from 'utils';

import { totalMarketValueAtom } from '../models/input';

interface TotalMarketValueProps {}

export const TotalMarketValue: React.FC<TotalMarketValueProps> = (props) => {
  const { t } = useTranslation();
  const totalMarketValue = useAtomValue(totalMarketValueAtom);
  return (
    <div className="flex flex-col grow font-bold">
      <Ellipsis>{t('releaseRequest.toBeReleased')}</Ellipsis>
      <div className="tracking-wider">{printMoney(totalMarketValue)}</div>
    </div>
  );
};
