import React from 'react';

import { useIdleTimer } from 'react-idle-timer';

import { IDLE_TIMEOUT_SECS } from 'modules/root/Settings/envVars';

import { useAuth } from '../auth.context';

const DEFAULT_TIMEOUT_MS = 60 * 10 * 1000;
const ACTION_THROTTLE_MS = 5000;

export const LogoutOnIdle: React.FC = React.memo(() => {
  const { logout, isAuthenticated } = useAuth();
  const timeout = IDLE_TIMEOUT_SECS ? IDLE_TIMEOUT_SECS * 1000 : DEFAULT_TIMEOUT_MS;
  const handleOnIdle = (): void => {
    if (isAuthenticated) {
      logout();
    }
  };

  useIdleTimer({
    throttle: ACTION_THROTTLE_MS,
    timeout,
    onIdle: handleOnIdle,
    crossTab: true,
  });
  return <></>;
});
