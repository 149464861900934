const isProd = process.env.NODE_ENV === 'production';
const env = isProd ? window.NAV_CORE_UI_ENVIRIONMENT : process.env;

if (!env.REACT_APP_AUTH0_DOMAIN) {
  throw new Error(
    'REACT_APP_AUTH0_DOMAIN is not provided, please add it to public/env.js and .env file'
  );
}
if (!env.REACT_APP_AUTH0_CLIENT_ID) {
  throw new Error(
    'REACT_APP_AUTH0_CLIENT_ID is not provided, please add it to public/env.js and .env file'
  );
}

export const AUTH0 = {
  DOMAIN: env.REACT_APP_AUTH0_DOMAIN,
  CLIENT_ID: env.REACT_APP_AUTH0_CLIENT_ID,
  AUDIENCE: env.REACT_APP_AUTH0_AUDIENCE,
  ENABLE_IDP_INITIATED_LOGIN:
    env.REACT_APP_AUTH0_ENABLE_IDP_INITIATED_LOGIN?.toString() === 'true',
  RESPONSE_TYPE: env.REACT_APP_AUTH0_RESPONSE_TYPE,
  TOKEN_CLAIM_NAMESPACE: env.REACT_APP_AUTH0_TOKEN_CLAIM_NAMESPACE,
  INFORM_DOMAIN: env.REACT_APP_INFORM_DOMAIN,
};

export const SCHEMA_HOST = env.REACT_APP_SCHEMA_HOST?.replace(/\/$/, '');
export const INFORM_HOST = env.REACT_APP_INFORM_HOST;
export const DEFAULT_CREDITPOLICY = env.REACT_APP_DEFAULT_CREDITPOLICY;
export const INFORM_DOMAIN = env.REACT_APP_INFORM_DOMAIN;
export const IDLE_TIMEOUT_SECS = env.REACT_APP_IDLE_TIMEOUT_SECS
  ? parseInt(env.REACT_APP_IDLE_TIMEOUT_SECS, 10)
  : undefined;
export const IDP_PROVIDER_DASHBOARD_URL = env.REACT_APP_IDP_PROVIDER_DASHBOARD_URL;
