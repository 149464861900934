import React from 'react';
import { generatePath, useNavigate } from 'react-router';

import { Button, Ellipsis, Modal, ModalBackToTitle, NavIcon } from 'components';
import { ActivityAuditType, LoanStatus, useLoanCommentsQuery } from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import { ResourceAccessed, useSendAuditReport } from 'services/auditReport';
import { DATE_FORMAT, DATE_FORMAT_MONTH_NAME, TIME_FORMAT_12H, formatDate } from 'utils';

import calendar from 'assets/icons/calendar.svg';
import info from 'assets/icons/info.svg';

import { StatusBadge } from 'modules/common';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePaths } from 'modules/root/Settings';
import { printMoney } from 'utils/print';

import { AlertToShow } from './Alert';

interface AlertModalProps {
  customerName: string;
  alert: AlertToShow;
  customerId: string;
  customerExternalId: string;
  onBackToAll: () => void;
  onClose: () => void;
}

export const AlertModalTitle: React.FC<AlertModalProps> = ({
  onClose,
  alert,
  customerName,
  customerId,
  onBackToAll,
  customerExternalId,
}) => {
  const { t } = useTranslation();
  const { labels, serviceStatus } = useCustomisation();
  const showFixMessage =
    [LoanStatus.SellOut, LoanStatus.TopUp].includes(alert.status) &&
    Boolean(alert.fixAmount && alert.repaymentFixAmount);
  const navigate = useNavigate();
  const paths = usePaths();

  const sendAudit = useSendAuditReport();
  const navState = React.useMemo(() => ({ highlightMarginAnalysis: true }), []);
  const handleViewAlertDetails = () => {
    const path = generatePath(paths.customer.loans.details, {
      customerId: customerId,
      loanId: alert?.loanStatusId,
    });
    sendAudit({
      activityType: ActivityAuditType.Read,
      customerExternalId,
      resourceAccessed: ResourceAccessed.ViewCustomerAlertDetails,
      queryDetails: JSON.stringify({ loanStatusId: alert.loanStatusId }),
    });
    navigate(path, { state: navState });
  };
  return (
    <div className="bg-gray-50 px-10 py-6">
      <div className="flex justify-between h-14">
        <ModalBackToTitle
          onClose={onClose}
          backTo={{ title: t('dashboard.alerts.allAlerts'), onClick: onBackToAll }}
        />
      </div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-x-2">
            <StatusBadge
              status={alert.status}
              label={serviceStatus[alert.status].label}
            />
            {showFixMessage && (
              <div
                data-testid="alert-modal-fix-amount"
                className="flex gap-x-1 text-gray-500"
              >
                <span className="text-base font-normal">
                  {t('dashboard.alerts.addCollateral')}
                </span>
                <span className="text-base font-normal">
                  {printMoney(alert.fixAmount)}
                </span>
                <span className="text-base font-normal">{t('common.or')}</span>
                <span className="text-base font-normal">
                  {t('dashboard.alerts.paydown')}
                </span>
                <span className="text-base font-normal">
                  {printMoney(alert.repaymentFixAmount)}
                </span>
              </div>
            )}
          </div>
          <div className="flex gap-x-4">
            <Ellipsis className="max-w-[440px]">{customerName}</Ellipsis>
            <span>{'•'}</span>
            <span>{alert.obligorObligation}</span>
          </div>
          <div className="text-sm font-normal flex gap-x-4">
            <span>
              <NavIcon
                source={calendar}
                alt="calendar"
                size="1x"
                className="inline-block mb-1 mr-2 text-red-400 fill-red-200"
              />
              {t('dashboard.alerts.modal.deadline')}
              {': '}
              {alert.deadline ? formatDate(alert.deadline, DATE_FORMAT) : '-'}
            </span>
            <span>
              <NavIcon
                source={info}
                alt="info"
                size="1x"
                className="inline-block mb-1 mr-2 text-red-400 fill-red-200"
              />
              {labels.status}
              {': '}
              {alert.alertStatus}
            </span>
          </div>
        </div>
        <div>
          <Button
            data-testid="see-details"
            state="secondary"
            size="sm"
            onClick={handleViewAlertDetails}
          >
            {t('dashboard.alerts.modal.seeDetails')}
          </Button>
        </div>
      </div>
    </div>
  );
};

interface AlertEntityProps {
  date: string;
  time: string;
  children: React.ReactElement;
}

export const AlertEntity: React.FC<AlertEntityProps> = ({ date, time, children }) => {
  return (
    <div className="border rounded-lg p-5" data-testid="alert-modal-entity">
      <div className="flex justify-between text-gray-600 border-b pb-4 mb-4 text-xs">
        <span>{date}</span>
        <span>{time}</span>
      </div>
      <div>{children}</div>
    </div>
  );
};

export const AlertModal: React.FC<AlertModalProps> = (props) => {
  const { data, isLoading, error } = useQueryFetch(useLoanCommentsQuery, {
    queryHookParams: { loanStatusId: props.alert.loanStatusId },
  });

  return (
    <Modal
      className="w-[750px] lg:w-[850px] xl:w-[1050px]"
      isOpen={true}
      onClose={props.onClose}
      title={<AlertModalTitle {...props} />}
    >
      <div className="h-[500px] px-10 py-6 overflow-y-auto">
        <QuerySuspense
          isLoading={isLoading}
          error={error}
          noData={!data?.loanComments.length}
        >
          <div className="flex flex-col gap-y-3">
            {data?.loanComments.map(
              (comment) =>
                comment && (
                  <AlertEntity
                    date={formatDate(comment.date, DATE_FORMAT_MONTH_NAME)}
                    time={formatDate(comment.date, TIME_FORMAT_12H)}
                    key={comment.date}
                  >
                    <div>
                      <span>{comment.text}</span>
                    </div>
                  </AlertEntity>
                )
            )}
          </div>
        </QuerySuspense>
      </div>
    </Modal>
  );
};
