import {
  CustomersSearchQuery,
  CustomersSearchQueryVariables,
  LoanStatus,
  SearchOrder,
  SortableFieldEnum,
} from 'generated/graphql';
import { atom } from 'jotai';

export type CustomerList = CustomersSearchQuery['customersSearch']['items'];
export type CustomerPage = { items: CustomerList; fromIndex: number };

export type CustomerSelection = {
  id: string;
  customerId: string;
  displayName: string;
  serviceStatus?: LoanStatus | null;
};

export const customersAtom = atom<CustomerPage[]>([]);

export const canShowMoreCustomersAtom = atom<boolean>(false);

const customerParamsAtom = atom<CustomersSearchQueryVariables | null>(null);

export const customerSearchAtom = atom<
  CustomersSearchQueryVariables | null,
  [string, number],
  void
>(
  (get) => get(customerParamsAtom),
  (_get, set, searchText, fromIndex) => {
    set(customerParamsAtom, {
      fromIndex: fromIndex,
      numResults: 5,
      sortBy: { field: SortableFieldEnum.ServiceStatusPriority, order: SearchOrder.Desc },
      searchText: searchText,
    });
  }
);
