import React from 'react';
import { useNavigate } from 'react-router';

import { useAtomValue } from 'jotai';

import { LeaveEvaluationModal } from 'modules/Evaluation/modals/LeaveEvaluationModal';
import { useBlockNavigation } from 'utils/blockNavigation';

import { useDrawdownRequestContext } from '../DrawdownRequestRoot';
import { drawdownRequestStatusAtom } from '../models/status';

export const LeaveBeforeSaving: React.FC = () => {
  const navigate = useNavigate();
  const { resetStore } = useDrawdownRequestContext();
  const [showLeaveModal, setShowLeaveModal] = React.useState(false);
  const handleBlock = React.useCallback(() => setShowLeaveModal(true), []);
  const status = useAtomValue(drawdownRequestStatusAtom);
  const isWorkCanBeLost = status !== 'pre' && status !== 'saved';
  const { unblock } = useBlockNavigation(isWorkCanBeLost, handleBlock);

  const handleLeavePage = (choose: 'cancel' | 'leave') => {
    if (choose === 'leave') {
      unblock().then((blockedPath) => {
        navigate(blockedPath);
        resetStore();
      });
    } else {
      setShowLeaveModal(false);
    }
  };

  return showLeaveModal ? <LeaveEvaluationModal onClose={handleLeavePage} /> : null;
};
