import React, { useState } from 'react';
import { generatePath } from 'react-router';

import clsx from 'clsx';
import { Button, ButtonLink } from 'components';
import { ActivityAuditType } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { ResourceAccessed, useSendAuditReport } from 'services/auditReport';

import { useCustomisation, usePaths } from 'modules/root/Settings';

import { NewEvaluationModal } from './NewEvaluationModal';

export interface NewEvaluationButtonProps {
  className?: string;
  'data-testid'?: string;
}

export const NewEvaluationButton: React.FC<NewEvaluationButtonProps> = ({
  className,
  'data-testid': dataTestId,
}) => {
  const { t } = useTranslation();
  const { featureFlags } = useCustomisation();
  const paths = usePaths();
  const [isOpen, setIsOpen] = useState(false);
  const sendAudit = useSendAuditReport({ sendOnMount: false });
  const handleClick = React.useCallback(() => {
    setIsOpen(true);
    sendAudit({
      activityType: ActivityAuditType.Read,
      resourceAccessed: ResourceAccessed.NewEvaluationButton,
    });
  }, [sendAudit]);

  return (
    <>
      {featureFlags?.evaluationV2 ? (
        <ButtonLink
          state="secondary"
          data-testid={dataTestId}
          className={clsx(className, 'whitespace-nowrap')}
          size="md"
          url={generatePath(paths.evaluationsV2.new)}
          onClick={handleClick}
        >
          {t('evaluations.newEvaluation')}
        </ButtonLink>
      ) : (
        <>
          <Button
            state="secondary"
            data-testid={dataTestId}
            className={clsx(className, 'whitespace-nowrap')}
            size="md"
            onClick={handleClick}
          >
            {t('evaluations.newEvaluation')}
          </Button>
          {isOpen && (
            <NewEvaluationModal
              onClose={() => setIsOpen(false)}
              data-testid={`${dataTestId}-modal`}
            />
          )}
        </>
      )}
    </>
  );
};
