import React from 'react';

import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Button, FontIcon } from 'components';
import { useTranslation } from 'react-i18next';

export interface EvaluationErrorMessageProps {
  rerunEvaluation: () => void;
}

const EvaluationErrorMessage: React.FC<EvaluationErrorMessageProps> = ({
  rerunEvaluation,
}) => {
  const { t } = useTranslation();
  const errorMessage = t('evaluations.summary.processingFailedMSG');
  return (
    <div
      className="mt-40 flex flex-col items-center justify-center h-full"
      data-testid="evaluation-error"
    >
      <h2 className="mb-10 text-xl">{t('common.error.processingFailed')}</h2>
      <div>
        <FontIcon size="3x" className="text-red-500" icon={faCircleExclamation} />
      </div>
      <div className="mt-10 mb-8">{errorMessage}</div>
      <div>
        <Button onClick={rerunEvaluation} data-testid="evaluation-try-again">
          {t('common.tryAgain')}
        </Button>
      </div>
    </div>
  );
};

const EvaluationLoading: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="font-bold text-lg pt-3 pb-3.5">
        {t('evaluationV2.evaluationResults')}
      </div>
      <div
        className="animate-pulse flex flex-col gap-2"
        data-testid="evaluation-processing"
      >
        <div className="flex flex-col gap-3 mb-14">
          <div className="bg-gray-100 w-full h-16 rounded-xl"></div>
          <div className="flex gap-3">
            <div className="rounded-xl bg-gray-100 w-full h-14"></div>
            <div className="rounded-xl bg-gray-100 w-full h-14"></div>
          </div>
        </div>
        <div className="mb-14 h-96 w-full bg-gray-100 rounded-md" />
      </div>
    </>
  );
};

export const QueryHandler = {
  EvaluationErrorMessage,
  EvaluationLoading,
};
