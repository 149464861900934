import React from 'react';

import clsx from 'clsx';
import { Ellipsis } from 'components';

import { CustomerIcon } from 'modules/common/CustomerIcon/CustomerIcon';
import { INELIGIBLE_STATUSES } from 'modules/common/constants/status';
import { useCustomisation } from 'modules/root/Settings';

import { CustomerSelection } from './model';

interface CustomerItemProps {
  customer: CustomerSelection;
  selected?: boolean;
  icon?: React.ReactNode;
  size?: 'sm' | 'md';
  onSelect: (selected: CustomerSelection) => void;
  renderBadge?: (eligible: boolean) => React.ReactElement;
  getEligibitity?: (customer: CustomerSelection) => boolean;
}

export const CustomerItem: React.FC<CustomerItemProps> = ({
  customer,
  selected = false,
  icon,
  size = 'md',
  renderBadge,
  getEligibitity,
  onSelect,
}) => {
  const { labels } = useCustomisation();
  const isEligible =
    typeof getEligibitity === 'undefined'
      ? !(customer.serviceStatus && INELIGIBLE_STATUSES.includes(customer.serviceStatus))
      : getEligibitity(customer);
  return (
    <div
      data-testid="evaluation-customer-item"
      className={clsx('rounded-xl px-5 py-4 cursor-pointer', {
        'border-2 border-primary-600': selected,
        'border-gray-100 border hover:border-primary-200': !selected,
      })}
      role="radio"
      aria-checked={selected}
      onClick={() => onSelect(customer)}
    >
      <div className="flex items-center">
        <CustomerIcon customerName={customer.displayName} />
        <div className="ml-5">
          <div className="flex flex-col">
            <div className="max-w-[380px] font-bold">
              <Ellipsis>{customer.displayName}</Ellipsis>
            </div>
            <div className="flex gap-4 items-center">
              <div
                className={clsx({
                  'max-w-[270px]': size === 'md',
                  'max-w-[105px]': size === 'sm',
                })}
              >
                <Ellipsis>{`${labels.id.toUpperCase()} ${customer.customerId}`}</Ellipsis>
              </div>
              {renderBadge && renderBadge(isEligible)}
            </div>
          </div>
        </div>
        <div className="flex grow justify-end">
          {icon ? (
            <>{icon}</>
          ) : (
            <div
              className={clsx('w-5 h-5 rounded-full p-1', {
                'border border-gray-200': !selected,
                'border-2 border-primary-600': selected,
              })}
            >
              {selected && (
                <div className="bg-primary-600 rounded-full w-full h-full"></div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
