import React from 'react';

import clsx from 'clsx';

interface OnChangeArg {
  name?: string;
  value?: string;
  checked: boolean;
}

export type CheckboxOnChange = (arg: OnChangeArg) => void;

interface CheckboxProps {
  className?: string;
  label?: string;
  name?: string;
  value?: string;
  checked: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  onChange: CheckboxOnChange;
  'data-testid'?: string;
  ariaLabel?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  className,
  name,
  value,
  label,
  onChange,
  checked,
  disabled,
  indeterminate,
  'data-testid': dataTestId,
  ariaLabel,
}) => {
  const [isChecked, setIsChecked] = React.useState(checked);
  React.useEffect(() => {
    if (typeof checked === 'boolean') {
      setIsChecked(checked);
    }
  }, [checked]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ name, value, checked: event.target.checked });
    setIsChecked((prev) => !prev);
  };
  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };
  return (
    <div
      className={clsx(className, 'h-5', {
        checked: isChecked,
        'w-5': !label,
      })}
      data-testid={dataTestId}
      onClick={handleClick}
    >
      <label
        className={clsx(
          { 'pl-7 ': !!label },
          'h-5 block relative select-none cursor-pointer group/checkbox'
        )}
      >
        <span className="absolute text-base -top-0.5">{label}</span>
        <input
          data-testid={`${dataTestId}-input`}
          className="peer absolute opacity-0 cursor-pointer h-0 w-0"
          name={name}
          type="checkbox"
          checked={isChecked}
          onChange={handleChange}
          disabled={disabled}
          aria-label={ariaLabel}
        />
        <span
          className={clsx(
            disabled
              ? ['peer-checked:bg-gray-200', 'border', 'border-gray-200']
              : [
                  'group-hover/checkbox:bg-gray-200',
                  'peer-checked:bg-primary-500',
                  'border',
                  'border-gray-300',
                ],
            'absolute top-0 left-0 h-5 w-5 rounded-sm',
            "after:content-[''] after:absolute",
            indeterminate
              ? [
                  'after:block',
                  'after:w-2.5 after:h-1.5 after:left-1 after:top-1.5',
                  'after:bg-primary-500 after:rounded-sm',
                ]
              : [
                  'after:hidden',
                  'after:w-1.5 after:h-3 after:left-1.5 after:top-0.5 after:rotate-45',
                  'after:border after:border-b-[3px] after:border-r-[3px] after:border-l-0 after:border-t-0',
                ],
            'peer-checked:after:block'
          )}
        ></span>
      </label>
    </div>
  );
};
